import React from 'react';
import { addClassNames } from '../../../Functions/Helper/Component';

function processPaste(e, maxCharacterCount) {
    const pastedText = e.clipboardData.getData('text');
    if (pastedText) {
        const textarea = e.target;
        if (textarea) {
            const selectionStart = textarea.selectionStart;
            const selectionEnd = textarea.selectionEnd;
            const existingText = textarea.value;
            let finalText = existingText.slice(0, selectionStart) + pastedText + existingText.slice(selectionEnd);
            if (maxCharacterCount && finalText.length > maxCharacterCount) {
                finalText = finalText.slice(0, maxCharacterCount);
            }
            return finalText;
        }
    }
}


function TextBoxInput({ label, children, preAddOn, addOn, className, inputGroupClassName, inputClassName, disabled, value, disabledValue, refCallback, ...rest}) {
    const [focused, setFocused] = React.useState(false);

    const { className: addOnClassName, children: addOnChildren,  ...addOnRest } = addOn || {};
    
    const { className: preAddOnClassName, children: preAddOnChildren,  ...preAddOnRest } = preAddOn || {};
    
    
    return (
        <div className={addClassNames("input ", (className || ""))}>
            {(!!(children || label)) && <label htmlFor={rest?.id}>{children || label}</label>}
            <div className={addClassNames("input-group", (focused? " focus" : ""), inputGroupClassName)}>
                {preAddOn && (
                    <span
                        {...preAddOnRest}
                        className={addClassNames(
                            "input-group-text border-start-0",
                            addOnClassName
                        )}
                    >{preAddOnChildren}</span>
                )}
                <input
                    {...rest}
                    ref={refCallback}
                    onFocus={(e) => {setFocused(true); rest?.onFocus?.(e);}}
                    onBlur={(e) => {setFocused(false); rest?.onBlur?.(e);}}
                    disabled={disabled}
                    value={(disabled? disabledValue : value) || ""}
                    onPaste={(e) => {
                        const finalText = processPaste(e, rest?.maxCharacterCount);
                        if (finalText) {
                            e.preventDefault();
                            e.target.value = finalText;
                            rest.onChange(e);
                        }
                    
                    }}
                    className={addClassNames(
                        inputClassName,
                        addOn? "border-end-0" : "",
                        preAddOn? "border-start-0" : ""
                    )}
                />
                {addOn && (
                    <span
                        {...addOnRest}
                        className={addClassNames(
                            "input-group-text border-end-0", 
                            preAddOnClassName
                        )}
                    >{addOnChildren}</span>
                )}
            </div>
        </div>
    );
}

function setTextBoxHeight(target, rows, maxCharacterCount) {
    if (!target || (maxCharacterCount && (target.value?.length || 0) > maxCharacterCount)) return;
    
    // Resize textarea
    target.style.height = 'inherit';
    const scrollHeight = target.scrollHeight;
    const minHeight = parseInt(window.getComputedStyle(target).getPropertyValue('line-height')) * rows;
    target.style.height = `${Math.max(scrollHeight, minHeight) + 1}px`;
    

}

export function ExpandingTextarea({
    refCallback,
    onChange: _onChange,
    setFocused,
    maxCharacterCount,
    value, 
    disabled,
    rows = 3,
    disabledValue,
    ...rest
}) {
    const onChange =  React.useCallback((e) => {
        setTextBoxHeight(e.target, rows, maxCharacterCount);
        _onChange && _onChange(e);
    }, [_onChange, rows, maxCharacterCount])

    return (
        <textarea
            ref={(ref) => {
                setTextBoxHeight(ref, rows, maxCharacterCount);
                if (refCallback) refCallback(ref);
            }}
            onChange={onChange}
            onPaste={(e) => {
                const finalText = processPaste(e, maxCharacterCount);
                if (finalText) {
                    e.preventDefault();
                    e.target.value = finalText;
                    onChange(e);
                }
            
            }}
            rows={rows}
            disabled={disabled}
            value={(disabled? disabledValue : value) || ""}
            onFocus={()=>setFocused && setFocused(true)}
            onBlur={()=>setFocused && setFocused(false)}
            {...rest}
        />
    )
}

function TextareaInput({ 
    label,
    children,
    maxCharacterCount,
    className,
    inputClassName,
    value,
    disabled,
    disabledValue,
    onChange: _onChange,
    rows = 3,
    refCallback,
    addOn,
    preAddOn,
    inputGroupClassName,
    ...rest
}) {
    const [focused, setFocused] = React.useState(false);

    const { className: addOnClassName, children: addOnChildren,  ...addOnRest } = addOn || {};
    
    const { className: preAddOnClassName, children: preAddOnChildren,  ...preAddOnRest } = preAddOn || {};
    
    const charCount = value?.length || 0;
    return (
        <div className={addClassNames("input position-relative ", maxCharacterCount? "char-cap" : "",  (className || ""))}>
            { (children||label) && (<label htmlFor={rest.id}>{children || label}</label>)}
            <div className={addClassNames("input-group", (focused? " focus" : ""), inputGroupClassName)}>
                <div className='flex flex-column flex-grow-1'>
                {preAddOn && (
                    <span
                        {...preAddOnRest}
                        className={addClassNames(
                            "input-group-text border-start-0",
                            addOnClassName
                        )}
                    >{preAddOnChildren}</span>
                )}
                <ExpandingTextarea
                    refCallback={refCallback}
                    onChange={_onChange}
                    setFocused={setFocused}
                    maxCharacterCount={maxCharacterCount}
                    value={value}
                    disabled={disabled}
                    disabledValue={disabledValue}
                    rows={rows}
                    className={inputClassName}
                    {...rest}
                />
                </div>
                {addOn && (
                    <span
                        {...addOnRest}
                        className={addClassNames(
                            "input-group-text border-end-0", 
                            preAddOnClassName
                        )}
                    >{addOnChildren}</span>
                )}
                
                {maxCharacterCount && (
                    <div
                        className="character-count rounded-pill"
                        style={{
                            backgroundColor: `var(--mo-${charCount >= maxCharacterCount ? "danger" : "primary"})`
                        }}
                    >
                        {charCount}
                    </div>
                )}

            </div>

        </div>
    );
}

export default function FormControlInput({ onChange: _onChange, inputClassName : _inputClassName, type="text", ...rest}) {
    let inputClassName = addClassNames("form-control", _inputClassName);
    const onChange = (e) => _onChange && _onChange(e.target.value);
    
    if (type === "textarea") {
        return <TextareaInput {...{...rest, type, onChange, inputClassName}}/>;
    } else {
        return <TextBoxInput {...{...rest, type, onChange, inputClassName}} />;
    }


}