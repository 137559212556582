import React from 'react';
import { 
    Search as SearchIcon,
    GripVertical as GripIcon
} from '../Generic/Icons';
import useRoleAchievement from '../../Hooks/useRoleAchievement';
import useSkills from '../../Provider/TableContext/SkillContext';
import { AchievementDisplay } from '../AchievementsInput';
import Draggable from '../Generic/Draggable';
import Input from '../Generic/Input';
import Graph from '../Generic/Graph';
import { Li } from '../Generic/GenericComponents';
import HookedAchievementModal from '../AchievementHookedModal';
import "./SidePanel.scss";
import LoadingSpinner from '../Generic/LoadingSpinner';
import { TruncateChartData } from '../../Functions/Helper/Functions';
import { getDataColors } from '../../Functions/Helper/Color';
import { CliftonStrengthOptionsMap } from '../../Constants/CliftonStrengths';

function SearchInput ({ search, setSearch, skillSearch, setFormData, skills }) {
    const searchedSkillName = React.useMemo(() => {
        if (skillSearch) {
            return skills?.find(skill => skill.mcb_skillid === skillSearch)?.mcb_skill || "";
        } else return "";
    }, [skillSearch, skills]);

    const onChange = React.useCallback((val) => {
        if (searchedSkillName) {
            setFormData(fd => ({...fd, skillSearch: ""}));
        }
        setSearch(val);
    }, [setFormData, setSearch, searchedSkillName]);

    const onKeyDown = React.useCallback((e) => {
        if (searchedSkillName && !["Shift", "Control", "Alt", "Meta"].includes(e.key)) {
            setFormData(fd => ({...fd, skillSearch: ""}));
            setSearch("");
        }
    }, [setFormData, setSearch, searchedSkillName]);
    return (
        <Input
            value={skillSearch? "" : search}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={ searchedSkillName? `With Skill: ${searchedSkillName}` : "Search"}
            style={{ backgroundColor: "white!important", opacity: "1" }}
            inputGroupClassName="rounded-pill"
            inputClassName="ps-2"
            preAddOn={{
                children: <SearchIcon className="text-gray-200"/>
            }}
        />
    );
}

function AchievementsDisplay({achievements, achievementLoading, search, skillSearch}) {
    const displayAchievements = React.useMemo(() => {
        if(achievementLoading) return [];
        const _search = search.toLowerCase() || "";
        const kw = _search.split(/\s+/i).filter(a => a);
        if(skillSearch) { 
            return achievements.filter((achievement) => (
                achievement.mcb_ABCSkill.some(({mcb_skillid}) => mcb_skillid === skillSearch)
            ));
        } else if (kw.length > 0) {
            const achievementIndex = {};
            return achievements.filter((achievement) => {
                const mcb_achievementstatement = achievement.mcb_achievementstatement.toLowerCase();
                let counter = 0;
                kw.forEach(kw => {
                    const keywordCount =  mcb_achievementstatement.includes(kw)? 1 : 0;
                    counter += keywordCount;
                });
                if (counter > 0) {
                    achievementIndex[achievement.mcb_abcid] = 1/ counter + (1 - 1 / ((achievement.mcb_index + 1) || 1));
                    return true;
                } else {
                    return false;
                }
            }).sort((a, b) => ((achievementIndex[a.mcb_abcid] || 0) - (b.mcb_abcid || 0)));
        } else {
            return achievements;
        }
    }, [achievements,achievementLoading, search, skillSearch]);
    if(achievementLoading) return <LoadingSpinner style={{ background: "none"}}/>;
    else return (
        <ul className="px-4 d-flex flex-column mt-4 gap-3">
            {displayAchievements.map((abc) => (
                <Draggable 
                    key={abc.mcb_abcid}
                    className="mcb-achievement card bg-link-color text-light d-flex flex-row flex-nowrap align-items-center px-2 py-1"
                    type="mcb-achievement"
                    data={abc}
                    onDragStart={() => {
                        const element = document.getElementsByClassName(`journey-wrapper`)[0];
                        setTimeout(() => element.scrollTo({ left: 0, behavior: 'instant' }), 15);
                    }}
                    ItemComponent={Li}
                >
                    <GripIcon className="fs-5 icon"/>
                    <AchievementDisplay value={abc}/>
                </Draggable>
            ))}
        </ul>
    );
}

function ShowGraphs({ keySkills, keyStrengths, requirements, skills, skillLoading}) {
    const [skillData, strengthData, strengthColors] = React.useMemo(() => {
        const skillMap = {};
        const strengthMap = {};
        requirements?.forEach(requirement => {
            requirement?.achievements?.forEach(achievement => {
                achievement?.mcb_ABCSkill?.forEach(({mcb_skillid}) => {
                    if (keySkills.includes(mcb_skillid)) {
                        if (!isNaN(skillMap[mcb_skillid])) {
                            skillMap[mcb_skillid] += 1;
                        } else {
                            skillMap[mcb_skillid] = 1;
                        }
                    }
                });
                achievement.mcb_cliftonstrengths?.split(",").forEach(strengthId => {
                    if (keyStrengths.includes(Number(strengthId))) {
                        if (!isNaN(strengthMap[strengthId])) {
                            strengthMap[strengthId] += 1;
                        } else {
                            strengthMap[strengthId] = 1;
                        }
                    }
                });
            });
        });

        const skillData = Object.entries(skillMap).map(([id, value]) => ({
            label: skills?.find(skill => skill.mcb_skillid === id)?.mcb_skill,
            value 
        })).filter(a => a.value).sort((a, b) => b.value - a.value);
        const strengthData = Object.entries(strengthMap).map(([id, value]) => ({
            ...CliftonStrengthOptionsMap[id], value
        })).filter(a => a.value).sort((a, b) => b.value - a.value);
        const strengthColors = getDataColors(strengthData);
        return [TruncateChartData(skillData), TruncateChartData(strengthData), strengthColors];
    }, [requirements, keySkills, keyStrengths, skills]);

    if(skillLoading) return <LoadingSpinner style={{ background: "none"}}/>;
    else return (
        <>
            {skillData?.length > 0 && (
                <div className="skill-preview">
                    <h3 className="h3 title text-white text-start mb-2">Your Key Skills for Role</h3>
                    <Graph data={skillData} />
                </div>
            )}
            {strengthData?.length > 0 && (
                <div className="strength-preview">
                    <h3 className="h3 title text-white text-start">Your CliftonStrengths</h3>
                    {strengthData?.length > 0? (
                    <Graph colors={strengthColors} data={strengthData} chartSize={500}/>
                    ) : "You haven't entered any CliftonStrengths yet."}
                </div>
            )}
        </>
    );

}
export function SidePanel({ formData, setFormData, className: wrapperClassName }) {
    const [search, setSearch] = React.useState("");
    const { achievements, loading: achievementLoading } = useRoleAchievement();
    const { skills, loading: skillLoading } = useSkills();
    return (
        <div className={wrapperClassName}>
        <div className="side-role-requirements-panel">
            <div className="text-white py-5 px-3 d-flex flex-column gap-4">
                <div className="achievement-preview d-flex flex-column mb-4">
                    <div className="d-flex flex-nowrap justify-content-between mb-2 gap-1">
                        <div className="d-flex flex-column">
                            <div className="h3 title text-light mb-0 flex-grow-1">Achievements</div>
                            <div className="text-light">Drag and drop to achievement to your requirement.</div>
                        </div>
                        <div><HookedAchievementModal classNames={{ modalTriggerClassName: "btn-outline-light" }} /></div>
                    </div>
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        skillSearch={formData?.skillSearch || ""}
                        setFormData={setFormData}
                        skills={skills || []}
                    />
                    <AchievementsDisplay 
                        achievements={achievements} 
                        achievementLoading={achievementLoading} 
                        search={search} 
                        skillSearch={formData.skillSearch || ""}
                    /> 
                </div>
                <ShowGraphs
                    skills={skills} 
                    keySkills={formData?.skills||[]}
                    keyStrengths={formData?.cliftonstrengths||[]}
                    requirements={formData?.requirements||[]}
                    skillLoading={skillLoading} 
                />
            </div>
        </div>
        </div>
    );
}

export function getBindedSidePanel(useContext) {
    return function (props) {
        const context = useContext();
        return (<SidePanel {...props} {...context}/>);
    }
}