import React from 'react';
import FormModal from './Generic/FormModal';
import AchievementForm from './AchievementForm';
import { PlusCircle as AddIcon, FloppyFill as  FloppyIcon } from './Generic/Icons';
import RoleSelection from './RoleSelection';
import { addClassNames } from '../Functions/Helper/Component';
import { bindOnChange } from '../Functions/Helper/OnChange';
import useRole from '../Provider/TableContext/RoleContext';
import useResponsibility from '../Provider/TableContext/ResponsibilityContext';
import useABC from '../Provider/TableContext/ABCContext';
import { deferred } from '../Functions/Helper/Async';
import { formDataToProfession } from '../Functions/Helper/Model';
import ProfessionalForm from './ProfessionForm';

function useProfessionSubmission() {
    const [showRoleForm, setShowRoleForm] = React.useState(false);
    const [roleFormData, setRoleFormData] = React.useState({});
    
    const { processRoleChanges } = useRole();
    const { processResponsibilityChanges } = useResponsibility();
    const { processABCChanges } = useABC();

    const onProfessionSubmit = React.useCallback(async (preProcessData) => {
        const [promise, res, rej] = deferred();
        setRoleFormData((formData) => {
            const profession = typeof preProcessData === "function"?  
            preProcessData(formData || {}) : {...(formData || {})};
            
            const responsibilityChanges = [], abcChanges = [];
            const roleChange = formDataToProfession(profession, responsibilityChanges, abcChanges)
            
            Promise.all([
                processRoleChanges([roleChange]),
                processResponsibilityChanges(responsibilityChanges),
                processABCChanges(abcChanges)
            ]).then(res).catch(rej);

            return formData;
        });

        await promise;
        setRoleFormData({});
        setShowRoleForm(false);
    }, [processRoleChanges, processResponsibilityChanges, processABCChanges]);

    return {onProfessionSubmit, showRoleForm, setShowRoleForm,roleFormData, setRoleFormData};
}

export default function AchievementModal({title, actionName, value: _value, onSubmit: _onSubmit, onChange: _onChange,  onClose: _onClose, classNames, ...props}) {
    const {
        onProfessionSubmit,
        showRoleForm, setShowRoleForm,
        roleFormData, setRoleFormData
    } = useProfessionSubmission();

    const value = React.useMemo(() => (
        showRoleForm? roleFormData : _value
    ), [ showRoleForm, roleFormData, _value]);
    
    const onChange = React.useCallback((value) => {
        if (showRoleForm) {
            setRoleFormData(value);
        } else {
            _onChange(value);
        }
    }, [showRoleForm, setRoleFormData, _onChange]);

    const onSubmit= React.useCallback(async (value) => {
        if (showRoleForm) {
            onProfessionSubmit(value);
        } else {
            await _onSubmit?.(value);
        }
        return true;
    }, [showRoleForm,onProfessionSubmit, _onSubmit]);
    
    const onClose = React.useCallback(() => {
        setShowRoleForm(false);
        _onClose?.(); 
    }, [_onClose, setShowRoleForm]);

    const onRoleChange = React.useCallback((value) => {
        bindOnChange(onChange, "_mcb_role_value")(value)
    }, [onChange]);

    const TitleComponent = React.useCallback(({children, ..._props}) => (
        <div className="d-flex flex-grow-1 flex-row nowrap justify-content-between align-items-center gap-5">
            <div {..._props}><h5>{children}</h5></div>
            {!showRoleForm && (
                <div><RoleSelection
                    setFirstValue
                    value={value?._mcb_role_value}
                    onChange={onRoleChange}
                    onCreateOption={(mcb_roletitle) => {
                        setRoleFormData({ mcb_roletitle });
                        setShowRoleForm(true);
                    }}
                    required={true}
                /></div>
            )}
        </div>
    ), [onRoleChange, showRoleForm, value?._mcb_role_value, setRoleFormData, setShowRoleForm]);

    const _classNames = {
        modalTitleClassName: "btn-primary",
        modalDialogClassName: "modal-dialog-centered", 
        modalTriggerClassName: "border-0 fs-5 p-0 shadow-none text-decoration-underline",
        closeActionClassName: "btn icon-button",
        submitActionClassName: "btn btn-primary"
    };

    Object.entries(classNames || {}).forEach(([key, value]) => {
        _classNames[key] = addClassNames(value, _classNames[key]);
    });

    return (
        <FormModal
            title={showRoleForm? "Add Role" : (title || `${(value?.mcb_abcid? "Update" : "Add")} Achievement`)}
            actionName={showRoleForm? (<><AddIcon className="me-2"/> Add Role</>) 
                : (actionName || <>{value?.mcb_abcid? (<><FloppyIcon className="me-2"/> Update </>) : (<><AddIcon className="me-2"/> Add </>)} Achievement</>)}
            TitleComponent={TitleComponent}
            classNames={_classNames}
            BodyComponent={showRoleForm? ProfessionalForm : AchievementForm}
            onClose={onClose}
            value={showRoleForm? roleFormData : value}
            onChange={showRoleForm? setRoleFormData : onChange}
            onSubmit={onSubmit}
            {...props}
        />
    );
}