import React from 'react';
import { TwoInputColumns, StateInput } from '../../Components/Generic/InputGrouping';
import { TopCliftonStrengthInput } from '../../Components/CliftonStrengthInput';
import Form from '../../Components/Generic/Form';
import useProfileJourneyContext from './ProfileJourneyContext';
import useFileInput from '../../Hooks/useFileInput';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import { addClassNames } from '../../Functions/Helper/Component';
import Sectors from '../../Constants/Sector.json';
import PDFViewer from '../../Components/Generic/PDFViewer';
import { 
    CloudDownload as DownloadIcon,
    ZoomIn as ZoomInIcon,
    ZoomOut as ZoomOutIcon
} from '../../Components/Icons';
import { useLocation } from 'react-router';
import useConstant from '../../Provider/ConstantContext';

export default function Personal({ onNext, next }) {
    const {
        formData,
        setFormData,
        updatePersonal,
        personalLoading,
        profileActions
    } = useProfileJourneyContext("personal");
    const { hash } = useLocation();
    const { browseFile: browseFileForProfile } = useFileInput(profileActions.uploadProfileFile);

    const formRef = React.useRef();
    onNext(async () => {
        if(formRef.current.reportValidity()) {
            await updatePersonal();
            return true;
        }
        return false;
    });


    React.useEffect(() => {
        if (!personalLoading && hash) {
            document.getElementById(hash.substring(1))?.scrollIntoView({ behavior: "smooth" });
        } 
    }, [personalLoading, hash]);

    if (personalLoading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            Loading Profile Data...
        </LoadingSpinner>
    )

    
    return (
        <Form 
            ref={formRef}
            onSubmit={(e) => { 
                e.preventDefault();
                next(); 
            }}
            className="my-2 d-flex flex-column gap-3"
        >
            <h3 className="h5">Personal Details</h3>
            <TwoInputColumns
                state={(formData || {})}
                setState={setFormData} 
                inputs={[
                    ['firstname', 'text', 'First Name', {required: true}],
                    ['lastname', 'text', 'Last Name', {required: true}],
                    ['emailaddress1', 'email', 'Email', {required: !formData?.telephone1}],
                    ['telephone1', 'tel', 'Phone', {required: !formData?.emailaddress1}],
                    ['jobtitle', 'text', 'Job Title', {required: true}],
                    ['mcb_mainqualification', 'text', 'Main Qualification', {required: true}],
                    ['address1_city', 'text', 'Current City', {required: true}],
                    ['mcb_linkedin', 'url', 'LinkedIn Url ']
                ]}
            />
            <StateInput
                state={(formData || {})}
                setState={setFormData}
                alias='sectors'
                type='multiselect'
                label='Sectors of your Profession'
                props={{
                    options: Sectors.sort((a, b) => a.label.localeCompare(b.label)),
                    className: "primary-select",
                    placeholder: "Select Sector"
                }}
            />
            <StateInput
                state={(formData || {})}
                setState={setFormData}
                alias='adx_publicprofilecopy'
                type='textarea'
                label={<span>Profile Summary <em className='fs-smaller'>(max 1650 char)</em></span>}
                props={{
                    required: true,
                    maxCharacterCount: 1650
                }}
            />
            
            <TopCliftonStrengthInput
                hasCliftonStrength={formData.mcb_has_cliftonstrength}
                strengthValues={formData.topCliftonStrengths}
                setNthStrength={(n, value) => 
                    setFormData((od) => ({ 
                        ...od, 
                        topCliftonStrengths: {
                            ...od.topCliftonStrengths,
                            [n]: value
                        }
                    }))
                }
                browseFile={(field) => {
                    return browseFileForProfile({ mimeType: "application/pdf"}, field);
                }}
                isNew={!formData.mcb_clifton34pdf}
                id="clifton-strength-input"                
                setHasCliftonStrength={(mcb_has_cliftonstrength) => 
                    setFormData((od) => ({ ...od, mcb_has_cliftonstrength }))}
            />
                
                
        </Form>
    );
}

// Need to use PDFJS as PowerAPPS file doesn't have correct mime type.
function MCB34PDFDisplay({ downloadProfileFile, zoom, field, pdfId }) {
    const [pdf, setPdf] = React.useState(null);
    const currentPdf = React.useRef(null);
    React.useEffect(() => {
        if (currentPdf.current !== pdfId) {
            downloadProfileFile(field, false).then(({arrayBuffer} = {}) => {
                // to prevent memory deletion in ios
                if (!window.pdfArray) window.pdfArray = {};
                window.pdfArray[pdfId] = arrayBuffer;
                setPdf(window.pdfArray[pdfId])
            });
            currentPdf.current = pdfId;
        }
    }, [downloadProfileFile, field, pdfId]);
    
    if (pdf) {
        return <PDFViewer id={currentPdf.current} zoom={zoom} file={pdf} />;
    } else {
        return <LoadingSpinner />;
    }
}

const FileOptions = {
    mcb_clifton34pdf: "CliftonStrength34",
    mcb_cliftonstrengthstop5pdf: "CliftonStrengths Top 5 Report",
    mcb_signaturethemepdf: "Signature Theme Report",
    mcb_themesequencepdf: "Theme Sequence Report"
};

function SidePanel({className, journeyContainer}) {
    const { portalURL } =  useConstant();
    const {
        formData,
        profileActions: { downloadProfileFile }
    } = useProfileJourneyContext("personal");

    const {
        mcb_clifton34pdf,
        mcb_cliftonstrengthstop5pdf,
        mcb_signaturethemepdf,
        mcb_themesequencepdf
    } = formData;
    
    const [selectedOption, setSelectedOption] = React.useState(FileOptions.mcb_clifton34pdf);
    const [zoom, setZoom] = React.useState(1);

    function handleZoomIn() {
      setZoom(zoom + 0.05);
    }
  
    function handleZoomOut() {
      if (zoom > 0.1) {
        setZoom(zoom - 0.05);
      }
    }
  
    
    React.useEffect(() => {
        if (journeyContainer.current && (
            mcb_clifton34pdf || mcb_cliftonstrengthstop5pdf || mcb_signaturethemepdf || mcb_themesequencepdf
        )) {
            journeyContainer.current.classList.add("with-side-panel");
            setSelectedOption(Object.keys(FileOptions).find((key) => formData[key]));
        } else {
            journeyContainer.current.classList.remove("with-side-panel");    
        }
    }, [ formData, mcb_clifton34pdf, mcb_cliftonstrengthstop5pdf, mcb_signaturethemepdf,
        mcb_themesequencepdf, journeyContainer]);

    if (mcb_clifton34pdf || mcb_cliftonstrengthstop5pdf || mcb_signaturethemepdf || mcb_themesequencepdf) {
        return <div className={addClassNames(className, "d-flex flex-column gap-3 py-4 h-100")}>
            <div className="d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap flex-xl-nowrap flex-xxl-nowrap flex-row gap-2 justify-content-between">
                <div className="dropdown">
                    <button 
                        type="button"
                        className="btn btn-light upload-dropdown-toggle dropdown-toggle"            
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >{FileOptions[selectedOption] || "Select the View"}</button>
                    
                    <div
                        className="dropdown-menu"
                    >{Object.entries(FileOptions).map(([key, label]) => (formData[key] &&
                        <button 
                            type="button"
                            className="dropdown-item"
                            key={key}
                            onMouseDown={() => { setSelectedOption(key);}}
                            onTouchStart={() => { setSelectedOption(key);}}
                        >{label}</button>
                    ))}</div>
                </div>
                <div className="btn-group">
                    <button
                        type="button"
                        className="icon-button btn btn-link-color"
                        onClick={handleZoomOut}
                    ><ZoomOutIcon className="icon me-0"/></button>
                    <button className="btn btn-link-color text-center">{Math.round(zoom * 100)}%</button>
                    <button 
                        type="button"
                        className="icon-button btn btn-link-color"
                        onClick={handleZoomIn}
                    ><ZoomInIcon className="icon me-0"/></button>
                </div>
                <div className="d-flex">
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href={`${portalURL}File/download.aspx?Entity=contact&Attribute=${selectedOption}&Id=${formData?.contactid}`}
                        className='btn btn-primary w-max-content unstyled align-self-end'
                    ><DownloadIcon className="icon"/> Download PDF</a>
                </div>
            </div>
            {selectedOption && formData[selectedOption] && (
                <MCB34PDFDisplay
                    pdfId={formData[selectedOption]}
                    field={selectedOption}
                    downloadProfileFile={downloadProfileFile}
                    zoom={zoom}
                />
            )}
        </div>
    } else {
        return <div className={addClassNames(className, "empty")}></div>
    }

}

Personal.stepName = "Personal";
Personal.SideComponent = SidePanel;
