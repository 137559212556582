import React from 'react';
import { monthDate } from '../Functions/Helper/Month';
import useQualifications from '../Provider/TableContext/QualificationContext';
import "./useQualificationOptions.scss";

export default function useQualificationOptions() {
    const { qualifications, loading } = useQualifications();
    
    const options = React.useMemo(() => {
        return (qualifications || []).sort((a, b) => (
            new Date(a.mcb_dateearned) - new Date(b.mcb_dateearned)
        )).map(qual => ({ 
            brief: (
                <div className="d-inline-block ws-normal">
                    <strong className="fw-bold fs-large">{qual?.mcb_qualification}</strong>,<span className="fs-medium fw-normal">{qual?.mcb_institution}</span>
                </div>
            ),
            label: (
                <div className="d-flex ws-normal">
                    <div className="d-inline-block mr-2 ws-normal">
                        <strong className="fw-bold fs-large">{qual?.mcb_qualification}</strong>,<span className="fs-medium fw-normal">{qual?.mcb_institution}</span>
                    </div>
                    <div className="flex-grow-1 qualification-value-date fs-medium fw-normal ws-normal text-end">{monthDate(qual?.mcb_dateearned)} </div>
                </div>
            ),
            value: qual?.mcb_qualificationid,
            qualification: qual
        }));
    }, [qualifications]);

    return [options, loading];
}