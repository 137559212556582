import React from 'react';
import { Link } from './Generic/Link';
import Card from './Generic/Card';
import LoadingSpinner from './Generic/LoadingSpinner';
import { 
    PlusCircle as AddIcon,
    PencilSquare as EditIcon,
    Trophy as AchievementIcon 
} from './Generic/Icons';
import useAchievementOptions from '../Hooks/useAchievementOptions';
import HookedAchievementModal from './AchievementHookedModal';
import './AchievementsCard.scss';
import SearchForm from './SearchForm';
import useConstant from '../Provider/ConstantContext';

export default function AchievementsCard(props) {
    const [search, setSearch] = React.useState("");
    const [options, loading] = useAchievementOptions(search);
    const [achievement, setAchievement] = React.useState({});
    const onClose = React.useCallback(() => setAchievement({}), []);
    const { Descriptions } = useConstant();

    return (
        <Card
            title={<>Your Achievement Statements <Link 
                className="link-secondary ms-2"
                to="/achievements"><EditIcon className="icon"/></Link></>}
            footer={
                <div className="d-flex flex-row justify-content-start align-items-center gap-3">
                    <HookedAchievementModal 
                        value={achievement}
                        onChange={setAchievement}
                        onClose={onClose}
                        triggerName={<AddIcon/>}
                    />
                    <SearchForm 
                        className="flex-grow-1" 
                        placeholder="Search Achievements..."
                        search={search}
                        setSearch={setSearch}
                    />
                </div>
            }
            description={Descriptions['achievement']}
            classNames={{body: "position-relative achievement-card"}}
            {...props}
        >
            {loading? <LoadingSpinner/> : (
                <div className="d-flex flex-column position-lg-absolute overflow-y-auto w-100 h-100 gap-3 px-2">
                    {options.filter(option => Array.isArray(option.options) && option.options.length).map(option => {

                        return (
                            <div key={option.key}>
                                <div >{option.label}</div>
                                <ul className="achievements-list">
                                    {option.options.map(({label, value}) => {
                                        return (
                                            <li key={value}>
                                                <AchievementIcon width="24" height="24" className="icon"/>
                                                <div className="achievement-detail fs-medium">{label}</div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            )}
        </Card>
    ); 
}