import React from 'react';
import LoadingSpinner from './Generic/LoadingSpinner';
import Card from './Generic/Card';
import RichTextDisplay from './RichTextDisplay';
import HTMLDocxLink from './HTMLDocxLink';
import { addClassNames } from '../Functions/Helper/Component';

const Description = (name, HTML) => () => <HTMLDocxLink HTML={HTML} rotate name={name}> Download Role Alignment Capability Matrix</HTMLDocxLink>;

export default function MyRAC({role, className}) {

    const children = role.mcb_rac? <LoadingSpinner/> : <RichTextDisplay value={role.mcb_rac}/>;
    
    return (
        <Card
            title="Role Alignment Capability Matrix"
            className={addClassNames("w-100", className)}
            description="Downloads this Role Alignment Capability as MS Word Document"
            DescriptionControl={Description(`RAC - ${role.mcb_roletitle}`, role.mcb_rac)}
        >
            {children}
        </Card>
    );
}