export function lightenColor(color, percent) {
    var num = parseInt(color.replace("#",""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;
    return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
}

export function rgbaToHex(rgba) {
    // Extract the rgba components
    const result = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)$/);
    if (!result) {
        return null; // Invalid rgba format
    }

    // Convert each component to a two-digit hexadecimal value
    const r = parseInt(result[1]).toString(16).padStart(2, '0');
    const g = parseInt(result[2]).toString(16).padStart(2, '0');
    const b = parseInt(result[3]).toString(16).padStart(2, '0');

    // Combine the components into a single hexadecimal string
    return `${r}${g}${b}`;
}

export function getDataColors(data) {
    const uniqueColor = {};
    const colors = data.map(({color}) => color).map(color => {
        if (uniqueColor[color]) {
            uniqueColor[color] += 1;
            return lightenColor(color, uniqueColor[color] * 5);
        } else {
            uniqueColor[color] = 1;
            return color;
        }
    });
    return colors
  }