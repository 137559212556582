import React from 'react';
import { addClassNames } from '../../Functions/Helper/Component';
import { convertStyleStringToReactStyle } from '../../Functions/Helper/RichText';
import processPPR from './processPPR';
import processRPR from './processRPR';

function KeyedFragment({ c, ...props }) {
    return <>{c.map((C, k) => {
        return (<React.Fragment key={k}>{typeof C === "function"? C(props) : C}</React.Fragment>);
    })}</>
}

export default function processXML(XML, data) {
    const components = [];
    const context = { style: {}, className: ""};
    if (XML._fTextNode) {
        components.push(XML._text);
    } else if (!['mc:Fallback'].includes(XML._tag)) {
        const children = [];
        const childContext = { style: {}, className: ""};
        let listChildrenBuffer = [];
        let lastNumId = null;

        const pushListChildrenBuffer = () => {
            const numberingDetail = {...(data.numbering[lastNumId] || {})};
            
            if (!listChildrenBuffer.length) return;
            const cProps = {};
            const props = {...numberingDetail?.props} || {};
            const style = {...props?.style || {}};
            // Fix for when the numbering is intended (CSS don't support indenting of li)
            if (style.textIndent) {
                const indent = style.textIndent;
                const unitMatch = indent.match(/[a-z]+/);
                const unit = unitMatch? unitMatch[0] : "";
                const value = parseFloat(indent.match(/[+-]?\d+(\.\d+)?/)[0]);
                if (value < 0) {
                    cProps.style = { paddingLeft: `${Math.abs(value)}${unit}`}
                    style.textIndent = "0px";
                    props.style = style;
                }

            }

            if (numberingDetail?.type === "ol") {
                children.push(
                    <ol {...props}>
                        <KeyedFragment c={listChildrenBuffer} {...cProps}/>
                    </ol>
                );
            } else {
                children.push(
                    <ul data-numid={lastNumId} {...props}>
                        <KeyedFragment c={listChildrenBuffer} {...cProps}/>
                    </ul>
                );
            }
            listChildrenBuffer = [];
            lastNumId = null;
        };

        if (!["w:numPr", "w:pPr", "w:rPr", "wp:align"].includes(XML._tag))
            XML._children.forEach(child => {
                const result = processXML(child, data);
                const { style, className, compileList, numId, tabStop, tabStops, tabbed, ...props } = result.context;
                
                if (compileList) {
                    if (lastNumId === numId)  {
                        result.components.forEach(component => listChildrenBuffer.push((props) => <li {...props}>{component}</li>));
                    } else {
                        pushListChildrenBuffer();
                        if (numId && numId !== '0') {
                            result.components.forEach(component => listChildrenBuffer.push((props) => <li {...props}>{component}</li>));
                            lastNumId = numId;
                        } else {
                            result.components.forEach(component => children.push(component));
                        }
                    }    
                } else {
                    if (numId) childContext.numId = numId;
                    result.components.forEach(component => children.push(component));
                    if (listChildrenBuffer.length > 0) pushListChildrenBuffer();
                }
                
                if (style) Object.assign(childContext.style, style);
                if (className) childContext.className = addClassNames(childContext.className, className);
                if (tabStop || tabStops) {
                    if (!Array.isArray(childContext.tabStops)) childContext.tabStops = [];
                    if (tabStop) childContext.tabStops.push(tabStop);
                    else if (tabStops) childContext.tabStops = tabStops;
                }
                
                if (tabbed) childContext.tabbed = true;
                Object.assign(childContext, props);
            });
        if (listChildrenBuffer.length > 0) pushListChildrenBuffer();
        
        switch(XML._tag) {
            case ('w:p'):
                if (!children.length) break;
                const { paragraphType, numId, numFormat, containsDiv, tabStops, tabStop, tabbed, ...paragraphProps } = childContext;
                if (tabStops) {
                    paragraphProps["data-tab-stops"] = JSON.stringify(tabStops);

                }
                if (numId) {
                    context.numId = numId;
                    context.compileList = true;
                }
                if (tabbed) {
                    paragraphProps.className = addClassNames(paragraphProps.className, "tabbed");
                }
                if (containsDiv) {
                    context.containsDiv = true;
                    children.forEach((child) => {
                        if (child.type === "span") {
                            components.push(<div {...paragraphProps}>{child}</div>);
                        } else {
                            components.push(child);
                        }
                    });
                } else switch (paragraphType) {
                    case ('H1'):
                        components.push(<h1 {...paragraphProps}><KeyedFragment c={children}/></h1>);
                        break;
                    case ('H2'):
                        components.push(<h2 {...paragraphProps}><KeyedFragment c={children}/></h2>);
                        break;
                    case ('H3'):
                        components.push(<h3 {...paragraphProps}><KeyedFragment c={children}/></h3>);
                        break;
                    case ('H4'):
                        components.push(<h4 {...paragraphProps}><KeyedFragment c={children}/></h4>);
                        break;
                    case ('H5'):
                        components.push(<h5 {...paragraphProps}><KeyedFragment c={children}/></h5>);
                        break;
                    default:
                        components.push(<div {...paragraphProps}><KeyedFragment c={children}/></div>);
                }
                
                break;
            
            case ('w:tab'):
                if (XML._parent._tag !== 'w:tabs') {
                    components.push(<span className="tabs"/>);
                    context.tabbed = true;
                }
                break;
            
            case ('w:r'):
                if (childContext.tabbed) context.tabbed = true;
                if (childContext.containsDiv) context.containsDiv = true;
                if (children.length && children.some(c => c)) {
                    children.forEach((child, key) => {
                        if (typeof child === "string") {
                            components.push(<span
                                key={key}
                                style={childContext.style}
                                className={childContext.className}
                            >{child}</span>);
                        } else if(child) {
                            components.push(child);
                        }
                    });
                }
                break;

            case ('w:tbl'):
                components.push(<table {...childContext}><KeyedFragment c={children}/></table>);
                break;

            case ('w:tr'):
                components.push(<tr {...childContext}><KeyedFragment c={children}/></tr>);
                break;

            case ('w:tc'):
                components.push(<td {...childContext}><KeyedFragment c={children}/></td>);
                break;

            case ('w:gridSpan'):
                context.colSpan = XML._attrs['w:val'];
                break;

            case ('w:vMerge'):
                context.rowSpan = XML._attrs['w:val'];
                break;
            
            case ('w:tcBorders'):
                context.style.border = `1px solid ${XML._children[0]._attrs['w:color']}`;
                break;

            case ('w:tblW'):
                context.style.width = `${XML._attrs['w:w'] / 20}pt`;
                break;

            case ('w:tblCellSpacing'):
                context.style.borderSpacing = `${XML._attrs['w:w'] / 20}pt`;
                break;

            case ('w:tblCellMar'):
                context.style.padding = `${XML._attrs['w:left'] / 20}pt`;
                break;

            case ('w:tblBorders'):
                context.style.border = `1px solid ${XML._children[0]._attrs['w:color']}`;
                break;

            case ('w:tblLook'):
                context.style.borderCollapse = XML._attrs['w:val'] === 'nil' ? 'separate' : 'collapse';
                break;

            case ('w:tblStyle'):
                context.className = addClassNames(context.className, XML._attrs['w:val']);
                break;


            case ('wp:posOffset'):
                // const posOffset = parseInt(children[0]) / 20;
                // if (!isNaN(posOffset)) context.style.margin = `${posOffset}pt`;
                break;

            case ('mc:Choice'):
                components.push(<KeyedFragment c={children}/>);
                context.containsDiv = true;
                Object.assign(context.style, childContext.style);
                context.className = addClassNames(context.className, childContext.className);
                break;

            case ('wp:align'):
                if (XML?._children[0]?._text) {
                    context.style.float = XML?._children[0]?._text;
                    context.style.clear = "both";
                }
                break;

            case ('a:lumMod'):
                if (XML?._children[0]?._text) {
                    let brightness = parseInt(XML._children[0]._text) / 1000;  // Convert to percentage
                    context.style.filter = `brightness(${brightness}%)`;
                }
                break;

            case ('a:lumOff'):
                if (XML?._children[0]?._text) {
                    let brightness = 100 + parseInt(XML._children[0]._text) / 1000;  // Convert to percentage and add to original brightness
                    context.style.filter = `brightness(${brightness}%)`;
                }
                break;

            // case ('a:fillRef'):
            //     XML._children.forEach(child => {
            //         if (child._tag === 'a:schemeClr') context.style.backgroundColor = `var(--docx-${child._attrs['val']})`; 
            //     });
            //     break;

            case ('a:off'):
                context.yOff = parseInt(XML._attrs['y']);
                break;
            case ('wps:wsp'):
                components.push(<div className="side-panel" data-yoff={childContext.yOff}><KeyedFragment c={children}/></div>);
                break;

            case ('wpg:wgp'):
                children.sort((a, b) => {
                    let aYoff = a.props['data-yoff'] || 0;
                    let bYoff = b.props['data-yoff'] || 0;
                    return aYoff - bYoff;
                }).forEach(child => components.push(child));
                break;

            case ('w:tblInd'):
                context.style.margin = `${XML._attrs['w:w'] / 20}pt`;
                break;
            
            case ('v:group'):
                const vGroupStyle = convertStyleStringToReactStyle(XML._attr.style);
                components.push(<div style={vGroupStyle} className="group"><KeyedFragment c={children}/></div>);
                break;
            
            case ('v:rect'): 
                components.push(<div {...childContext}><KeyedFragment c={children}/></div>);
                break;
                
            case ('v:stroke'):
                context.style.borderColor = XML._attrs['color'];
                context.style.borderWidth = /[A-z]/.test(XML._attrs['weight'])? XML._attrs['weight'] : `${(parseInt(XML._attrs['weight'])*72)/91440}pt`;
                break;

            case ('v:fill'):
                context.style.backgroundColor = XML._attrs['color'] || XML._attrs['color1'] || XML._attrs['color2'];
                break;

            case ('v:textbox'):
                components.push(<p {...childContext}><KeyedFragment c={children}/></p>);
                break;

            case ('w:br'):
                if (XML._attrs['w:type'] === 'page') components.push(<div className="page-break"/>);
                else components.push(<br/>);
                break;

            case ('w:hyperlink'):
                components.push(<a target="_blank" rel="noreferrer" href={data.links[XML._attrs['r:id']] || "#"}><KeyedFragment c={children}/></a>);
                break;
            case ('wp:anchor'):
                components.push(<div {...childContext}><KeyedFragment c={children}/></div> );

                context.containsDiv = true;
                break;
            case ('w:pPr'):
                const pprData = processPPR(XML);
                const { paragraphStyle, runningStyle: paragraphRunningStyle, rprStyle, ...pprContext } = pprData.context;
                context.style = Object.assign(
                    context.style,
                    data.styles[paragraphStyle || ""]?.style || {},
                    pprData.style || {},
                    data.styles[paragraphRunningStyle || ""]?.style || {},
                    rprStyle || {}
                );
                Object.assign(context, pprContext);
                break;

            case ('w:rPr'):
                const rprData = processRPR(XML);
                const { runningStyle } = rprData.context;
                context.style = Object.assign(
                    context.style,
                    data.styles[runningStyle || ""]?.style || {},
                    rprData.style,
                );
                break; 
            
            case ('w:pgSz'):
                if (XML._attrs['w:w']) {
                    context.style.width = `${XML._attrs['w:w'] / 20}pt`;
                }
                if (XML._attrs['w:h']) {
                    context.style.height = `${XML._attrs['w:h'] / 20}pt`;
                }
                break;

            case ('w:pgMar'):
                if (XML._attrs['w:top']) {
                    context.style.paddingTop = `${XML._attrs['w:top'] / 20}pt`;
                }
                if (XML._attrs['w:right']) {
                    context.style.paddingRight = `${XML._attrs['w:right'] / 20}pt`;
                }
                if (XML._attrs['w:bottom']) {
                    context.style.paddingBottom = `${XML._attrs['w:bottom'] / 20}pt`;
                }
                if (XML._attrs['w:left']) {
                    context.style.paddingLeft = `${XML._attrs['w:left'] / 20}pt`;
                }
                break;

            case ('w:docGrid'):
                if (XML._attrs['w:linePitch']) {
                    context.style.lineHeight = `${XML._attrs['w:linePitch'] / 20}pt`;
                }
                // CSS does not support a direct equivalent for 'w:charSpace'
                break;
            case ('w:headerReference'):
                if (XML._attrs['w:type'] === 'first') {
                    context.firstHeader = XML._attrs['r:id'];
                }
                break;
            default:
                const { style, className, tabbed: childTabbed, numFormat: childNumFormat, containsDiv: childContainsDiv, ...props } = childContext
                children.forEach(d => components.push(d));
                Object.assign(context.style, style);
                context.className = addClassNames(context.className, className);
                if (childTabbed) context.tabbed = true;
                if (childNumFormat) context.numFormat = childNumFormat;
                if (childContainsDiv) context.containsDiv = true;
                Object.assign(context, props);
                break;
        }
    }
    return { components, context };
}