import React from 'react';
import { useNavigate } from 'react-router';
import Sector from '../../Constants/Sector.json';
import Form from '../../Components/Generic/Form';
import ChipDropdown from '../../Components/Generic/ChipDropdown';
import useApplyingRoleJourneyContext from './ApplyingRoleContext';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import { TwoInputColumns, StateInput } from '../../Components/Generic/InputGrouping';

export default function ApplyingRoleDetails({ onNext, next }) {
    const { formData, setFormData, updateApplyingRoleDetails, loading } = useApplyingRoleJourneyContext();
    const navigate = useNavigate();
    const formRef = React.useRef();
    onNext(async () => {
        if(formRef.current.reportValidity()) {
            const result = await updateApplyingRoleDetails();
            if (result.mcb_roleid) {
                navigate(`/applying-job-setup/requirements/${result.mcb_roleid}`);
            }
        }
        return false;
    });
    
    if (loading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            Loading Applying Role Data...
        </LoadingSpinner>
    );
    
    return (
        <Form 
            ref={formRef}
            onSubmit={(e) => { 
                e.preventDefault();
                next(); 
            }}
            className="my-2 d-flex flex-column gap-3"
        >
            <h3 className="h5">Job Details</h3>
            <TwoInputColumns
                state={formData || {}}
                setState={setFormData} 
                inputs={[
                    ['mcb_rolecompanyname', 'text', 'Company Name', {required: true}],
                    ['mcb_roletitle', 'text', 'Job Title', {required: true}]
                ]}
            />
            <StateInput
                state={(formData || {})}
                setState={setFormData}
                alias="mcb_challenge"
                type="richtext"
                label={<span>Job Description <em className='fs-smaller'>(max 5000 char)</em></span>}
                props={{
                    required: true,
                    maxCharacterCount: 5000
                }}
            />
            <TwoInputColumns
                state={formData || {}}
                setState={setFormData}
                inputs={[
                    [
                        'mcb_baserenumeration',
                        'number', (
                            <>
                                <label>Renumeration</label><br/>
                                <span className="fs-smaller">The amount of the remuneration for the job.</span>
                            </>
                        ),
                        {
                            addOn: {
                            children: <ChipDropdown 
                                defaultValue={861400000}
                                onChange={(mcb_renumerationtype) => setFormData((od) => ({ ...od, mcb_renumerationtype }))}
                                value={formData.mcb_renumerationtype}
                                options={[
                                    { label: "per year", value: 861400000},  
                                    { label: "per hour", value: 861400001}    
                                ]} 
                            />
                            },
                            preAddOn: {
                            children: <div className="font-weight-bold">$</div>
                            },
                            inputClassName: "text-end text",
                            min: 0,
                            step: 1
                        }
                    ],
                    [
                        'mcb_atriskrenumeration',
                        'number', (
                            <>
                                <label>At Risk Renumeration</label><br/>
                                <span className="fs-smaller">The additional bonus/commissions you would want.</span>
                            </>
                        ),
                        {
                            preAddOn: {
                                children: <div className="font-weight-bold">$</div>
                            },
                            inputClassName: "text-end",
                            min: 0,
                            step: 1
                        }
                    ], 
                    ['mcb_sector', 'select', "Sector", {
                        options: Sector.sort((a, b) => a.label.localeCompare(b.label)),
                        sorted: true,
                        className: "primary-select",
                        placeholder: "Select Sector"
                    }],
                    ['mcb_datesubmitted', 'date', 'Date Submitted', {}]
                ]}
            />
            <StateInput
                state={(formData || {})}
                setState={setFormData}
                alias="mcb_generalcomments"
                type="richtext"
                label={<span>Notes <em className='fs-smaller'>(max 800 char)</em></span>}
                props={{
                    maxCharacterCount: 800
                }}
            />             
        </Form>
    );
}

ApplyingRoleDetails.stepName = "Job Details";