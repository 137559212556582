import React from 'react';
import Input from '../Generic/Input';
import CliftonStrengthOptions, { tooltipPrefix } from '../../Constants/CliftonStrengths';
import { components as ReactSelectComponents } from 'react-select';
import CheckCliftonStrength from '../CheckCliftonStrength';

export default function CliftonStrengthInput({multiselect,  checkCliftonStrength = true, noWrapper, bold, components, ...props}) {
    const modifyFontWeight = React.useCallback(( baseStyle) => {    
        return {
            ...baseStyle,
            fontWeight: bold? "bold" : "normal",
        }
    }, [bold]);
    const _components = React.useMemo(() => {
         
        const selectValue = props.value;
        const tooltipOverride = (componentName) => (props) => {
            const Component = (components && components[componentName]) || ReactSelectComponents[componentName];
            // single value style fix
            let value = componentName === "ValueContainer"? selectValue : props.data?.value;
            if (value) {
                return (
                    <div data-tooltip-id={tooltipPrefix  + value}>
                        <Component {...props}/>
                    </div>
                );
            } else {
                return <Component {...props}/>
            }
        }
        const result = {
            ...components,
            Option: tooltipOverride("Option"),
        }
        if (multiselect) {
            result.MultiValueLabel = tooltipOverride("MultiValueLabel");
        } else {
            result.ValueContainer = tooltipOverride("ValueContainer");
        }
        return result;
    }, [components, multiselect, props.value]);

    const input = (
        <Input
            type={multiselect? "multiselect" : "select"}
            {...props}
            options={CliftonStrengthOptions}
            components={_components}
            inputWrapper={noWrapper && CheckCliftonStrength}
            styles={{
                group: (baseStyle, state) => {
                    return {
                        ...baseStyle,
                        borderLeft: "0.33em solid " + state.data.color,
                    }
                },
                groupHeading: (baseStyle) => {
                    return ({
                        ...baseStyle,
                        fontSize: "0.9rem",
                    });
                },
                singleValue: modifyFontWeight,
                
                multiValue: (baseStyle, state) => {
                    return {
                        ...baseStyle,
                        backgroundColor: state.data.color,
                        color: "white",
                    };
                },
                multiValueLabel: (baseStyle) => modifyFontWeight({...baseStyle, color: "white"})
            }}
        />
        
    );
    if (checkCliftonStrength) 
        return <CheckCliftonStrength label={props.label}>{input}</CheckCliftonStrength>;
    else return input;
}