import React from 'react';
import { Link } from './Generic/Link';

export function JobApplicationsLink() {
    return (
        <div className="d-flex w-100 justify-content-center align-items-center">
            <Link to="/job-applications" className='btn btn-outline-link-color'>View all Jobs</Link>
        </div>
    );
}
