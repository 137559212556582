import React from "react";
import useActionDialog from "../Provider/ ActionDialogContext";

export default function useFileInput(uploadFileFunction) {
    const { alert } = useActionDialog();
    const browseFile = React.useCallback(({ mimeType, maxSize } = {}, ...args) => {
        const input = document.createElement("input");
        input.style.display = "none";
        input.type = "file";

        if (mimeType) input.accept = mimeType;

        input.addEventListener("change", (e) => {
            const file = e.target.files[0];
            input.remove();
            
            if (file) {
                if (maxSize && file.size > maxSize) {
                    alert("File size exceeds the maximum limit");
                    return;
                }
                uploadFileFunction(...args, file);
            }
        });
        input.click();
    }, [uploadFileFunction, alert]);

    return { browseFile };
    }
