import React from "react";
import { Link as RouterLink } from "react-router-dom";
export function Link({onClick, ...props}) {
    const _onClick = React.useCallback((e) => {
        if (typeof onClick === "function") {
            onClick(e);
        } else {
            setTimeout(() => {
                window.scrollTo(0, 0, {behavior: "smooth"});
            }, 50);
        }
    }, [onClick]);
    return (
        <RouterLink onClick={_onClick} {...props} />
    );
}