import React from 'react';
import useRole from '../../Provider/TableContext/RoleContext';
import RoleStatus from '../../Constants/RoleStatus.json';
import useCreatedRoleContext from '../../Hooks/useCreatedRoleContext';

const IdealRoleContext = React.createContext();

export function IdealRoleProvider(props) {
    const { roles, createRole, updateRole, loading: roleLoading } = useRole();
    const role = React.useMemo(() => {
        if (!roleLoading) {
            const role = {...(roles?.filter(role => (
                (role || {}).mcb_rolestatus === RoleStatus.Ideal
            ))[0] || {})}; 

            role.mcb_renumerationtype = role?.mcb_renumerationtype || 861400000;
            role.interestedSections = (role?.mcb_interestedsectors?.split(",") || []).map(a => Number(a));
            role.notInterestedSections = (role?.mcb_notinterestedsectors?.split(",") || []).map(a => Number(a));
            return role;
        }
    }, [roles, roleLoading]);
    const { formData, setFormData, setLoading, ...contextValue} = useCreatedRoleContext({ role, updateRole, roleStatus: RoleStatus.Ideal, roleStatusName: "Ideal", cvName: "Generic CV" })
    const updateIdealRoleDetails = React.useCallback(async ()  => {
        setLoading(true);
        const role = {...(formData || {}), mcb_rolestatus: RoleStatus.Ideal};
        const mcb_interestedsectors =  (role.interestedSections || []).join(",");
        const mcb_notinterestedsectors =  (role.notInterestedSections || []).join(",");
        if (mcb_interestedsectors) role.mcb_interestedsectors = mcb_interestedsectors;
        if (mcb_notinterestedsectors) role.mcb_notinterestedsectors = mcb_notinterestedsectors;
        const mcb_atriskrenumeration = Number(role?.mcb_atriskrenumeration) || 0;
        const mcb_baserenumeration = Number(role.mcb_baserenumeration) || 0;
        if (mcb_atriskrenumeration) role.mcb_atriskrenumeration = mcb_atriskrenumeration;
        if (mcb_baserenumeration) role.mcb_baserenumeration = mcb_baserenumeration;

        const result = role?.mcb_roleid? await updateRole(role): await createRole(role);
        setLoading(false);
        return result;
    }, [formData, updateRole, createRole, setLoading]);

    return (
        <IdealRoleContext.Provider value={{
            formData, setFormData, updateIdealRoleDetails, ...contextValue
        }}>
            {props.children}
        </IdealRoleContext.Provider>
    );
}

export default function useIdealRoleJourneyContext() {
    return React.useContext(IdealRoleContext);
}