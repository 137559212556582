import React from 'react';

const DragAndDropContext = React.createContext();

function DragAndDropProvider({children, touchDragIcon}) {
    const currentlyDragging = React.useRef(null);
    const currentlyOver = React.useRef(null);
    const [touchDragDisplay, setTouchDragDisplay] = React.useState({start: false, x: 0, y: 0});
    
    const setCurrentlyDragging = React.useCallback((dragged) => {
        if (dragged?.onDragStart) dragged.onDragStart(dragged);
        else if (currentlyDragging.current?.onDragStop) currentlyDragging.current.onDragStop();
        currentlyDragging.current = dragged;
    }, []);

    const setCurrentlyOver = React.useCallback((possibleOver) => {
        if (currentlyDragging.current === null) return;
        const type = (possibleOver || currentlyOver.current)?.type; 
        const checkDraggingType = (type) => {
            
            const checkDropType = (dragType) => (
                typeof type === "function"? type(dragType) : (dragType === type)
            );
            const dragType = currentlyDragging.current.type;

            if (Array.isArray(dragType)) {
                return dragType.some(checkDropType);
            } else {
                return checkDropType(dragType);
            }
        };
        
        const validity = (() => {
            if (!type) return false;
            else if (typeof type.test === "function") {
                return checkDraggingType(type.test);
            } if (Array.isArray(type)) {
                return type.some(checkDraggingType);
            } else {
                return checkDraggingType(type);
            }
        })();

        
        if (possibleOver === null) {
            const drag = currentlyDragging.current;
            const over = currentlyOver.current;
            if (over?.onDragLeave) {
                over.onDragLeave(validity && drag);
            }
            if (drag?.onDropLeave) {
                drag.onDropLeave(validity && over);
            }
            // Making sure the drop events are fired before setting the currentlyOver event
            setTimeout(() => {
                currentlyOver.current = null;
            }, 50); 
        } else {
            if (validity) currentlyOver.current = possibleOver;

            if (possibleOver.onDragOver) {
                possibleOver.onDragOver(validity && currentlyDragging.current);
            }
            
            if (currentlyDragging.current.onDropOver)  {
                currentlyDragging.onDropOver(validity && currentlyOver.current);
            }
        }
        return validity;
    }, []);

    const resolve = React.useCallback(() => {
        const dragging = currentlyDragging.current;
        const over = currentlyOver.current;

        if (over) over.onDrop(dragging);
        if (dragging?.onDrop) dragging.onDrop(over);
        if (dragging?.onDragStop) dragging.onDragStop();
        currentlyDragging.current = null;
        currentlyOver.current = null;
        
    }, []);

    const TouchDragIcon = React.useMemo(() => {
        if (touchDragDisplay.touchDragDisplay) {
            return touchDragDisplay.touchDragDisplay;
        } else {
            return touchDragIcon;
        }
    }, [touchDragDisplay, touchDragIcon]);

    const value = React.useMemo(() => ({
        setCurrentlyDragging, setCurrentlyOver, resolve, setTouchDragDisplay
    }), [setCurrentlyDragging, setCurrentlyOver, resolve, setTouchDragDisplay]);

    return (
        <DragAndDropContext.Provider value={value}>
            {children}
            {TouchDragIcon && touchDragDisplay.start && <div style={{
                position: "fixed",
                opacity: 0.89,
                left: touchDragDisplay.x,
                top: touchDragDisplay.y,
                pointerEvents: "none",
                transform: "translate(-50%, -50%)",
            }}>{TouchDragIcon}</div>}
        </DragAndDropContext.Provider>
    )
};

export default function useDragAndDrop() {
    return React.useContext(DragAndDropContext);
}
export { useDragAndDrop, DragAndDropProvider };