import React from 'react';
import { useFunctionRef } from '../Functions/useFunctionRef';
import { useParams, useNavigate, useLocation } from 'react-router';
import { 
    ChevronLeft as BackIcon,
    ChevronRight as NextIcon,
    X as ExitIcon
} from '../Components/Icons';

import './Journey.scss';
import { Link } from '../Components/Link';
import RoundedIcon from '../Components/Generic/RoundedIcon';
import { addClassNames } from '../Functions/Helper/Component';
import TouchIndicator from '../Components/Generic/TouchIndicator';

function JourneyStepsIndicator({ stepNames, stepAliases, getStepPath, currentStep, allHighlighted }) {
    const JourneyMobileSteps = [];
    const JourneyProgressSteps = [];
    let currentJourneyStep;

    stepNames.forEach((stepName, index) => {
        if (!stepName) return;
        if (index) {
            JourneyProgressSteps.push(
                <div key={`${index}-divider`} className="journey-step-divider"></div>
            );
        }

        const JourneyDisplay = (
            <div key={index} className={`journey-step-indicator ${allHighlighted || index <= currentStep ? 'completed' : ''}`}>
                <div className="journey-step-index">{index + 1}</div>
                <div className="journey-step-name">{stepName}</div>
            </div>
        );

        const JourneyStep = (className) => (
            <Link key={`${index}-step`} className={className} to={getStepPath(stepAliases[index])}>
                {JourneyDisplay}
            </Link>
        );
        if ((!currentStep && !index) || currentStep === index) currentJourneyStep = JourneyDisplay; 
        JourneyMobileSteps.push(JourneyStep("dropdown-item unstyled w-100"));
        JourneyProgressSteps.push(JourneyStep("unstyled"));
    });

    return (
        <>
            <div className="journey-steps px-5 my-2 mb-4" >{JourneyProgressSteps}</div>
            <div className='journey-steps mobile d-flex flex-nowrap justify-content-center align-items-center mb-1'>
                <div className="dropdown">
                    <button
                        className="btn btn-transparent dropdown-toggle d-flex flex-nowrap align-items-center gap-2 mb-2" 
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {currentJourneyStep}
                    </button>
                    <div className="dropdown-menu dropdown-menu-center" >
                        {JourneyMobileSteps}
                    </div>
                </div>
            </div>
        </>
    );
}

function JourneyStep({
    journeyTitle,
    stepNames,
    currentStep,
    setCurrentStep,
    finalRoute,
    progress,
    StepComponent,
    stepAliases,
    ...props
}) {
    const [onNext, nextCallback] = useFunctionRef(true);
    const [onBack, backCallback] = useFunctionRef(true);
    const navigate = useNavigate();
    const {id, getStepPath, returnUrl} = useCurrentNavigation();
    const next = React.useCallback(async (triggerCallback = true) => {
        if (triggerCallback && nextCallback && !await nextCallback()) return;
        if (currentStep + 1 < stepNames.length) {
            setCurrentStep(currentStep + 1);
        } else if (finalRoute) {
            navigate(typeof finalRoute === "function"? finalRoute(id) : finalRoute);
        } else if (returnUrl) {
            navigate(decodeURIComponent(returnUrl));
        }
    }, [nextCallback, navigate, currentStep, setCurrentStep, finalRoute, id, stepNames.length, returnUrl]);

    const back = React.useCallback(async (triggerCallback = true) => {
        if (triggerCallback && backCallback && !await backCallback()) return;
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        } 
    }, [backCallback, currentStep, setCurrentStep]);
    const stepProps = { ...props, onNext, next, onBack, back };
    let exitLink = React.useMemo(() => {
        if (returnUrl !== "forced")
            return (
                <Link to={returnUrl || "/"} className="text-secondary position-absolute px-3 l-0">
                    <RoundedIcon 
                        className="border border-secondary"
                        Icon={ExitIcon}
                        backgroundColor="var(--mo-light)"
                        color="var(--mo-dark)"
                    />
                </Link>
            );
        else return <></>;
    }, [returnUrl]);
    
    return (
        <div className="journey-step px-1 position-relative">
            {StepComponent.SideComponent && (
                <div className='position-absolute r-3 t-3'><TouchIndicator style={{ fontSize: "12px"}}/></div>
            )}
            <div className='journey-header bg-white pt-3'>
                <div className="journey-title px-3 mb-3">{exitLink}<h2 className="h4">{journeyTitle}</h2></div>
                <JourneyStepsIndicator
                    stepNames={stepNames}
                    stepAliases={stepAliases}
                    currentStep={currentStep}
                    getStepPath={getStepPath}
                    allHighlighted={returnUrl !== "forced"}
                />
                {(!returnUrl || returnUrl === "forced")? (

                    <div className={addClassNames("journey-progress px-4", (!stepAliases?.length || stepAliases.length <= 1) && "d-none" )}>
                        <div className="journey-progress-label mb-1"  style={{ width: `${progress}%` }}>
                            <span className="text-primary">{progress}%</span> Completed
                        </div>
                        <div className="journey-progress-bar">
                            <div className="journey-progress-bar-fill" style={{ width: `${progress}%` }} />
                        </div>
                    </div>
                ): <div className="journey-progress px-4"><div className="journey-progress-bar">
                <div className="journey-progress-bar-fill" style={{ width: `100%` }} />
            </div></div>} 
                
            </div>

            <div className="journey-body px-4"><StepComponent {...stepProps}/></div>
            <div className={`journey-actions py-3 px-4${!currentStep ? ' flex-row-reverse' : ''}`}>
                {StepComponent.Actions? <StepComponent.ActionButtons {...{next, back}}/> : (
                    <React.Fragment>
                        {
                            currentStep > 0 && (
                                <button 
                                    className="journey-action btn btn-outline-secondary rounded-pill"
                                    onClick={back}
                                    >
                                    <span className="me-2"><BackIcon/></span> Back
                                </button>
                            )
                        }
                        {
                            (currentStep + 1 < stepNames.length || finalRoute) && (
                                <button 
                                    className='journey-action btn btn-primary rounded-pill'
                                    onClick={next}
                                >
                                    Save & Next <span className="ms-2"><NextIcon/></span>
                                </button>

                            )
                        }
                    </React.Fragment>
                )}
                
            </div>

        </div>
    );
}

function JourneySidePanel({SideComponent, ...props}) {
    if (!SideComponent) return <div className='journey-side-panel empty'/>;
    return (
        <SideComponent className="journey-side-panel" {...props}/>
    );
}

function getProgress(totalStepWeight, StepComponents, currentStep, currentStepProgress = 0) {
    let completedStepWeight = 0;
    for (let i = 0; i < currentStep; i++) {
        completedStepWeight += StepComponents[i].stepWeight || 1;
    }
    completedStepWeight += (StepComponents[currentStep].stepWeight || 1) * (currentStepProgress || 0);
    return Math.round((completedStepWeight / totalStepWeight) * 100);

}
function useCurrentNavigation() {
    const { pathname, hash, search } = useLocation();
    const { id, step } = useParams();
    const returnUrl = React.useMemo(() => {
        let returnUrl;
        search.substring(1).split("&").forEach(param => {   
            const [key, value] = param.split("=");
            if (key === "returnUrl") returnUrl = value;
        });
        if (returnUrl) {
            return decodeURIComponent(returnUrl);
        } else {
            return undefined;
        }
    }, [search]);
    const getStepPath = React.useCallback((newStep) => {
        const pathnames = pathname.split("/").filter(a => a);
        if (id) {
            pathnames[pathnames.length - 2] = newStep;
            pathnames[pathnames.length - 1] = id;
        } else if (step) {
            pathnames[pathnames.length - 1] = newStep;
        } else {
            pathnames.push(newStep);
        }
        return `/${pathnames.join("/")}${hash}${search}`;
    }, [pathname, id, step, hash, search]);
    return {step, getStepPath, returnUrl, id};
}

function useSteppedRoute(stepAliases) {
    const navigate = useNavigate();
    const {step, getStepPath} = useCurrentNavigation();
    
    let currentStep = React.useMemo(() => {
        let currentStep = -1;
        if (step) {
            if (isNaN(step)) {
                currentStep = stepAliases.indexOf(step);
            } else {
                currentStep = parseInt(step);
            }
        }
        if (currentStep < 0 || (currentStep >= stepAliases.length && stepAliases.length > 0)) {
            if (currentStep < 0) {
                currentStep = 0;   
            } else {
                currentStep = stepAliases.length - 1;
            }
            navigate(getStepPath(stepAliases[currentStep]));
        }
        return currentStep;
    }, [step, stepAliases, navigate, getStepPath]);

    const setCurrentStep = React.useCallback((newStep) => {
        if (isNaN(step)) {
            newStep = stepAliases[newStep];
            if (newStep) navigate(getStepPath(newStep));
        }
    }, [step, stepAliases, navigate, getStepPath]);

    return [currentStep, setCurrentStep];
}

function CurrentJourneyStep({ 
    stepNames, stepAliases, StepComponents, totalStepWeight, ..._props 
}) {
    const [currentStep, setCurrentStep] = useSteppedRoute(stepAliases);
    const [currentStepProgress, setCurrentStepProgress] = React.useState(0);
    const StepComponent = StepComponents[currentStep];
    const stepName = stepNames[currentStep];

    React.useEffect(() => {
        setCurrentStepProgress(0);
    }, [currentStep]);

    const props = { 
        ..._props,
        
        currentStep,
        setCurrentStep,        
        stepNames,
        stepName,
        stepAliases,

        progress: getProgress(
            totalStepWeight,
            StepComponents,
            currentStep,
            currentStepProgress
        ),
        setCurrentStepProgress
    };
    const journeyContainer = React.useRef();

    return (
        <div ref={journeyContainer} className="journey-wrapper">
            <div className={addClassNames("journey-container", StepComponent.SideComponent && " with-side-panel")}>
            <JourneyStep StepComponent={StepComponent} {...props}/>
            <JourneySidePanel
                journeyContainer={journeyContainer}
                SideComponent={StepComponent.SideComponent}
                {...props}
            />
            </div>
        </div>
    )
    
}

export default function Journey({journeyTitle = "Complete the Steps", Provider, finalRoute}, ...StepComponents) {
    // if (StepComponents.length <= 1) throw new Error('please provide more than one step for journey');
    const stepNames = StepComponents.map(step => step.stepName || step.name);
    const stepAliases = StepComponents.map((step, idx) => 
        step.stepAlias || stepNames[idx].replace(/\s/g, '-').toLowerCase()
    );
    const totalStepWeight = StepComponents.reduce((total, step) => total + (step.stepWeight || 1), 0);

    return function (_props) {
        const props = {
            stepNames,
            stepAliases,
            totalStepWeight,
            journeyTitle,
            finalRoute,
            StepComponents,
            ..._props
        };
        if (Provider) return <Provider><CurrentJourneyStep {...props}/></Provider>;
        else return <CurrentJourneyStep {...props}/>;
    };
}