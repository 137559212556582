import useMyABCCompute from "./MyABCCompute";
import useMyIdealRoleCompute from "./MyIdealRoleCompute";
import useMyRACCompute from "./MyRACCompute";
import useRequirementsAchievementCompute from "./RequirementsAchievementCompute";

export default function useCompute() {
    useMyABCCompute();
    useMyIdealRoleCompute();
    useMyRACCompute();
    useRequirementsAchievementCompute();
}