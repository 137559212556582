import React from 'react';
import Card from './Generic/Card';
import LoadingSpinner from './Generic/LoadingSpinner';
import { 
    PlusCircle as AddIcon,
    Trophy as AchievementIcon,
    PencilSquare as EditIcon
} from './Generic/Icons';
import useAchievementOptions from '../Hooks/useAchievementOptions';
import HookedAchievementModal from './AchievementHookedModal';
import './AchievementsCard.scss';
import SearchForm from './SearchForm';
import HookedProfessionModal from './ProfessionHookedModal';
import { abcToFormData } from '../Functions/Helper/Model';
import { useLocation } from 'react-router';
import useConstant from '../Provider/ConstantContext';

export default function AchievementsDetails(props) {
    const [search, setSearch] = React.useState("");
    
    const location = useLocation();
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setSearch(queryParams.get('q') || "");
    }, [location.search]);
    
    const [options, loading] = useAchievementOptions(search);
    const [achievement, setAchievement] = React.useState({});
    const onClose = React.useCallback(() => setAchievement({}), []);
    const { Descriptions } = useConstant();

    return (
        <Card
            title="Your Achievement Statements"
            description={Descriptions['achievement']}
            classNames={{body: "position-relative"}}
            {...props}
        >
            <div className="d-flex flex-row justify-content-start align-items-center gap-3 mb-3">
                <SearchForm 
                    className="flex-grow-1" 
                    placeholder="Search Achievements..."
                    search={search}
                    setSearch={setSearch}
                />
                <HookedAchievementModal 
                    value={achievement}
                    onChange={setAchievement}
                    onClose={onClose}
                    triggerName={<><AddIcon className="icon"/> Add Achievements</>}
                />
            </div>
            <div className="d-flex flex-column gap-3 px-2">
                {loading? <LoadingSpinner/> : options.filter(option => Array.isArray(option.options) && option.options.length).map(option => {

                    return (
                        <div key={option.key}>
                            <div className='d-flex flex-row flex-nowrap align-items-center gap-2' >
                                <div className="flex-grow-1">{option.label}</div>
                                <HookedProfessionModal 
                                    value={option.role}
                                    triggerName={<EditIcon className="icon me-0"/>}
                                />
                            </div>
                            <ul className="achievements-list">
                                {option.options.map(({label, abc, value}) => {
                                    return (
                                        <li key={value}
                                            className='d-flex flex-row flex-nowrap align-items-center gap-2'
                                        >
                                            <AchievementIcon width="24" height="24" className="icon"/>
                                            <div className="achievement-detail fs-medium">{label}</div>
                                            <HookedAchievementModal 
                                                initialValue={abcToFormData(abc)}
                                                triggerName={<EditIcon className="icon me-0"/>}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
            
        </Card>
    ); 
}