import React from 'react';
import { Briefcase as BriefcaseIcon } from './Icons';
import SimpleCard from './Generic/SimpleCard';
import { Link } from './Link';
import RoleStatus from '../Constants/RoleStatus.json';
import ApplicationStatus from '../Constants/ApplicationStatus.json';
import useRole from '../Provider/TableContext/RoleContext';

export default function JobApplicationAcceptancePanel(props) {
    const { roles } = useRole();
    const applicationPercentage = React.useMemo(() => {
        let applicationCount = 0;
        let applicationAcceptedCount = 0;

        roles.forEach(role => {
            if (role.mcb_applyingrolestatus === RoleStatus.Applied) {
                if (role.mcb_role)
                if (role.mcb_applicationstatus >= ApplicationStatus['Submitting Application']) {
                    applicationCount++;
                    if (role.mcb_applicationstatus >= ApplicationStatus['Offered']) {
                        applicationAcceptedCount++;
                    }
                }
            }
        });

        if (applicationCount === 0) return '0';
        else return (applicationAcceptedCount / applicationCount).toString(2);
    }, [roles]);
    
    return (
        <SimpleCard
            Icon={BriefcaseIcon}
            backgroundColor="var(--mo-primary)" 
            {...props}
        >
            <div className='d-flex flex-row flex-nowrap justify-content-between align-items-center gap-3 flex-grow-1'>
                <div>
                    <span>You have </span><strong
                        className="rounded-pill px-2 py-1 mx-1"
                        style={{backgroundColor: "var(--mo-gray-400)", fontSize: "0.89em"}}
                    >{applicationPercentage}%</strong>
                    <br/><span>Application Acceptance</span></div>
                <Link className="btn btn-primary" to="/applying-job-setup">Add Job Application</Link>
            </div>
            
        </SimpleCard>
    );
}