import React from 'react';
import SimpleCard from './Generic/SimpleCard';
import { TrophyFill as AchievementIcon } from './Generic/Icons';
import RoleStatus from '../Constants/RoleStatus.json';
import useABC from '../Provider/TableContext/ABCContext';
import useRole from '../Provider/TableContext/RoleContext';
import HookedAchievementModal from './AchievementHookedModal';

function TriggerButtonComponentRenderer({className, ...props}, ref)  {
    return <button className="btn btn-outline-secondary" ref={ref} {...props}>Add an Achievement</button>
}

const TriggerButtonComponent = React.forwardRef(TriggerButtonComponentRenderer);

export default function JobApplicationAcceptancePanel(props) {
    const { roles } = useRole();
    const { abcs } = useABC();
    const count = React.useMemo(() => {
        const experiences = roles.filter(role => [RoleStatus.Current, RoleStatus.Previous].includes(role?.mcb_rolestatus));
        return abcs.filter(abc => experiences.some(experience => 
            experience.mcb_roleid === abc?._mcb_role_value)).length  || 0;
    }, [roles, abcs]);
    
    return (
        <SimpleCard
            Icon={AchievementIcon}
            backgroundColor="#5D5FEF" 
            {...props}
        >
            <div className='d-flex flex-row flex-nowrap justify-content-between align-items-center gap-3 flex-grow-1'>
                <div>
                    <span>You have got</span><strong
                        className="rounded-pill px-2 py-1 mx-1"
                        style={{backgroundColor: "var(--mo-gray-400)", fontSize: "0.89em"}}
                    >{count}</strong>
                    <br/><span>awesome Achievements</span></div>
                <HookedAchievementModal TriggerButtonComponent={TriggerButtonComponent} />
                
            </div>
            
        </SimpleCard>
    );
}