import { rgbaToHex } from "./Color";

function parseStyle(node) {
    if (!node?.style) return [];
    const styleChild = [];
    let computedStyle = node.style;
    try {
        computedStyle = window.getComputedStyle(node);
    } catch (err) {
        console.warn(err, node);
    }
    if(node.style.cssText) {
        node.style.cssText.split(";").forEach((style) => {
            if (style) {
                let [key, value] = style.split(":");
                key = key.trim();
                value = value.trim();
                if (value) switch(key) {
                    case "font-weight":
                        const fontWeight = parseInt(value);
                        if (isNaN(fontWeight)) {
                            if (value === "bold") styleChild.push({tag: "w:b" });
                            else if (value === "bolder") styleChild.push({tag: "w:bCs"});
                        } else if(fontWeight >= 700) styleChild.push({tag: "w:b"});
                        break;
                    case "font-style":
                        if (value === "italic") styleChild.push({tag: "w:i" });
                        break;
                    case "text-decoration":
                        value.split(" ").forEach((decoration) => {
                            switch (decoration.trim()) {
                                case "underline":
                                    styleChild.push({attrs: {"w:val": "single"}, tag: "w:u"});
                                    break;
                                case "line-through":
                                    styleChild.push({attrs: {"w:val": "strike"}, tag: "w:strike"});
                                    break;
                                case "overline":
                                    styleChild.push({attrs: {"w:val": "overline"}, tag: "w:u"});
                                    break;
                                default:
                                    break;
                            }
                        });
                        break;
                    case "text-align":
                        styleChild.push({attrs: {"w:val": value}, tag: "w:jc"});
                        break;
                    case "color":
                        styleChild.push({attrs: {"w:val": rgbaToHex(value)}, tag: "w:color"});
                        break;
                    case "background-color":
                        styleChild.push({attrs: {"w:val": rgbaToHex(value)}, tag: "w:highlight"});
                        break;
                    case "font-size":
                        const fontSize = Math.round(parseFloat(computedStyle.fontSize) * 1.5);
                        if (!isNaN(fontSize)) styleChild.push({attrs: {"w:val": fontSize + ""}, tag: "w:sz"});
                        break;
                    case "font-family":
                        const fontFamily = value?.split(",")[0]?.trim()?.replace(/['"]+/g, '');
                        if (fontFamily) styleChild.push({attrs: {"w:val": fontFamily}, tag: "w:rFonts"});
                        break;
                    case "line-height":
                        styleChild.push({attrs: {"w:val": value}, tag: "w:spacing"});
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return styleChild;
    
}

function cloneOOXMLData(data, parentOOXML) {
    let result = {
        _attrs: data._attrs || {},
        _fTextNode: data._fTextNode,
        _children: [],
        _parent: parentOOXML,
    };
    if (data._tag) result._tag = data._tag;
    if (data._text) result._text = data._text;
    if (data._children) data._children.map((child) => result._children.push(cloneOOXMLData(child, result)));
    return result;
}

function convertToOOXML(data, parentOOXML) {
    let result = { _attrs: data.attrs || {}, _children: []};
    if (parentOOXML) result._parent = parentOOXML;

    if (data.tag === "TEXT_NODE") {
        result._fTextNode = true;
        result._text = data.children;
    } else {
        result._tag = data.tag;
        result._fTextNode = false;
        
        if (data.children) data.children.forEach((child) =>
            result._children.push(convertToOOXML(child, result))
        );

        if (data.ooxmlChildren) data.ooxmlChildren.forEach((child) =>
            result._children.push(cloneOOXMLData(child, result))
        );
    }

    return result;
}

/**
 * 
 * @param {ChildNode} node 
 * @param {string} pr 
 * @returns 
 */
function parseHTMLToOoxmlCursively(ctx, node, parentOOXML = null, pr = []) {
    if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.trim()) {
            const style = parseStyle(node.parentNode);
            return [convertToOOXML({
                tag: "w:r",
                children: [
                    {tag: "w:rPr", ooxmlChildren: pr, children: style},
                    {
                        tag: "w:t", attrs: {"xml:space": "preserve"},
                        children: [{tag: "TEXT_NODE", children: node.textContent}]
                    }
                ]
            }, parentOOXML)];
        }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        let result = [];
        const ooxmlChildren = [];
        if (node.nodeName === 'STYLE') return [];
        if (node.nodeName === 'P' || node.nodeName === 'DIV' || /^H\d/.test(node.nodeName)) {
            const style = node.nodeName === 'P' ? 'Normal' : node.nodeName.replace("H", "Heading ");
            const pRp = {tag: "w:pPr", children: [{ attrs: {"w:val": style, tag: "w:pStyle"} }],  ooxmlChildren: pr};
            const toNewParagraph = ooxmlChildren => convertToOOXML({ tag: "w:p", children: [pRp], ooxmlChildren}, parentOOXML);
            let children = [];
            Array.from(node.childNodes).forEach((cn) => {
                const processedOOXML = parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, pr);
                processedOOXML.forEach(node => {
                    if (node._tag === 'w:p') {
                        if (children.length) {
                            result.push(toNewParagraph(children));
                            children = [];
                        }
                        result.push(node);
                    } else if (node._tag === "w:tbl") {
                        if (children.length) {
                            result.push(toNewParagraph(children));
                            children = [];
                        }
                        result.push(node);
                    } else {
                        children.push(node);
                    }
                });
            });

            if (children.length) {
                result.push(toNewParagraph(children));
            }
            return result;
        }  else switch (node.nodeName) {
            case 'BR':
                return [convertToOOXML({ tag: "w:r", children: [{tag: "w:br"}]}, parentOOXML)];
            case 'OL':
            case 'UL':
                ctx.maxNumId ++;
                ctx.numberingDetails.push({
                    numId: ctx.maxNumId,
                    listType: node.nodeName
                });
                const numId = ctx.maxNumId.toString();
                const listGroup = [];
                node.childNodes.forEach(li => {
                    const matches = /ql-indent-(\d+)/gi.exec(li.className);
                    const ilvl = matches ? matches[1] : "0";

                    Array.from(li.childNodes).forEach(cn => 
                        parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, pr)
                            .forEach(pr => ooxmlChildren.push(pr))
                    );

                    listGroup.push(convertToOOXML({ tag: "w:p", ooxmlChildren, children: [
                        {tag: "w:pPr", children: [
                            {tag: "w:numPr", children: [
                                {tag: "w:ilvl", attrs: {"w:val": ilvl}},
                                {tag: "w:numId", attrs: {"w:val": numId}}
                            ]}
                        ]},
                    ]}, parentOOXML));
                });
                return listGroup;
            case "TABLE":
                const tableProps = [
                    // {tag: "w:tblStyle", attrs: { "w:val": "667" }, children: []},
                    // {tag: "w:tblW", attrs: {"w:w": "0", "w:type": "auto"}, children: []},
                    // {tag: "w:tblLook", attrs: {
                    //     "w:val": "04A0",
                    //     "w:firstRow": "1",
                    //     "w:lastRow": "0",
                    //     "w:firstColumn": "1",
                    //     "w:lastColumn": "0",
                    //     "w:noHBand": "0",
                    //     "w:noVBand": "1"
                    //   }, children: []}                          
                ];

                if (node.hasAttribute("cellspacing")) {
                    const spacing = Math.round(parseInt(node.getAttribute("cellspacing")) * 1.5);
                    if (!isNaN(spacing)) {
                        tableProps.push({ tag: "w:tblCellSpacing", attrs: { "w:w": `${spacing}`, "w:type": "dxa" } });
                    }
                }

                // if (node.hasAttribute("border")) {
                //     const borderSizeVal = Math.round(parseInt(node.getAttribute("border")) * 1.5);
                //     if (!isNaN(borderSizeVal)) {
                //         const borderSize = borderSizeVal + "";
                //         tableProps.push({ tag: "w:tblBorders", children: [
                //             { tag: "w:top", attrs: { "w:val": "single", "w:sz": borderSize, "w:space": "0", "w:color": "auto" } },
                //             { tag: "w:left", attrs: { "w:val": "single", "w:sz": borderSize, "w:space": "0", "w:color": "auto" } },
                //             { tag: "w:bottom", attrs: { "w:val": "single", "w:sz": borderSize, "w:space": "0", "w:color": "auto" } },
                //             { tag: "w:right", attrs: { "w:val": "single", "w:sz": borderSize, "w:space": "0", "w:color": "auto" } },
                //             { tag: "w:insideH", attrs: { "w:val": "single", "w:sz": borderSize, "w:space": "0", "w:color": "auto" } },
                //             { tag: "w:insideV", attrs: { "w:val": "single", "w:sz": borderSize, "w:space": "0", "w:color": "auto" } }
                //         ]});
                //     }
                // }

                if (tableProps.length) {
                    ooxmlChildren.push(convertToOOXML({ tag: "w:tblPr", children: tableProps }, parentOOXML));
                }
                const tblGridChildren = [];
                ctx.vMerges = {};
                Array.from(node.childNodes).forEach(cn => {
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML).forEach(pr => ooxmlChildren.push(pr));
                    tblGridChildren.push({tag: "w:gridCol", attrs: {"w:w": "2000"}});
                });

                delete ctx.vMerges;
                return [convertToOOXML({
                    tag: "w:tbl",
                    ooxmlChildren,
                    children: [
                        {tag: "w:tblPr", children: tableProps },
                        // {tag: "w:tblGrid", children: tblGridChildren}
                    ]
                }, parentOOXML)];
            case "TR":
                if (!ctx.vMerges) ctx.vMerges = {};
                var rowId = 0;
                const processRowIds = () => {
                    while (ctx.vMerges[rowId] && ctx.vMerges[rowId] > 1) {
                        ctx.vMerges[rowId] -= 1;
                        ooxmlChildren.push(convertToOOXML({ 
                            tag: "w:tc",
                            children: [{
                                tag: "w:tcPr", children: [
                                    {tag: "w:vMerge", attrs: {"w:val": "continue"}},
                                    ...(ctx?.lastCellProps || [])
                                ]}
                            ]
                        }, parentOOXML));
                        rowId++;
                    }
                }
                Array.from(node.childNodes).forEach((cn) => {
                    processRowIds();
                    const rowSpan = parseInt(cn.rowSpan);
                    if (!isNaN(rowSpan) && rowSpan > 1) {
                        ctx.vMerges[rowId] = rowSpan;
                    }
                    rowId++;
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML).forEach(pr => ooxmlChildren.push(pr));
                });
                processRowIds();
                return [convertToOOXML({
                    tag: "w:tr",
                    ooxmlChildren,
                    children: [{tag: "w:trPr", children: []}]
                }, parentOOXML)];
            case "TD":
                let children = [];          
                Array.from(node.childNodes).forEach((cn) => {  
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML).forEach(pr => {
                        if (pr._tag === "w:r") children.push(pr);
                        else if (children.length) {
                            ooxmlChildren.push(convertToOOXML({
                                tag: "w:p",
                                children: [{tag: "w:pPr", children: []}],
                                ooxmlChildren: children
                            }));
                            children = [];
                         } else ooxmlChildren.push(pr);
                    });
                });
                if (children.length) {
                    ooxmlChildren.push(convertToOOXML({
                        tag: "w:p",
                        children: [{tag: "w:pPr", children: []}],
                        ooxmlChildren: children
                    }));
                }
                const computedStyle = window.getComputedStyle(node);
                const cellMargins = [];
                const cellBorders = [];

                ["left", "top", "right", "bottom"].forEach((side) => {
                    const padding = Math.round(parseInt(computedStyle.getPropertyValue(`padding-${side}`).replace("px", "")) * 15);
                    if (!isNaN(padding)) {
                        cellMargins.push({ tag: `w:${side}`, attrs: { "w:w": `${padding}`, "w:type": "dxa"} });
                    }
                    const borderSize = Math.round(parseInt(computedStyle.getPropertyValue(`border-${side}-width`).replace("px", "")) * 15);
                    const borderColor = computedStyle.getPropertyValue(`border-${side}-color`);
                    const borderStyle = computedStyle.getPropertyValue(`border-${side}-style`);
                    
                    const borderAttrs = {};
                    if (!isNaN(borderSize)) {
                        borderAttrs["w:sz"] = `${borderSize}`;
                        borderAttrs["w:space"] = "0";
                        borderAttrs["w:type"] = "dxa";
                    }
                    if (borderColor && borderColor !== "rgba(0, 0, 0, 0)") {
                        borderAttrs["w:color"] = rgbaToHex(borderColor);
                    }
                    if (isNaN(borderSize) || !borderSize || !borderColor || borderColor === "rgba(0, 0, 0, 0)") {
                        borderAttrs["w:val"] = "none";
                    } else if (borderStyle) {
                        if (borderStyle === "inset") {
                            borderAttrs["w:sz"] = `${Math.round(borderSize / 2)}`;
                        }
                        borderAttrs["w:val"] = ({
                            "none":	"none",
                            "hidden": "none",
                            "dotted": "dotted",
                            "dashed": "dashed",
                            "solid": "single",
                            "double": "double",
                            "groove": "threeDEngrave",
                            "ridge": "threeDEmboss",
                            "inset": "inset",
                            "outset": "outset",
                        })[borderStyle];
                    }
                    if (Object.keys(borderAttrs).length) 
                        cellBorders.push({ tag: `w:${side}`, attrs: borderAttrs });
                });
                
                const cellProps = [
                    { tag: "w:tcMar", children: cellMargins},
                    { tag: "w:tcBorders", children: cellBorders}
                ];
                
                const valign = node.getAttribute("valign") || "center";    
                cellProps.push({ tag: "w:vAlign", attrs: { "w:val": valign } });
                
                const backgroundColor = computedStyle.getPropertyValue("background-color");
                if (backgroundColor && backgroundColor !== "rgba(0, 0, 0, 0)") {
                    cellProps.push({ tag: "w:shd", attrs: { "w:fill": rgbaToHex(backgroundColor) } });
                }
                ctx.lastCellProps = [...cellProps];

                if (node.colSpan && node.colSpan > 1) {
                    cellProps.push({ tag: "w:gridSpan", attrs: { "w:val": `${node.colSpan}` } });
                }
                if (node.rowSpan && node.rowSpan > 1) {
                    cellProps.push({ tag: "w:vMerge", attrs: { "w:val": "restart" } });
                }
                return [convertToOOXML({
                    tag: "w:tc",
                    ooxmlChildren,
                    children: [{tag: "w:tcPr", children: cellProps}]
                }, parentOOXML)];
            case "SPAN":
                result = [];
                const rpr = parseStyle(node);
                Array.from(node.childNodes).forEach(cn => {
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML).forEach(pr => {
                        if (pr._tag === "w:r") {
                            pr._children.forEach(child => {
                                if (child._tag === "w:rPr") {
                                    rpr.forEach(style => {
                                        if (!child._children.find(a => a._tag === style.tag))
                                            child._children.push(convertToOOXML(style, child));
                                    });
                                }
                            });
                            result.push(pr);
                        } else {
                            result.push(convertToOOXML({
                                tag: "w:r",
                                children: [{tag: "w:rPr", children: rpr}],
                                ooxmlChildren: [pr]
                            }));
                        }
                    });
                    
                });
                return result;

            case 'A':
                Array.from(node.childNodes).forEach(cn => {
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, pr).forEach(pr => ooxmlChildren.push(pr));
                });
                const rtLinkId = ctx.links.length;
                ctx.links.push(node.href);
                result = [convertToOOXML({ tag: "w:hyperlink", ooxmlChildren, attrs: {"r:id": `rtLinkId${rtLinkId}`}}, parentOOXML)];
                return result;
            
            default:
                let newRPR = [...pr];
                switch (node.nodeName)  {
                    case 'B':
                    case 'STRONG':
                        newRPR.push(convertToOOXML({ tag: "w:b" }));
                        break;
                    case 'I':
                    case 'EM':
                        newRPR.push(convertToOOXML({ tag: "w:i" }));
                        break;
                    case 'U':
                        newRPR.push(convertToOOXML({ attrs: {"w:val": "single"}, tag: "w:u" }));
                        break;

                    default:
                        break;
                }
                Array.from(node.childNodes).forEach(cn => {
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, newRPR).forEach(pr => result.push(pr));
                });
                return result;
        }
    }
    return [];
}


/**
 * 
 * @param {Node} node 
 */
export function removeParagraph(doc, node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        const newChildren = [];
        let child;
        do {
            child = node.childNodes[0];
            if (child) {
                const removedNodes = removeParagraph(doc, child);
                if (Array.isArray(removedNodes)) newChildren.push(...removedNodes);
                else newChildren.push(removedNodes);
                node.removeChild(child);
            }
        } while(child);
        newChildren.forEach(child => node.appendChild(child));

        if (node.nodeName === 'P' && node.childNodes.length > 0) { 
            const children = []
            node.childNodes.forEach(child => {
                children.push(child);
            });
            children.push(doc.createElement('br')); 
            return children;
        }
    } 

    return node;
}

export function htmlToOoxml(ctx, html, parentNode, ppr) {
    const shadowHost = document.createElement('div');
    const shadowRoot = shadowHost.attachShadow({ mode: 'closed' });
    shadowRoot.innerHTML = html;
    const result = [];
    document.body.appendChild(shadowHost);
    if (ctx.removeParagraph) removeParagraph(document, shadowRoot);
        Array.from(shadowRoot.childNodes).map((cN) =>
            parseHTMLToOoxmlCursively(ctx, cN, parentNode, ppr)
        .forEach(d => result.push(d))
    );
    document.body.removeChild(shadowHost);
    return result;
}

export function processRichNode(ctx, node) {
    
    if (node._fTextNode) {
        let mainMatch = /~~~(INLINE_)?RICHTEXT\s(.*)~~~/gi.exec(node._text);
        if (mainMatch) {
            if (mainMatch[1] === "INLINE_") ctx.removeParagraph = true;
            const result = htmlToOoxml(ctx, mainMatch[2], node._parent);
            if (mainMatch[1] === "INLINE_") ctx.removeParagraph = false;
            return result;
        } else {
            return node;
        }
    } else if (node._tag === "w:r" || node._tag === "w:t") {
        const finder = node._tag === "w:r"? a => a._tag === "w:t" : a => a._fTextNode;
        const tNode = node._children.find(finder);
        if (tNode) {
            const result = processRichNode(ctx, tNode);
            if (Array.isArray(result)) return result;
        }
        return node;
            
    } else {
        const children = [];
        for (let c = 0; c < node._children.length; c++) {
            const child = node._children[c];
            if (child._tag === "w:p") ctx.afterParagraph = [];
            
            const result = processRichNode(ctx, child);
            
            if (Array.isArray(result)) {
                if(Array.isArray(ctx.afterParagraph)) {
                    const [first, ...rest] = result;
                    if (!first) continue;
                    if (first && first._tag === "w:p") {
                        first._children.forEach(firstChild => {
                            if(firstChild._tag === 'w:r') {
                                children.push({...firstChild, _parent: node});
                            }
                        });
                    } else if (first._tag === "w:tbl") {
                        ctx.afterParagraph.push(first);
                    } else children.push({...first, _parent: node});

                    rest.forEach(restItem => {
                        if (restItem._tag === "w:p") {
                            ctx.afterParagraph.push(restItem);
                        } else {
                            children.push({...restItem, _parent: node});
                        }
                    });

                } else {
                    result.map(r => children.push({...r, _parent: node}));
                }
            } else if (result) {
                children.push({ ...result, _parent: node});
            }

            if (child._tag === "w:p") {
                ctx.afterParagraph.forEach(afterParagraph => 
                    children.push({...afterParagraph, _parent: node})
                );
                ctx.afterParagraph = false;
            }
        }
        return {...node, _children: children.map(a => ({...a, _parent: node}))};
    }
}

export function maxAbstractId(nodes, max = 0) {
    if (nodes?._children?.length) nodes._children.forEach(node => {
        if (node._tag === 'w:abstractNum') {
            max = Math.max(max, parseInt(node._attrs['w:abstractNumId']));
        }
        max = maxNumId(node, max);
    });
    return max;
}

export function maxNumId(nodes, max = 0) {
    if (nodes?._children?.length) nodes._children.forEach(node => {
        if (node._tag === 'w:numId') {
            max = Math.max(max, parseInt(node._attrs['w:val']));
        }
        max = maxNumId(node, max);
    });
    return max;
}

export function removeNumId(maxNumId, xml) {
    if (xml._tag === 'w:numbering') {
        xml.children = xml._children.filter(childNode => {
            return removeNumId(maxNumId, childNode);
        });
    } else {
        return xml._children.every(childNode => {
            if(childNode._tag === 'w:numId') {
                return parseInt(childNode._attrs['w:val']) <= maxNumId
            } else if (!childNode._fTextNode) {
                return removeNumId(maxNumId, childNode);
            } 
            return true;
            
        });
    }
}

export function abstractOrderedList(id, parentOOXML) {
    return convertToOOXML({
        tag: "w:abstractNum",
        attrs: {"w:abstractNumId": id},
        children: [
            {tag: "w:lvl", attrs: {"w:ilvl": "0"}, children: [
                {tag: "w:start", attrs: {"w:val": "1"}},
                {tag: "w:numFmt", attrs: {"w:val": "decimal"}},
                {tag: "w:lvlText", attrs: {"w:val": "%1."}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]},
            {tag: "w:lvl", attrs: {"w:ilvl": "1"}, children: [
                {tag: "w:start", attrs: {"w:val": "1"}},
                {tag: "w:numFmt", attrs: {"w:val": "lowerLetter"}},
                {tag: "w:lvlText", attrs: {"w:val": "%2."}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]},
            {tag: "w:lvl", attrs: {"w:ilvl": "2"}, children: [
                {tag: "w:start", attrs: {"w:val": "1"}},
                {tag: "w:numFmt", attrs: {"w:val": "lowerRoman"}},
                {tag: "w:lvlText", attrs: {"w:val": "%3."}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]}
        ]
    }, parentOOXML);
}

export function abstractUnorderedList(id, parentOOXML) {
    return convertToOOXML({
        tag: "w:abstractNum",
        attrs: {"w:abstractNumId": id},
        children: [
            {tag: "w:lvl", attrs: {"w:ilvl": "0"}, children: [
                {tag: "w:numFmt", attrs: {"w:val": "bullet"}},
                {tag: "w:lvlText", attrs: {"w:val": "•"}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]}
        ]
    }, parentOOXML);
}

export function numberingDetail(id, abstractId, parentOOXML) {
    return convertToOOXML({
        tag: "w:num",
        attrs: {"w:numId": id},
        children: [
            {tag: "w:abstractNumId", attrs: {"w:val": abstractId}}
        ]
    }, parentOOXML);
}

export function processRichTextXML(ctx, xml, documentComponent, reports) {
    if (documentComponent === "main.xml") {
        let body = xml._children[0];
        ctx.initialMaxNumId = maxNumId(body);
        ctx.maxNumId = ctx.initialMaxNumId;
        ctx.links = [];
        const result = processRichNode(ctx, xml);
        // ctx.links.forEach((url, idx) => {
        //     reports.links[`rtLinkId${idx}`] = {url};
        // });
        xml = result;
    } else if (documentComponent === "numbering.xml") {
        ctx.maxAbstractId = maxAbstractId(xml) + 1;
        ctx.unOrderedListId = ctx.maxAbstractId.toString();
        ctx.maxAbstractId++;
        ctx.orderedListId = ctx.maxAbstractId.toString();
        removeNumId(ctx.initialMaxNumId , xml);
        
        xml._children.unshift(
            abstractUnorderedList(ctx.unOrderedListId, xml),
            abstractOrderedList(ctx.orderedListId, xml)
        );
        
        ctx.numberingDetails.forEach(({ numId, listType }) => {
            const abstractId = listType === "OL"? ctx.orderedListId: ctx.unOrderedListId;
            const numDetail = numberingDetail(numId.toString(), abstractId, xml);
            xml._children.push(numDetail);
        });
    }
    return xml;
}

export function runOnChild(xml, childTest, callback) {
    if (xml._children) {
        xml._children.forEach(child => {
            if (childTest(child)) {
                callback(child);
            } else {
                runOnChild(child, childTest, callback);
            }
        });
    }
}