import { monthDate } from '../../Functions/Helper/Month';

export function filterDateLabel(filter) {
    const created_date = filter?.created_date || {};
    let result = "From ";

    if (created_date?.customFrom && created_date?.fromValue) {
        result += monthDate(created_date.fromValue);
    } else {
        result += "Beginning";
    }

    result += " To ";

    if (created_date?.customTo && created_date?.toValue) {
        result += monthDate(created_date.toValue);
    } else {
        result += "Today";
    }

    created_date.label = result;
    filter.created_date = created_date;
    return filter;
}

export function filterByDate(filter, value) {
    let result = true;
    if (filter?.customFrom && filter?.fromValue) {
        result &= filter?.fromValue?.getMonth() <= value?.getMonth();
    }

    if (filter?.customTo && filter?.toValue) {
        result &= filter?.toValue?.getMonth() >= value.getMonth();
    }
    return result;
}

export function valueToLabel(value) {
    if (value?.label) return value.label;
    else if (Array.isArray(value) && value.length) {
        let result = value.map(a => a?.label || a).join(", ");
        if (result.length > 25) result = result.slice(0, 25) + "...";
        return result;
    } else if (value) return value;
    else return "All";
}

export function filterByValue(filter, value) {
    if (filter === "all") return true;
    else if (Array.isArray(filter)) {
        return !filter.length || filter.some(filter => {
            return filterByValue(filter, value);
        });
    } else if (typeof filter === "object" && typeof filter?.value !== "undefined")
        return filterByValue(filter.value, value);
    else if (typeof filter?.test === "function") return filter.test(value);
    else return filter === value;
}

export function implementFilterByValue(filter) {
    filter.test = filterByValue;
    return filter;
}
