import React from 'react';
import Card from './Generic/Card';
import RichTextDisplay from './Generic/RichTextDisplay';
import { TrashFill as TrashIcon } from './Generic/Icons';
import EditIconLink from './Generic/EditIconLink';
import LinkToJourney from './Generic/LinkToJourney';
import { addClassNames } from '../Functions/Helper/Component';
import { getSectorNames } from '../Functions/Helper/Sector';
import { localeDate } from '../Functions/Helper/Month';
import { useNavigate } from 'react-router';
import useConstant from '../Provider/ConstantContext';
import { ApplicationStatuses, getRoleApplicationStatus } from '../Functions/Helper/ApplicationStatus';
import useActionDialog from '../Provider/ ActionDialogContext';

export default function ApplyingRoleSmallPanel({ role, updateRole, deleteRole,  ...props}) {
    const navigate = useNavigate();
    const DescriptionControl = React.useCallback(() =>
        <EditIconLink to={`/applying-job-setup/job-details/${role?.mcb_roleid}`}/>, [role?.mcb_roleid]);

    const FilterComponent = React.useMemo(()=> function ({label, value}) {
        return (
            <li key={value}>
                <button 
                    type="button"
                    onClick={() => {
                        if (!role.mcb_datesubmitted && value > 861400003) {
                            role.mcb_datesubmitted = new Date().toISOString();
                        }
                        updateRole({...role, mcb_applicationstatus: value}, false)
                    }}
                    className="dropdown-item gap-2 d-flex flex-nowrap align-items-center justify-content-between" 
                >{label}</button>
                
            </li>
        );
    }, [updateRole, role]);

    const CurrentApplicationStatus = React.useMemo(() => (
        getRoleApplicationStatus(role?.mcb_applicationstatus)
    ), [role]);

    const { Descriptions } = useConstant();
    const { confirm } = useActionDialog();
    
    return (
        <Card
            title={<LinkToJourney className="unstyled" to={`/job-applications/${role?.mcb_roleid}`}><span className="h4 py-3 ps-3">{role?.mcb_roletitle}</span></LinkToJourney>} 
            description={Descriptions['applying-job']}
            DescriptionControl={DescriptionControl}
            footer={
                <div className="dropdown-container mt-4 d-flex flex-row flex-nowrap btn-group w-100">
                    <LinkToJourney
                        to={`/job-applications/${role?.mcb_roleid}`}
                        className="btn btn-light-primary text-dark flex-shrink-1  flex-grow-0 min-w-max-content pe-4 d-flex"
                    >See more</LinkToJourney>
                    <button 
                        type="button"
                        className={addClassNames(
                            "btn flex-grow-1 py-2 px-3 dropdown-toggle ws-normal d-flex",
                        )}
                        style={{ backgroundColor: CurrentApplicationStatus?.color || "black", color: "white"}}
                        data-bs-toggle="dropdown" aria-expanded="false"
                    >{CurrentApplicationStatus? CurrentApplicationStatus.label : "Choose Status"}</button>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        {ApplicationStatuses.map(FilterComponent)}
                        <li key="divider" className="dropdown-divider"></li>
                        <li key="delete"><button
                            type="button"
                            onClick={async () => {
                                if (await confirm("This could not be undone. Are you sure?")) {
                                    await deleteRole(role.mcb_roleid);
                                    navigate("/job-applications");
                                }
                            }}
                            className="dropdown-item text-danger gap-2 d-flex flex-nowrap align-items-center justify-content-between" 
                        ><TrashIcon/> Delete</button></li>
                    </ul>
                </div>
            }
            {...props}
        >
            <div className="d-flex flex-column gap-2 px-3 h-100">
                <div className="flex-grow-1">
                    <p className="fs-5 mb-1 min-w-min-content"><b>Company</b>: {role?.mcb_rolecompanyname}</p>
                    <p className="fs-5 mb-1 min-w-min-content"><b>Sector</b>: {getSectorNames(`${role.mcb_sector}`)}</p>
                    <p className="fs-large mb-1 min-w-min-content"><b>Remuneration</b>: ${Number(role?.mcb_baserenumeration).toLocaleString()} {
                        role?.mcb_renumerationtype === 861400001? "per year" : "per hour"}</p>
                    <p className="fs-large mb-1 min-w-min-content"><b>Bonuses</b>: ${Number(role?.mcb_atriskrenumeration).toLocaleString()}</p>
                    {role?.mcb_datesubmitted? 
                        <p className="fs-large mb-1 min-w-min-content"><b>Submitted Date</b>: {localeDate(role?.mcb_datesubmitted)}</p>
                        : null}
                    
                    {role?.mcb_generalcomments?.length > 0? 
                        <><h5 className="mt-3">General Comments</h5><RichTextDisplay
                            value={role?.mcb_generalcomments || ""}
                            readOnly={true}
                            className="read-only fs-larger"
                            theme="snow"
                        /></>
                        : null}
                    
                </div>
            </div>
        </Card>
    );
}