export async function safeAjax({url, method = "GET", headers = {}, headerOverride, body = ""}) {
    if (!url) return;
    await window.shell.refreshToken();
    var token = await window.shell.getTokenDeferred();
    const ajaxHeader = {"Content-Type": "application/json", ...headers};
    ajaxHeader["__RequestVerificationToken"] = token;
    ajaxHeader["X-Requested-With"] = "XMLHttpRequest";
    const requestProps = { method, headers: headerOverride || ajaxHeader };
    if (body) {
        requestProps.body = body;
    }
    
    var response = await fetch(url, requestProps);

    if (response.ok) {
        const result = {};

        const entityId = response.headers.get("entityId") || response.headers.get("entityid");
        if (entityId) result.entityId = entityId;
        const contentType = response.headers.get("Content-Type") || response.headers.get("content-type");
        if (response.status !== 204) switch(contentType.split(";")[0]) {
            case "application/json":
                result.json = await response.json();
                break;
            case "text/plain":
            case "text/html":
                result.text = await response.text();
                break;
            
            case "multipart/form-data":
                result.formData = await response.formData();
                break;
            default:
                result.arrayBuffer = await response.arrayBuffer();
                break;
        }
        return result;
    } else {
        const error = new Error(response.statusText  || "Error in AJAX request");
        error.status = response.status;
        error.body = response.body;
        throw error;
    }

}

export default async function AjaxActionHandler({ requestId, data}, source) {
    if(/^\/signin/i.test(window.location.pathname)) {
        return {success: false, error: new Error("Not logged in")};
    } else {
        source.postMessage({ action: "API_REQUEST_RECV", requestId }, '*');
        try {
            const result = { success: true, result: await safeAjax(data) };
            if (result.result.arrayBuffer) result.transferList = [result.result.arrayBuffer];
            return result;
        } catch (error) {
            // Handle errors and update the responseMessage with the error details
            return { success: false, error };
        }
    }
}

