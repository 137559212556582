import React from 'react';
import Input from './Generic/Input';
import {components as ReactSelectComponents} from 'react-select';
import useSkill from '../Provider/TableContext/SkillContext';
import useActionDialog from '../Provider/ ActionDialogContext';

export default function SkillInput({highlightNotIn, onClick, tooltipIdPrefix = "skill-tooltip-", single = false, inputRef, tooltip, filter, ...props}) {
    const { skills, createSkill, deleteSkill, loading, error } = useSkill();
    const options = React.useMemo(() => {
        return (filter? skills.filter(filter) : skills).map(skill => ({
            label: skill.mcb_skill,
            value: skill.mcb_skillid,
            color: highlightNotIn && !highlightNotIn.includes(skill.mcb_skillid)? "var(--mo-danger)" : "var(--mo-primary)"
        })).sort((a, b) => a.label.localeCompare(b.label))
    }, [skills, filter, highlightNotIn]);
    const tooltips = React.useMemo(() => {
        if (tooltip && options.length) {
            return options.map((option) => (
                tooltip({ id: tooltipIdPrefix + option.value, data: option })
            ));
        } 
        return [];
    }, [tooltip, options, tooltipIdPrefix]);
    const { confirm } = useActionDialog();
    return (
        <div>
            {tooltips}
            <Input
                {...props}
                ref={inputRef}
                type={single? 'select' : 'multiselect'}
                isLoading={loading}
                options={options}
                onCreateOption={(mcb_skill) => createSkill({mcb_skill}).then((data) => {
                    if (typeof props.onChange === 'function') {
                        props.onChange([ ...(props.value || []), data?.mcb_skillid]);
                    }
                })}
                components={{
                    MultiValueLabel: (props) => {
                        return (
                            <div style={{ cursor: onClick && "pointer" }}
                                onMouseDown={(e) => {
                                    typeof onClick === "function" && onClick(props.data, e);
                                }}
                                onTouchStart={(e) => {
                                    typeof onClick === "function" && onClick(props.data, e);
                                }}
                                data-tooltip-id={tooltipIdPrefix + props.data.value}
                            >   
                                <ReactSelectComponents.MultiValueLabel {...props}/>
                                
                            </div>
                        )
                    }
                }}
                styles={{
                    group: (baseStyle, state) => {
                        return {
                            ...baseStyle,
                            borderLeft: "0.33em solid " + state.data.color,
                        }
                    },
                    groupHeading: (baseStyle) => {
                        return ({
                            ...baseStyle,
                            fontSize: "0.9rem",
                        });
                    },
                    option: (styles, { data}) => {
                        return {
                          ...styles,
                          color: data.color === "var(--mo-danger)"? "var(--mo-danger)" : "initial",
                        };
                      },
                    
                    multiValue: (baseStyle, state) => {
                        return {
                            ...baseStyle,
                            backgroundColor: state.data.color,
                            color: "white",
                        };
                    },
                    multiValueLabel: (baseStyle) => ({...baseStyle, color: "white"}),
                    multiValueRemove: (styles, { data }) => ({
                        ...styles,
                        color: data.color,
                        ':hover': {
                          backgroundColor: data.color,
                          color: 'white',
                        },
                      }),
                }}
                onDeleteOption={async(skillId)=> {
                    if(await confirm("This action cannot be undone, are you sure?")) {
                        deleteSkill(skillId);
                    }
                }}
            />
            {error && (
                <div
                    className="alert alert-danger"
                >{error?.message || error}</div>
            )}
        </div>
    );
}