import { createContext, useContext } from 'react';
import constants from '../Constants';

const ConstantContext = createContext();

const ConstantProvider = ({children}) => (
    <ConstantContext.Provider value={constants}>
        {children}
    </ConstantContext.Provider>
);

const useConstant = () => useContext(ConstantContext);

export default useConstant;
export { useConstant, ConstantProvider };