import CliftonStrengths from './CliftonStrengths.json';

const CliftonStrengthOptionsMap = {};
Object.values(CliftonStrengths).forEach((strengthType) => {
    strengthType.options.forEach(option => {
        CliftonStrengthOptionsMap[option.value] = { color: strengthType.color, ...option};
    });                     
});

const CliftonStrengthDictionary = {};
Object.entries(CliftonStrengths).forEach(([category, value]) => {
    const { color  } = value;

    CliftonStrengthDictionary[category] = {
        ...value,
        label: category,
        options: value.options.map(option => ({
            ...option,
            color
        }))
    }
});

function getColor(value) {
    return Object.values(CliftonStrengthDictionary).find(
        ({ options }) => 
            options.some(({ value: v }) => v === value)
    )?.color;
}
const GroupedOptions = Object.values(CliftonStrengthDictionary);
const tooltipPrefix = "cliftonstrength-tooltip-";

export { CliftonStrengths, CliftonStrengthOptionsMap, CliftonStrengthDictionary, tooltipPrefix, getColor  };
export default GroupedOptions;