import React from 'react';
import ArrayInput from './Generic/ArrayInput';
import { bindOnChange } from '../Functions/Helper/OnChange';
import { 
    FloppyFill as FloppyIcon,
    ChevronLeft,
    DashCircle as RemoveIcon,
    PlusCircle as AddIcon
} from './Icons';
import {components as ReactSelectComponents} from 'react-select';
import Form from './Generic/Form';
import Input from './Generic/Input';
import Droppable from './Generic/Droppable';
import Orderable from './Generic/Orderable';
import { AchievementDisplay } from './AchievementsInput';
import useABC from '../Provider/TableContext/ABCContext';
import { addClassNames } from '../Functions/Helper/Component';
import RichTextDisplay from './RichTextDisplay';

function RequirementAchievementDisplay({value, index, onChange}) {
    return (
        <>
            <AchievementDisplay value={value} />
            <button
                type="button"
                className="icon-button text-light"
                onClick={(e) => {
                    e.stopPropagation();
                    onChange(values => {
                        values = (values || []).slice();
                        values.splice(index, 1);
                        return values;
                    });
                }}
            >
                <RemoveIcon className="icon fs-5"/>
            </button>
        </>
    );
}
function DisplayComponent({value, onEdit, onChange }) {
    const onAchievementChange = React.useMemo(() => bindOnChange(onChange, "achievements"), [onChange]);
    const { abcs } = useABC();
    const achievementOptions = React.useMemo(() => {
        return abcs?.map(abc => ({ label: abc.mcb_achievementstatement, value: abc.mcb_abcid })) || [];
    }, [abcs]);
    return (
        <>
            <div onClick={() => { onEdit()}} className="fw-bolder">{value?.mcb_description}</div>
            <div className='d-flex flex-column justify-content-right flex-grow-1 gap-2'>
                <ul className="mb-0 no-marker d-flex flex-column gap-2 px-0 mb-2">
                    <Orderable          
                        onChange={onAchievementChange}
                        ItemComponent={RequirementAchievementDisplay}
                        values={value?.achievements || []}
                        keyFunction={(val) => val.mcb_abcid}
                        draggableClassName="card bg-tertiary text-light flex-row"
                    />
                </ul>
                {(!value?.achievements?.length  || value?.achievements?.length < 3) && (
                    <>
                    <Droppable className="border border-1 p-4" type="mcb-achievement" onDrop={({data}) => {
                        onChange((ov) => {
                            const achievements = [...(ov?.achievements || [])];
                            if (achievements.every(abc => abc.mcb_abcid !== data.mcb_abcid)) {
                                achievements.push(data);
                                return {
                                    ...ov,
                                    achievements
                                };
                            } else {
                                return ov;
                            }
                        });

                    }}>
                        Please Drop Achievements to Add Here
                    </Droppable>

                    <div className="d-block d-sm-block d-xs-block d-md-block d-lg-none d-xl-none d-xxl-none">
                        <Input
                            type="select"
                            onChange={(value) => {
                                const data = abcs.find(abc => abc.mcb_abcid === value);
                                if (data) {
                                    onChange((ov) => {
                                        const achievements = [...(ov?.achievements || [])];
                                        if (achievements.every(abc => abc.mcb_abcid !== data.mcb_abcid)) {
                                            achievements.push(data);
                                            return {
                                                ...ov,
                                                achievements
                                            };
                                        } else {
                                            return ov;
                                        }
                                    });
                                }
                            }}
                            value=""
                            classNames={{
                                control: (a) => addClassNames(a?.className, "p-1")
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            components={{
                                Option: function DeletableProp({children, ...props}) {
                                    return (
                                        <div 
                                            className={'d-flex react-select__option_container' + (props?.isFocused? ' focused' : '')}
                                        >
                                            <ReactSelectComponents.Option {...props}>
                                                <RichTextDisplay
                                                    value={children || ""}
                                                    readOnly={true}
                                                    className='fs-larger flex-grow-1 read-only'
                                                    theme="snow"
                                                />
                                            </ReactSelectComponents.Option>
                                        </div>
                                    )
                                }
                            }}
                            options={achievementOptions.filter(abc => (value?.achievements || []).every(achievement => achievement.mcb_abcid !== abc.value))}
                            placeholder="Add Achievement"
                            preAddOn={{
                                children: <AddIcon className="icon me-0"/>
                            }}
                        />
                    </div>
                    </>
                )}
            
            </div>
            
        </>
    );
}
function toTitle(description = "") {
    return (description || "").split(" ").slice(0, 3).join(" ").substring(0, 20);
}

export default function RequirementsInput({ onChange, values, invalid, ...props}) {
    const _onChange = React.useMemo(() => bindOnChange(onChange, "requirements"), [onChange]);
    const _values = React.useMemo(() => values?.requirements || [], [values]);
    const InputComponent = React.useCallback(({value, onSubmit, onChange, isNew, onCancel}) => {
        return (
            <Form onSubmit={onSubmit}>
                <Input
                    value={value?.mcb_description || ""}
                    required
                    className={invalid? "is-invalid" : ""}
                    placeholder="Type Requirements here..."
                    onChange={(mcb_description) => onChange((ov) => ({...ov, mcb_responsibility: toTitle(mcb_description), mcb_description}))}
                    addOn={{
                        children: (
                            <div className="btn-group" title={!value?.mcb_description? 'please type a responsibility' : 'add responsibility'}>
                                {!isNew && (
                                    <button 
                                        onClick={onCancel}
                                        className="btn btn-transparent pe-3"
                                        type="button"
                                    >
                                        <ChevronLeft className="me-2"/> Cancel
                                    </button>
                                )}
                                <button 
                                    onClick={onSubmit}
                                    disabled={!value?.mcb_description}
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                >
                                    <FloppyIcon className="me-2"/> {isNew? "Add" : "Save"}
                                </button>
                            </div>
                        )
                    }}
                />
            </Form>
        )
    }, [invalid]);

    return (
        <>
        <ArrayInput
            InputComponent={InputComponent}
            DisplayComponent={DisplayComponent}
            newValue=""
            noActionComponents
            displayWrapperClassName={"d-flex flex-row justify-content-between align-items-center flex-wrap gap-4"}
            onChange={_onChange}
            values={_values}
            noClickWrapper={true}
            keyFunction={(v) => v?.mcb_responsibilityid}
            {...props}
        />
        </>
    );
}