import React from 'react';
import FormArray from '../../Components/Generic/FormArray';
import useProfileJourneyContext from './ProfileJourneyContext';
import Form from '../../Components/Generic/Form';
import { TrashFill as RemoveIcon } from '../../Components/Icons';
import { SingleInputColumn, TwoInputColumns } from '../../Components/Generic/InputGrouping';
import QualificationLevel from '../../Constants/QualificationLevel.json';
import SidePanel from './SidePanel';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import { sigmoid } from '../../Functions/Helper/Functions';

function EducationalForm({ value, onChange, onRemove, formProps}) {    
    return (
        <div className="education form d-flex flex-column gap-2">
            <div className="h3 mb-3 d-flex justify-content-between align-items-center">
                <div>{value?.mcb_qualification || "Add Qualification"}</div>
                <div className="text-end flex-grow-1 fs-smaller">
                    {value?.mcb_institution}
                </div>
                <button
                    className='btn btn-transparent text-danger fs-smaller'
                    type="button"
                    onClick={onRemove}
                > 
                    <RemoveIcon/>
                </button>
                
            </div>
            <Form
                {...formProps}
                className="d-flex flex-column gap-4 mb-4">
                <SingleInputColumn 
                    state={value}
                    setState={onChange}
                    inputs={[
                        ['mcb_institution', 'text', "Institution", {required: true}]
                    ]}
                />
                <TwoInputColumns
                    state={value}
                    setState={onChange}
                    inputs={[ 
                        ['mcb_qualification', 'text', 'Degree', {required: true}],
                        ['mcb_abbreviation', 'text', 'Abbreviation', {required: true}],
                        // ['mcb_fieldofstudy', 'text', 'Field of study', {required: true}],
                        ['mcb_qualificationlevel', 'select', 'Qualification Level', {
                            required: true,
                            options: Object.entries(QualificationLevel)
                                .map(([label, value]) => ({label, value}))
                        }],
                        ['mcb_dateearned', 'month', 'Date Earned', {required: true}]
                    ]}
                />
            </Form>
        </div>
    );
}

export default function Educational({onNext, setCurrentStepProgress}) {
    const { formData, setFormData, updateQualification, qualificationLoading } = useProfileJourneyContext("qualifications", [{}]);
    const validityCheckRef = React.useRef(null);

    React.useEffect(() => {
        let result = 0;
        formData.forEach((education) => {
            let currentProgress = 0;
            if (education.mcb_institution) currentProgress += 1;
            if (education.mcb_qualification) currentProgress += 1;
            if (education.mcb_fieldofstudy) currentProgress += 1;
            if (education.mcb_qualificationlevel || education.currentJob) currentProgress += 1;
            if (education.mcb_dateearned) currentProgress += 1;
            result += currentProgress / 5;
        });

        result = sigmoid(result, 1);
        setCurrentStepProgress((currentProgress) => 
            currentProgress !== result ? result : currentProgress
        );
    }, [setCurrentStepProgress, formData]);

    onNext(async () => {
        if (typeof validityCheckRef.current === "function" && validityCheckRef.current()) {
            await updateQualification();
            return true;
        } else {
            return !validityCheckRef.current;
        }
    });

    if (qualificationLoading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            Loading Educational Data...
        </LoadingSpinner>
    );

    return (
        <div>
            <h3 className="h5 mb-3">Educational Details</h3>
            <FormArray
                value={formData.sort((a, b) => new Date(b.mcb_dateearned) - new Date(a.mcb_dateearned))}
                onChange={setFormData}
                loading={qualificationLoading}
                FormComponent={EducationalForm}
                onValidityCheck={(validityCheck) => {
                    validityCheckRef.current = validityCheck;
                }}

                addButtonLabel={`Add ${formData?.length? "More " : ""}Education`}
            />
        </div>
    );
}

Educational.stepName = "Educational";
Educational.stepWeight = 2;
Educational.SideComponent = SidePanel;