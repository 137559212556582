import processRPR from "./processRPR";

export default function processPPR(xml) {
    const context = {};
    const style = {};

    if (xml._children?.length) {
        xml._children.forEach(child => {
            const childData = processPPR(child);
            Object.assign(style, childData.style);
            Object.assign(context, childData.context);
        });
    }

    switch (xml._tag) {
        case 'w:pStyle': // Specifies the style to be applied to the paragraph.
            context.paragraphStyle = xml._attrs['w:val'];
            break;
        
        case 'w:keepNext': // Specifies that the paragraph should be kept with the next paragraph when the document is rendered.
            style.pageBreakAfter = 'avoid';
            break;

        case 'w:keepLines': // Specifies that all lines in the paragraph should be kept together when the document is rendered.
            if (xml._attrs['w:val'] === 'true')
                style.pageBreakInside = 'avoid';
            break;

        case 'w:pageBreakBefore': // Specifies that a page break should be inserted before the paragraph when the document is rendered.
            if (xml._attrs['w:val'] === 'true')
                style.pageBreakBefore = 'always';
            break;

        case 'w:widowControl': // Specifies how widows and orphans should be handled in the paragraph.
            if (xml._attrs['w:val'] === 'true') {
                style.orphans = 2;
                style.widows = 2;
            }
            break;
            
        case 'w:jc': // Specifies the alignment of the paragraph.
            if (xml._attrs['w:val'])
                switch (xml._attrs['w:val']) {
                    case 'center':
                        style.textAlign = 'center';
                        break;
                    case 'right':
                        style.textAlign = 'right';
                        break;
                    case 'both':
                        style.textAlign = 'justify';
                        break;
                    default:
                        style.textAlign = 'left';
                        break;
                }
            break;

        case 'w:ind': // Specifies the indentation of the paragraph.
            if (xml._attrs['w:left'])
                style.textIndent = `${xml._attrs['w:left'] / 20}pt`;

            if (xml._attrs['w:hanging'])
                style.textIndent = `-${xml._attrs['w:hanging'] / 20}pt`;

            if (xml._attrs['w:right'])
                style.paddingRight = `${xml._attrs['w:right'] / 20}pt`;
            break;

        case 'w:spacing': // Specifies the spacing before and after the paragraph, and the line spacing within the paragraph.
            style.lineHeight = `${xml._attrs['w:line'] / 20}pt`;
            if (xml._attrs['w:before']) {
                style.marginTop = `${xml._attrs['w:before'] / 20}pt`;
            }
            if (xml._attrs['w:after']) {
                style.marginBottom = `${xml._attrs['w:after'] / 20}pt`;
            }
            break;

        case 'w:tabs': // Specifies tab stops for the paragraph.            
            break;

        // case 'w:numPr': // Specifies the numbering level and format for the paragraph.
        //     context.inNumPr = true;
        //     break;

        // case ('w:ilvl'):
        //     if (xml._attrs['w:val'])
        //         context.listLevel = parseInt(xml._attrs['w:val'], 10);
        //     break;

        case ('w:numId'): // Specifies the numbering level and format for the paragraph.
            if (xml._attrs['w:val'])
                context.numId = xml._attrs['w:val'];
            break;

        case 'w:bidi': // Specifies that the paragraph contains right-to-left text.
            if (xml._attrs['w:val'] === '1') {
                style.direction = 'rtl';
            } else {
                style.direction = 'ltr';
            }
            break;
        
        case 'w:textAlignment': // Specifies the vertical alignment of the text in the line.
            style.verticalAlign = xml._attrs['w:val'];    
            break;
        
        case "w:align": // Specifies the alignment of a paragraph.
            if (xml._attrs['w:val']) {
              style.textAlign = xml._attrs['w:val'];
            }
            break;
        
        case "w:textDirection": // Specifies the text flow direction of the paragraph.
            switch (xml._attrs['w:val']) {
                case 'lr':
                    style.direction = 'ltr';
                    style.unicodeBidi = 'bidi-override';
                    break;
                case 'rl':
                    style.direction = 'rtl';
                    style.unicodeBidi = 'bidi-override';
                    break;
                case 'tb':
                    // CSS does not support top-to-bottom text direction
                    break;
                default:
                    break;
            }
            break;

        case 'w:border': // Specifies the border of the paragraph.
            if (xml._attrs['w:val'])
                style.border = `${xml._attrs['w:sz']}px solid ${xml._attrs['w:color']}`;
            break;

        case 'w:shd': // Specifies the shading of the paragraph.
            style.backgroundColor = `#${xml._attrs['w:fill']}`;
            break;

        case 'w:framePr': // Specifies the text frame properties of the paragraph.
            if (xml._attrs) {
                style.position = 'absolute';
                if (xml._attrs['w:w'])
                    style.width = `${xml._attrs['w:w']}pt`;

                if (xml._attrs['w:h'])
                    style.height = `${xml._attrs['w:h']}pt`;

                if (xml._attrs['w:x'])
                    style.left = `${xml._attrs['w:x']}pt`;

                if (xml._attrs['w:y'])
                    style.top = `${xml._attrs['w:y']}pt`;

            }
            break;

        case 'w:sectPr': // Specifies the section properties of the paragraph.
            break;

        case 'w:pBdr': // Specifies the border of the paragraph.
            if (xml._attrs) {
                if (xml._attrs['w:top'])
                    style.borderTop = `${xml._attrs['w:top']['w:sz']}px solid ${xml._attrs['w:top']['w:color']}`;

                if (xml._attrs['w:bottom'])
                    style.borderBottom = `${xml._attrs['w:bottom']['w:sz']}px solid ${xml._attrs['w:bottom']['w:color']}`;

                if (xml._attrs['w:left'])
                    style.borderLeft = `${xml._attrs['w:left']['w:sz']}px solid ${xml._attrs['w:left']['w:color']}`;

                if (xml._attrs['w:right'])
                    style.borderRight = `${xml._attrs['w:right']['w:sz']}px solid ${xml._attrs['w:right']['w:color']}`;

            }
            break;

        case 'w:rPr': // Specifies the run properties for the paragraph.
            const rpr = processRPR(xml);
            context.rprStyle = Object.assign(context.rprStyle || {}, rpr.style);
            Object.assign(context, rpr.context);
            break;

        default:
            break;
    }

    return { style, context };
}