export function sigmoid(x, approach = 1) {
  return approach * ( 1 - 1 / (1 + x * 10));
}

export function TruncateChartData(data, limit = 10) {
  const otherDatas = data.splice(limit);
  if (otherDatas.length > 0 ) {
      data.push({
          label: "Others",
          isEmpty: true,
          value: otherDatas.reduce((acc, cur) => acc + cur.value, 0)
      });
  }

  return data;
}