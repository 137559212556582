import React from 'react';
import useProfileJourneyContext from './ProfileJourneyContext';
import { CallbackContextProvider } from '../../Components/Generic/CallbacksContext';
import FormArray from '../../Components/Generic/FormArray';
import SidePanel from './SidePanel';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import ProfessionalForm from '../../Components/ProfessionForm';
import { useCallbackSubmission } from '../../Hooks/useFormCallback';
import { sigmoid } from '../../Functions/Helper/Functions';

function ProfessionalFormContainer({onNext, setCurrentStepProgress}) {
    const { formData, setFormData, updateProfessional, professionalLoading } = useProfileJourneyContext("professional", [{}]);
    const validityCheckRef = React.useRef(null);
    const onFormSubmit = useCallbackSubmission(updateProfessional);
    
    onNext(async () => {
        if (validityCheckRef.current && validityCheckRef.current()) {
            return await onFormSubmit();
        } else {
            return !validityCheckRef.current;
        }
    });

    React.useEffect(() => {
        let result = 0;
        formData?.forEach((profession) => {
            let currentProgress = 0;
            if (profession.mcb_roletitle) currentProgress += 1;
            if (profession.mcb_rolecompanyname) currentProgress += 1;
            if (profession.mcb_starteddate) currentProgress += 1;
            if (profession.mcb_endeddate || profession.currentJob) currentProgress += 1;
            if (profession.mcb_rolehtml) currentProgress += 1;
            if (profession.mcb_challenge) currentProgress += 1;
            currentProgress += sigmoid(profession?.responsibilities?.length || 0, 2);
            currentProgress += sigmoid(profession?.achievements?.length || 0, 4);
            result += currentProgress / 12;
        });

        result = sigmoid(result, 1);
        setCurrentStepProgress((currentProgress) => 
            currentProgress !== result ? result : currentProgress
        );
    }, [setCurrentStepProgress, formData]);
    const uniqueCompanyNames = React.useMemo(() => {
        const companyNames = new Set();
        formData.forEach((role) => {
            if (role.mcb_rolecompanyname) {
                companyNames.add(role.mcb_rolecompanyname);
            }
        });
        return companyNames;
    }, [formData]);

    if (professionalLoading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            Loading Professional Data...
        </LoadingSpinner>
    );

    return (
        <div key="form-input">
            <datalist id="unique-company-names">
                {Array.from(uniqueCompanyNames).map((companyName, key) => (
                    <option key={companyName} value={companyName}/>
                ))}
            </datalist>
            <h3 className="h5 mb-3">Professional</h3>
            <FormArray
                value={formData}
                onChange={setFormData}
                loading={professionalLoading}
                FormComponent={ProfessionalForm}
                onValidityCheck={(validityCheck) => {
                    validityCheckRef.current = validityCheck;
                }}
                rolesData={formData}
                addButtonLabel="Add Another Role"
            />
        </div>
    );
}

export default function Professional(props) {
    return (
        <CallbackContextProvider>
            <ProfessionalFormContainer {...props}/>
        </CallbackContextProvider>
    );
}
Professional.stepName = "Professional";
Professional.stepWeight = 4;

Professional.SideComponent = SidePanel;