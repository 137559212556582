import React from 'react';
import MultiSelectInput from './MultiSelectInput';
import CheckInput from './CheckInput';
import FormControlInput from './FormControlInput';
import DateInput from './DateInput';
import RichTextInput from './RichTextInput';
import './Input.scss';


function FormInput({id, name, type, ...rest}) {
    const [inputId] = React.useState(() =>  
        id || name || Math.random().toString(36).substring(2) + Date.now().toString(36)
    );

    if (type === "checkbox" || type === "radio") {
        return CheckInput({...rest, id: inputId, name: (name || inputId), type});
    } else {
        return FormControlInput({...rest, id, name: (name || inputId), type});
    }
    
}

/**
 * Renders an input component based on the provided props.
 * @param {Object} props - The props for the input component.
 * @param {string} props.type - The type of the input component. Possible values are "select" or any other valid input type.
 * @param {string} props.id - The id of the input element.
 * @param {string} props.name - The name of the input element.
 * @param {Function} props.onChange - The callback function to be called when the value of the input changes.
 * @param {string} props.label - The label text for the input element.
 * @param {ReactNode} props.children - The child elements of the input component.
 * @param {Array} props.options - The options for the select input component.
 * @param {string} props.value - The value of the input element.
 * @param {string} props.className - The CSS class name for the input element.
 * @returns {ReactNode} The rendered input component.
 */
export default function Input(props) {
    const onChange = props?.onChange || function(){};
    let label = props.label;
    if (props.required && props.label) {
        label = <>{label} <span className='required-tag'>*</span></>
    }
    if (props.type === "select" || props.type === "multiselect") {
        return MultiSelectInput({...props, onChange, label});
    } else if (props.type === "date" || props.type === "month") {
        return DateInput({...props, onChange, label});
    } else if (props.type === "richtext") {
        return <RichTextInput {...{...props, onChange }} />;
    } else {
        return FormInput({...props, onChange, label});
    }
}