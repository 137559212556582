import CreateTableContext from './TableContext';

const fields = [
    "mcb_roleid",
    "mcb_roletitle",
    "mcb_applicationstatus",
    "mcb_atriskrenumeration",
    "mcb_baserenumeration",
    "mcb_challenge",
    "mcb_companyname_",
    "mcb_cliftonstrength",
    "mcb_datesubmitted",
    "mcb_description",
    "mcb_starteddate",
    "mcb_endeddate",
    "mcb_generalcomments",
    "mcb_interestedcompany",
    "mcb_notinterestedcompany",
    "mcb_interestedsectors",
    "mcb_notinterestedsectors",
    "mcb_onboardingplan",
    "mcb_positiondescriptiondoc",
    "mcb_renumerationtype",
    "mcb_reportingto",
    "mcb_rolecompanyname",
    "mcb_rolehtml",
    "mcb_rolelevel",
    "mcb_rolestatus",
    "mcb_roletype",
    "mcb_sector",
    "mcb_rac", // this value is computed under MyRACCompute and MyIdealRoleCompute
    "mcb_sectornamesummary",
];

const relations = {
    "mcb_CoverLetter": "mcb_coverletter",
    "mcb_CV": "mcb_cv",
    "mcb_Person": "contact"
}

const expand = {
    "mcb_KeySkills": "mcb_skill",
    "mcb_CVRoles": "mcb_cv",
    "mcb_TertiaryQualifications": "mcb_qualification",
};

const readOnly = [
    "modifiedon",
    "createdon"
];

const [useRole, RoleProvider] = CreateTableContext(
    "mcb_role", {
        contextName: "Role",
        preSubmission: (entity, profileData) => ({
            ...entity,
            _mcb_person_value: profileData?.id
        }), fields, readOnly, expand, relations
    }
);

export default useRole;
export { useRole, RoleProvider };
