import Api from "./Api";

import AuthPages from "../Layouts/AuthPages";
import _SignIn from "./SignIn";
import _ForgotPassword from "./ForgotPassword";
import _Invitation from "./Invitation";
import Profile from "./Profile";
import IdealRole from "./IdealRole";
import ApplyingRoleSetup from "./ApplyingRole";
import CVSetup from "./CVSetup";
import _Home from "./Home";
import _Achievements from "./Achievements";
import _JobApplications from "./JobApplications";
import _AccountSettings from "./AccountSettings";
import _CVs from './CVs';
import AccountFrameLayout from "./AccountFrameLayout";
import PageLayout from "../Layouts/PageLayout";

const SignIn = AuthPages(_SignIn);
const ForgotPassword = AuthPages(_ForgotPassword);
const Invitation = AuthPages(_Invitation);
const Home = PageLayout(_Home);
const Achievements = PageLayout(_Achievements);
const JobApplications = PageLayout(_JobApplications);
const AccountSettings = PageLayout(_AccountSettings);
const CVs = PageLayout(_CVs);

export {
    Api, AccountFrameLayout, SignIn, ForgotPassword, Invitation,
    Home, Achievements, Profile, AccountSettings, IdealRole,
    ApplyingRoleSetup, JobApplications, CVs, CVSetup
};