import React from 'react';
import { 
    HandIndexThumbFill as HandIcon,
    ChevronDoubleLeft as ChevronLeft,
    ChevronDoubleRight as ChevronRight
} from './Icons';
import './TouchIndicator.scss';
import { addClassNames } from '../../Functions/Helper/Component';

export default function TouchIndicator({className, flip, ...props}) {
    return (
        <div className={addClassNames("touch-indicator", className)} {...props}>
            <div className="motion">
                {!flip && <ChevronLeft className="left-indicator ico"/>}
                <div className="touch-point"/>
                {flip && <ChevronRight className="right-indicator ico"/>}
            </div>
            <HandIcon className="hand-icon ico" />
        </div>
    );
}