import processPPR from "./processPPR";
import processRPR from "./processRPR";

function processStyleTag(xml) {
    const styleData = {};

    xml._children.forEach(child => {
        Object.assign(styleData, processStyleTag(child));
    });

    switch (xml._tag) {
        case 'w:style':
            return { [xml._attrs['w:styleId']]: styleData };
        
        case 'w:basedOn':
            return { basedOn: xml._attrs['w:val'] };
        
        case 'w:next':
            return { next: xml._attrs['w:val'] };
        
        case 'w:link':
            return { link: xml._attrs['w:val'] };

        case 'w:autoRedefine':
            return { autoRedefine: xml._attrs['w:val'] };

        case 'w:rsid':
            styleData.rsid = xml._attrs['w:val'];
            break;
        
        case 'w:pPr':
            const ppr = processPPR(xml);
            return { ...ppr.context, pprStyle: { ...ppr.style }};

        case 'w:rPr':
            const rpr = processRPR(xml);
            return { ...rpr.context, rprStyle: { ...rpr.style }};
        case ('w:numId'):
            if (xml._attrs['w:val']) {
                styleData.numId = xml._attrs['w:val'];
            }
            break;

        default:
            return styleData;
    }

    return styleData;
}

export default function processStyle(styleXML) {
    const styleData = {...processStyleTag(styleXML)};
    
    Object.entries(styleData).forEach(function processStyle([key, val]) {

        if (!styleData[key].styleProcessed) {
            if (val?.pprStyle?.rprStyle) {
                styleData[key].pprStyle = Object.assign({},  val.pprStyle ||{}, val?.pprStyle?.rprStyle);
            }

            if (
                val?.paragraphStyle
                && val.paragraphStyle !== key
                && !styleData[val?.paragraphStyle].styleProcessed
            ) {
                const paragraphStyle = styleData[val?.paragraphStyle];
                processStyle([val?.paragraphStyle, paragraphStyle]);  
                styleData[key].pprStyle = Object.assign({}, paragraphStyle?.pprStyle || {}, val?.pprStyle || {});
            }

            if (
                val?.runningStyle
                && val.runningStyle !== key
                && !styleData[val?.runningStyle].styleProcessed
            ) {
                const runningStyle = styleData[val?.runningStyle];
                processStyle([val?.runningStyle, runningStyle]);  
                styleData[key].rprStyle = Object.assign({}, runningStyle?.rprStyle || {}, val?.rprStyle || {});
            }

            styleData[key].style = Object.assign({}, val?.pprStyle || {}, val?.rprStyle || {});
            styleData[key].styleProcessed = true;
        }
        
    });

    Object.entries(styleData).forEach(function processParentStyle([key, val]) {
        if (val.basedOn && !val.processed) {
            const parentStyle = styleData[val.basedOn];
            if (parentStyle.basedOn) {
                processParentStyle([val.basedOn, parentStyle]);
            }
            styleData[key].style = Object.assign({}, parentStyle?.style || {}, val?.style || {});
            styleData[key].processed = true;
        }
    });

    Object.entries(styleData).forEach(([key, val]) => {
        if (val.next) {
            const nextStyle = styleData[val.next];
            styleData[key].nextStyle = nextStyle.style;
        }

        if (val.link) {
            const linkStyle = styleData[val.link];
            styleData[key].linkStyle = linkStyle.style;
        }
    });
    
    return styleData;
}