import React from 'react';
import { addClassNames } from '../../Functions/Helper/Component';
import { GripHorizontal as GripIcon } from './Icons';
import Draggable from './Draggable';
import Droppable from './Droppable';
import './Orderable.scss';


function DroppableAction({index, typeSymbol, onChange, className}) {
    return (
        <Droppable
            type={typeSymbol}
            className={addClassNames(
                "w-100 b-2 border-primary active-height",
                (className || "")
            )}
            onDrop={({data: drop}) => {
                if (typeof onChange === "function") onChange((values) => {
                    values = values || [];
                    if (
                        isNaN(drop?.index)
                        || (drop?.index === index) 
                        || drop.index < 0
                    ) return values;
                    
                    const newValues = [...(values || [])];
                    const [value] = newValues.splice(drop.index, 1);

                    newValues.splice(
                        index > drop.index? index - 1
                        : index, 0, value
                    );
                    return newValues;
                });
            }}
        />
    );
}


function DraggableDisplay({
    ItemComponent, typeSymbol, containerRef, draggableClassName, value, index, locked, lock, unlock, onChange, dragging, className, ...props
}) {
    const draggableItem = React.useRef(null);
    const touchHandleRef = React.useRef(null);
    const item = (
        <div
            className="item-view d-flex justify-content-between gap-2 align-items-center flex-grow-1"
        >
            <ItemComponent
                index={index}
                value={value}
                onChange={onChange}
                className={addClassNames(className, `array-item item-${index}`)}
                lock={lock}
                unlock={unlock}
                {...props}
            />
        </div>
    );
    if (locked) {
        return item;
    } else {
        return (
            <React.Fragment>
                <div ref={draggableItem}>
                <DroppableAction 
                    index={index}
                    typeSymbol={typeSymbol}
                    onChange={onChange}
                    className={addClassNames(`array-reorder-space mb-3 droppable-item-${index}`)}
                />

                <Draggable 
                    type={typeSymbol}
                    data={{ index, value }}
                    onDragStop={() => {
                        // any rapid change to up when dropping start results in drop being executed wrongly
                        setTimeout(() => {
                            if (draggableItem.current) draggableItem.current.classList.remove("d-none");
                            if (containerRef.current) containerRef.current.classList.remove("dragging");
                        }, 15);
                        
                    }}
                    onDragStart={() => { 
                        // any rapid change to up when drag start results in the drag not starting
                        setTimeout(() => {
                            if (draggableItem.current) draggableItem.current.classList.add("d-none");
                            if (containerRef.current) containerRef.current.classList.add("dragging");
                        }, 15);
                    }}
                    touchHandle={touchHandleRef}
                    className={addClassNames("display-view card text-bg-light px-3 py-2 d-flex justify-content-start align-items-center flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap flex-xl-nowrap flex-xxl-nowrap  gap-2 position-relative", draggableClassName)}
                >
                    <GripIcon ref={touchHandleRef} className="fs-4 icon"/>
                    {item}
                </Draggable>
                </div>
            </React.Fragment>
        );
    }
}

export default function Orderable({ 
    ItemComponent,
    values,
    onChange,
    keyFunction,
    draggableClassName,
    draggableContainerClassName,
    className,
    ...props
}) {
    const typeSymbol = React.useMemo(() => Symbol("orderable-item"), []);
    const [locked, setLocked] = React.useState({});
    const containerRef = React.useRef(null);

    return (
        <div
            ref={containerRef}
            className={addClassNames("droppable-dragging-visible orderable-container gap-3 d-flex flex-column", draggableContainerClassName)}
        >
            {(values || []).map((value, index) => {
                const key = value?.key || (
                    (typeof keyFunction === "function" && keyFunction(value, index) ) ||  `item-${index}`
                );

                return (
                    <DraggableDisplay
                        key={key}
                        value={value}
                        arr={values}
                        index={index}
                        locked={locked[index]}
                        lock={() => setLocked(locked => ({...locked, [index]: true}))}
                        unlock={() => setLocked(locked => ({...locked, [index]: false}))}
                        onChange={onChange}
                        ItemComponent={ItemComponent}
                        typeSymbol={typeSymbol}
                        containerRef={containerRef}
                        draggableClassName={draggableClassName}
                        {...props}
                    />
                )
            })}
            <DroppableAction 
                index={values.length}
                typeSymbol={typeSymbol}
                onChange={onChange}
                key={"droppable-final-item"}
                className={`array-reorder-space droppable-item-${values.length}`}
            />
        </div>
    );
}