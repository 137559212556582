import React from 'react';
import {
    useNavigate,
    useLocation
} from 'react-router';
import { deferCall } from '../Functions/Helper/Async';

function usePortalFrameLocationSynchronizer(iframeRef, portalURL, loadedIframeLocation) {
    const navigate = useNavigate();
    const location = useLocation();
    // synchronize iframe location and app location
    const sync = React.useMemo(() => {
        if (!iframeRef || !loadedIframeLocation) return false;
        
        if (/^\/forgot-password/gi.test(location.pathname)) {
            if (!/^\/account\/login\/forgotpassword/gi.test(loadedIframeLocation.pathname)) {
                iframeRef.setSrc(portalURL + "account/login/forgotpassword");
                return false;
            }
        } else if (/^\/invitation/gi.test(location.pathname)) {
            const urlParam = new URLSearchParams(location.search);
            const step = urlParam.get('step');
            if (/^\/account\/login\/register/gi.test(loadedIframeLocation.pathname)) {
                if (step !== 'register') {
                    urlParam.set('step', 'register');
                    deferCall(() =>navigate(location.pathname + location.hash + '?' + urlParam.toString()));
                    return false;
                }
            } else if (/^\/(?:profile|web-api)/gi.test(loadedIframeLocation.pathname)) {
                deferCall(() => navigate("/profile?returnUrl=forced"));
                iframeRef.setSrc(portalURL + 'web-api');
                return false;
            } else if (!/^\/register/gi.test(loadedIframeLocation.pathname)) {
                if (step === 'register') {
                    urlParam.delete('step', 'register');
                    deferCall(() => navigate(location.pathname + location.hash + '?' + urlParam.toString()));
                }
                iframeRef.setSrc(portalURL + "register");
                return false;
            } 
        } else if (/^\/sign-out/gi.test(location.pathname)) {
            if(/^\/signin/gi.test(loadedIframeLocation.pathname)) {
                // redirect to app to /sign-in if iframe is in /signin
                deferCall(() => navigate('/sign-in' + location.search));
                return false;
            } else if (!/^\/account\/login\/logoff/gi.test(loadedIframeLocation.pathname)) {
                // redirect iframe to sign-out
                iframeRef.setSrc(portalURL + "account/login/logoff?ReturnUrl=%SignIn%2F");
                return false;
            }
        } else if (/^\/sign-in/gi.test(location.pathname)) {
            if (/^\/web-api/gi.test(loadedIframeLocation.pathname)) {
                const returnUrl = new URLSearchParams(location.search.toLowerCase())
                    .get('returnurl');
                if (returnUrl) {
                    deferCall(() => navigate(decodeURIComponent(returnUrl)));
                    return false;
                } else {
                    deferCall(() => navigate('/'));
                    return false;
                }
            } else if (/^\/profile/gi.test(loadedIframeLocation.pathname)) {
                iframeRef.setSrc(portalURL + "web-api");
                return false;
            } else if (!/^\/signin/gi.test(loadedIframeLocation.pathname)) {
                iframeRef.setSrc(portalURL + "signin?ReturnUrl=%2Fweb-api");
                return false;
            }
        } else if (/^\/signin/gi.test(loadedIframeLocation.pathname)) {
            if (!/^\/sign-in/gi.test(location.pathname)) {
                deferCall(() => navigate('/sign-in?returnUrl=' + encodeURIComponent(location.pathname + location.hash)));
                iframeRef.setSrc(portalURL + "signin?ReturnUrl=%2Fweb-api");
                return false;
            }
        } else if (!/^\/web-api/gi.test(loadedIframeLocation.pathname)) {
            iframeRef.setSrc(portalURL + "web-api");
            return false;
        }

        return true;
    }, [iframeRef, loadedIframeLocation, location, navigate, portalURL]);
    return sync;
}

export default function usePortalFrame(portalURL) {
    const [iframeRef, setIframeRef] = React.useState(null);
    const [loadedIframeLocation, setLoadedIframeLocation] = React.useState(null);
    const [validationMessage, setValidationMessage] = React.useState(null);
    const [apiReady, setAPIReady] = React.useState(false);
    const [profileData, setProfileData] = React.useState(null);
    // Effect to mount the iframe
    React.useEffect(() => {
        
        // message handler for fundamental events from the iframe
        const handleMessage = (event) => {
            if (event.origin !== portalURL.slice(0, -1)) return;
            try {
                const response = event.data
                switch (response.action) {
                    case "USER_INFO":
                        setProfileData(response.user);
                        break;
                    case "ERROR_MESSAGE":
                        setValidationMessage(response.message);
                        break;
                    case "LOADED_PATH":
                        const loadedIframePath = {...(response.location || {})};
                        console.log(loadedIframePath)
                        setLoadedIframeLocation(loadedIframePath);
                        break;
                    case "IFRAME_UNLOADING":
                        setValidationMessage("");
                        setAPIReady(false);
                        break;
                    case "API_READY":
                        setAPIReady(true);
                        break;
                    default:
                        break;
                }
            } catch (err) {
                // console.log(event);
                // console.error(err);
            }
        }

        window.addEventListener('message', handleMessage);

        // create the iframe
        const iframe = document.createElement('iframe');
        const apiUrl = portalURL + "web-api";
        iframe.id = 'portal-api-iframe';
        iframe.src = apiUrl;
        iframe.style.opacity = '0';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.padding= '0';
        iframe.style.margin = '0';
        iframe.setSrc = (src) => {
            setAPIReady(false);
            if (src) iframe.src = src;
        }

        document.body.appendChild(iframe);
        setIframeRef(iframe);

        // Cleanup on component unmount
        return () => {
            document.body.removeChild(iframe);
            window.removeEventListener('message', handleMessage);
        };
    }, [portalURL]);


    const sync = usePortalFrameLocationSynchronizer(iframeRef, portalURL, loadedIframeLocation);

    // iframe must have api otherwise its of no use
    return [iframeRef, !(sync && apiReady), profileData, validationMessage, loadedIframeLocation];
}