import React from 'react';
import {v4 as uuidv4} from 'uuid';
import { addClassNames } from '../../Functions/Helper/Component';
import { CallbackContextProvider } from './CallbacksContext';
import { X as CloseIcon } from "./Icons";
import useModalPortal from '../../Hooks/useModalPortal';
import { useCallbackSubmission } from '../../Hooks/useFormCallback';
import LoadingSpinner from './LoadingSpinner';

function ModalDisplay({ 
    modal, titleId, title, actionName,
    BodyComponent, bodyProps = {},
    TitleComponent, titleProps: {
        className: titleClassName,
        ...titleProps
    } = {},

    classNames: {
        modalHeaderClassName,
        modalTitleClassName,
        modalBodyClassName,
        modalFooterClassName,
        closeActionClassName,
        submitActionClassName
    }, onSubmit: bodyOnSubmit, ...props}) {
    
    const callbackHookedOnSubmit = useCallbackSubmission(bodyOnSubmit);
    
    const onSubmit =  React.useCallback(async() => {
        modal.hide();
        if (
            typeof callbackHookedOnSubmit === "function"
            && await callbackHookedOnSubmit() !== false
        ) {
            return true;
        } else {
            return false;
        }
    }, [callbackHookedOnSubmit, modal]);
    

    return (
        <>
            <div className={addClassNames("modal-header", modalHeaderClassName)}>
                {TitleComponent? (
                    <TitleComponent 
                        id={titleId}
                        className={addClassNames("modal-title", titleClassName, modalTitleClassName)}
                        {...titleProps}
                    >{title}</TitleComponent>
                ) : (
                    <h5
                        id={titleId}
                        className={addClassNames("modal-title", titleClassName, modalTitleClassName)}
                        {...titleProps}
                    >{title}</h5>
                )}
                <button
                    type="button"
                    className="btn-close icon-button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                ><CloseIcon width="1em" height="1em"/></button>
            </div>
            
            <div className={addClassNames("modal-body", modalBodyClassName)}>
                <BodyComponent onSubmit={onSubmit} {...props}/>
            </div>
            
            <div className={addClassNames("modal-footer",modalFooterClassName)}>
                <button
                    type="button"
                    className={addClassNames("btn", closeActionClassName )}
                    data-bs-dismiss="modal"
                >Close</button>
                <button
                    type="button"
                    className={addClassNames("btn", submitActionClassName)}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onSubmit();
                    }}
                >{actionName || "Submit"}</button>
            </div>
        </>
    );
} 

function ModalDefinition({
    title, actionName, triggerName, onClose,
    TriggerButtonComponent, triggerButtonProps: {
        className: triggerButtonClassName,
        ...triggerButtonProps
    } = {},
    classNames: {
        modalClassName, modalDialogClassName,
        modalContentClassName, modalTriggerClassName,
        ...classNames
    } = {},
    loading, ...props
}) {
    const titleId = React.useMemo(() => `${uuidv4()}-label`, []);
    const [shown, setShown] = React.useState(false);

    const { uuid, modal, ModalPortal } = useModalPortal();

    React.useEffect(() => {
        const modal = document.getElementById(uuid);
        if (modal) {
            const _onClose = (e) => {
                setShown(false);
                if (typeof onClose === 'function') onClose(e);
            };
            modal.addEventListener('hidden.bs.modal', _onClose);
            return () => {
                modal.removeEventListener('hidden.bs.modal', _onClose);
            };
        }
    }, [uuid, onClose]);

    return (
        <>
        <ModalPortal 
            modalType={addClassNames("modal-lg", modalClassName)}
            aria-labelledby={titleId}
            modalDialogClassName={modalDialogClassName}
            modalContentClassName={modalContentClassName}
        >
            {shown && (loading? <LoadingSpinner/> : <ModalDisplay
                titleId={titleId} title={title}
                actionName={actionName} classNames={classNames}
                modal={modal} 
                {...props}
            />)}
        </ModalPortal>
        {TriggerButtonComponent? (
            <TriggerButtonComponent
                className={addClassNames("btn", triggerButtonClassName, modalTriggerClassName)}
                onClick={() => {
                    modal.show();
                    setShown(true);
                }}
                {...triggerButtonProps}
            />
        ) :  (
            <button
                type="button"
                className={addClassNames("btn", triggerButtonClassName, modalTriggerClassName)}
                onClick={() => {
                    modal.show();
                    setShown(true);
                }}
                {...triggerButtonProps}
            >{triggerName || actionName || addClassNames("Open", title)}</button>
        
        )}
        </>
        
    );
}

export default function FormModal(props) {
    return (
        <CallbackContextProvider>
            <ModalDefinition {...props}/>
        </CallbackContextProvider>
    );
}