import React from 'react';
import DatePicker from 'react-date-picker';
import { addClassNames } from '../../../Functions/Helper/Component';

export default function DateInput({
    label,
    children,
    inputClassName,
    className,
    disabled,
    disabledValue,
    type = "date",
    inputRef,
    onChange, ...rest
}) {
    return (
        <div className={addClassNames("input ", (className || ""))}>
            <label htmlFor={rest?.id}>{children || label}</label>
            
            <div className="input-group">
            {disabled?(
                <input ref={inputRef} className="form-control" type="text" disabled={disabled} value={disabledValue} />
                ) : (
                    <DatePicker
                        ref={inputRef}
                        className={inputClassName}
                        onChange={(value) => {
                            if (typeof value?.getDate === "function") {
                                const year = value.getFullYear();
                                const month = (value.getMonth() + 1).toString().padStart(2, '0');
                                const date = value.getDate().toString().padStart(2, '0');
                                onChange(`${year}-${month}-${date}T00:00:00Z`);
                            } else {
                                onChange(value);
                            }
                        }}
                        format={type === "month"? 'MMMM y' : 'dd MMMM y'}
                        maxDetail={type === "month" ? 'year' : 'month' }
                        {...rest}
                    />
                    )
                }
                </div>
        </div>
    );
}