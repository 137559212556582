import CreateTableContext from './TableContext';

const fields = [
    "mcb_responsibilityid",
    "mcb_responsibility",
    "mcb_achievementssummary",
    "mcb_description",
    "mcb_responsibilitylevel",
    "mcb_rolestatus",
    "mcb_strengthssummary",
    "mcb_index",
    "mcb_achievementssummary"
];

const relations = {
    "mcb_Person": "contact",
    "mcb_Role": "mcb_role",
};

const expand = {
    "mcb_ABCResponsibility": "mcb_abc"
};

const readOnly = [
    "modifiedon"
];

const orderby = "mcb_index asc";

const [useResponsibility, ResponsibilityProvider] = CreateTableContext(
    "mcb_responsibility", {
        contextName: "Responsibility",
        preSubmission: ({
            _mcb_person_value, ...entity
        }, profileData) => ({
            ...entity,
            _mcb_person_value: profileData?.id
        }), fields, relations, expand, orderby, readOnly
    }
);

export default useResponsibility;
export { useResponsibility, ResponsibilityProvider };
