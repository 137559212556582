import React from 'react';
import { addClassNames } from '../Functions/Helper/Component';
import { ChevronDown as ChevronDownIcon } from './Icons';

export default function TextDropDownOptions({ label, options, className }) {
    const [dropdown, setDropdown] = React.useState(false);
    const container = React.useRef(null);
    React.useEffect(() => {
        const handleClick = (e) => {
            setDropdown(container.current && (container.current === e.target || container.current.contains(e.target)));
        }
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, []);

    return (
        <div ref={container} className={addClassNames("dropdown-container", !className?.includes("left") && "right", className)}>
            <button
                className="bg-transparent border-0 text-link-color"
                onClick={() => setDropdown(!dropdown)}
                type="button"
            >{label}<ChevronDownIcon className="icon me-0 text-secondary"/></button>
            <ul className={addClassNames("dropdown-menu px-2 py-3",(dropdown?"show" : ""))}>
                {options?.length && options.map((option, idx) => (
                    <li key={idx}>
                        {option === "divider"? (
                            <hr className="dropdown-divider"/>
                        ): option?.onClick? (
                            <button
                                className={addClassNames("dropdown-item", option.className)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    option.onClick(e);
                                    setDropdown(false);
                                }}
                            >{option.label}</button>
                        ) : option}
                    </li>
                ))}
            </ul>
        </div>
    )
}