import AjaxActionHandler from "./AjaxActionHandler";
import LoginActionHandler from "./LoginActionHandler";
import ForgotPasswordActionHandler from "./ForgotPasswordActionHandler";
import InviteUserActionHandler from "./InviteUserActionHandler";
import RegisterUserActionHandler from "./RegisterActionHandler";

const MessageHandlers = {
    "API_REQUEST": AjaxActionHandler,
    "LOG_IN": LoginActionHandler,
    "FORGOT_PASSWORD": ForgotPasswordActionHandler,
    "INVITATION": InviteUserActionHandler,
    "REGISTER": RegisterUserActionHandler
};

export default MessageHandlers;