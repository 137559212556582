import React from "react";
import useABC from "../../Provider/TableContext/ABCContext";
import useResponsibility from "../../Provider/TableContext/ResponsibilityContext";

export default function useRequirementsAchievementCompute() {
    const { responsibilities, updateResponsibility, loading: responsibilityLoading } = useResponsibility();
    const { abcs, loading: abcLoading } = useABC();
    const loading = React.useMemo(() => abcLoading || responsibilityLoading, [abcLoading, responsibilityLoading]);
    React.useEffect(() => {
        if (loading) return;
        responsibilities.forEach(responsibility => {
            const achievements = "<div>" + responsibility.mcb_ABCResponsibility?.map(({mcb_abcid}) =>
                abcs.find(a => a.mcb_abcid === mcb_abcid)
            )?.reduce((acc, abc) => acc + abc.mcb_achievementstatement, "") + "</div>";
            if (responsibility.mcb_achievementssummary !== achievements) {
                updateResponsibility({
                    ...responsibility,
                    mcb_achievementssummary: achievements
                }, false);
            }
            
        });
    }, [loading, responsibilities, abcs, updateResponsibility]);
}