import React from 'react';
import { addClassNames } from '../../../Functions/Helper/Component';
import { stripHtml } from '../../../Functions/Helper/RichText';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextInput.scss';

function getHtmlCharCount(html) {
    return stripHtml(html || "")?.trim().length || 0;
}

export default function RichTextInput({ 
    label, children, 
    maxCharacterCount,inputClassName,
    className, disabled,
    value, disabledValue,
    onChange, labelClassName, ...rest
}) {
    const [focused, setFocused] = React.useState(false);
    const reactQuillRef = React.useRef(null);
    const charCount = getHtmlCharCount(value);

    const checkCharacterCount = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getLength() > maxCharacterCount && event.key !== 'Backspace')
        event.preventDefault();
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        const currentLength = unprivilegedEditor.getLength();
        if (unprivilegedEditor.getLength() + pastedText.length > maxCharacterCount) {
            const trimmedText = pastedText.substring(0, maxCharacterCount - currentLength);
            unprivilegedEditor.insertText(unprivilegedEditor.getLength(), trimmedText);
        } else {
            unprivilegedEditor.insertText(unprivilegedEditor.getLength(), pastedText);
        }
    };

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const qlToolbars = document.getElementsByClassName("ql-toolbar");
            for(var toolbarC = 0; toolbarC < qlToolbars.length; toolbarC++) {
                const toolbar = qlToolbars[toolbarC];
            
                toolbar.setAttribute("tabindex", "-1");
                const buttons = toolbar.getElementsByTagName("button");
                for (var c = 0; c < buttons.length; c++) {
                    const button = buttons[c];
                    button.setAttribute("tabindex", "-1");
                }
    
                const select = toolbar.getElementsByClassName("ql-picker-label")[0];
                if (select) {
                    select.setAttribute("tabindex", "-1");
                }
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={addClassNames("input", (className || ""))}>
            <label className={labelClassName} htmlFor={rest?.id}>{children || label}</label>
            <div className={addClassNames("input-group", (focused? " focus" : ""))}>
                <ReactQuill
                    ref={reactQuillRef}
                    disabled={disabled}
                    onChange={(v) => {
                        if (v === "<p><br></p>") v = "";
                        onChange(v);
                    }}
                    onFocus={()=>setFocused(true)}
                    onBlur={()=>setFocused(false)}
                    onKeyDown={maxCharacterCount? checkCharacterCount : undefined}
                    onPaste={maxCharacterCount? handlePaste : undefined}
                    className={addClassNames("rich-text-input bg-white", inputClassName)}
                    value={(disabled? disabledValue : value) || ""}
                />
                {maxCharacterCount && (
                    <div
                        className="character-count rounded-pill"
                        style={{
                            backgroundColor: `var(--mo-${charCount >= maxCharacterCount ? "danger" : "primary"})`
                        }}
                    >
                        {charCount}
                    </div>
                )}
            </div>
        </div>
    );
}