import React from 'react';
import Banner from './Generic/BrandedBanner';
import { 
    RocketTakeoffFill as ApplicationsIcon,
    PlusCircle as AddIcon
} from './Generic/Icons';
import ApplicationStatus from '../Constants/ApplicationStatus.json';
import RoleStatus from '../Constants/RoleStatus.json';
import useRole from '../Provider/TableContext/RoleContext';
import Graph from './Generic/Graph';
import Card from './Generic/Card';
import "./JobApplicationBanner.scss";
import { addClassNames } from '../Functions/Helper/Component';
import ApplicationStatusColors from '../Constants/ApplicationStatusColors.json';
import LinkToJourney from './Generic/LinkToJourney';
import LoadingSpinner from './Generic/LoadingSpinner';

export default function BrandedJobApplicationBanner({className, ...props}) {
    const { roles, loading } = useRole();
    const [applicationCount, acceptanceRate, data, colors] = React.useMemo(() => {
        const statusCounts = {};
        let applicationCount = 0, submittedRoleCount = 0, offeredRoles = 0;
        roles?.forEach(role => {
            if (role.mcb_rolestatus === RoleStatus.Applying) {
                applicationCount++;
                if (role.mcb_applicationstatus >= ApplicationStatus["Submitting Application"]) {
                    submittedRoleCount++;
                    if (role.mcb_applicationstatus >= ApplicationStatus["Offered"]) {
                        offeredRoles++;
                    }
                }
                const [label] = Object.entries(ApplicationStatus)
                    .find(([, value]) => role.mcb_applicationstatus === value) || ["Gathering Information"];
                
                statusCounts[label] = (statusCounts[label] || 0) + 1;
            }
        });
        const data = Object.entries(statusCounts).map(([label, value]) => ({label, value}));
        const colors = data.map(({label}) => ApplicationStatusColors[label]);
        return [applicationCount, submittedRoleCount? Math.round((offeredRoles / submittedRoleCount) * 100) : 0, data, colors];
    }, [roles]);
    if (loading) return <LoadingSpinner/>;
    
    return (
        <Banner
            className={addClassNames(className, "job-acceptance-banner")}
            Icon={ApplicationsIcon}
            heading={applicationCount? `You have ${acceptanceRate}% acceptance rate` : ""}
            cta={applicationCount?  
                <Card><Graph className="job-acceptance-graph" data={data} colors={colors} /></Card> 
                : <LinkToJourney to="/applying-job-setup" className="btn btn-link-color fs-5"><AddIcon className="icon"/> Start Application</LinkToJourney>
            }
            {...props}
        >
            {applicationCount? (
                <div className="course-promo">
                <div className="flex-grow-1">
                    <p>Boost your job application success with a personalized Job Search Strategy.</p>
                    <p>Our tailored insights and techniques are designed to align with industry trends, 
                        offering you a competitive advantage in finding the right opportunities.</p>

                </div>
                <a 
                    href="https://mycareerbrand.net/products/job-search-strategy" 
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-light mw-max-content">
                    View Course
                </a>
                </div>
            ): <span className="h3 mb-0 text-white">Get started by adding an application</span>}
        </Banner>
    )
}