import React from 'react';
import Card from './Generic/Card';
import RichTextDisplay from './RichTextDisplay';
import {components as ReactSelectComponents} from 'react-select';
import { 
    CheckCircle as CheckCircleIcon,
    XCircle as CrossCircleIcon,
    Trophy as AchievementIcon,
    PlusCircle as AddIcon,
    CloudDownload as DownloadIcon
 } from './Icons';

import Input from './Generic/Input';
import GraphComponent from './Generic/Graph';
import EditIconLink from './Generic/EditIconLink';
import LoadingSpinner from './Generic/LoadingSpinner';
import RemoveAdditionalOption from './Generic/RemoveAdditionalOption';
import { addClassNames } from '../Functions/Helper/Component';
import { getSectorNames } from '../Functions/Helper/Sector';
import useRoleData from '../Hooks/useRoleData';
import useConstant from '../Provider/ConstantContext';
import HTMLDocxLink from './HTMLDocxLink';

import useCV from '../Provider/TableContext/CVContext';
import CheckCliftonStrength from './CheckCliftonStrength';
import MyIdealRole from './MyIdealRole';

const DescriptionControl = () => <EditIconLink to="ideal-job"/>;

export default function IdealRoleEditPanel({ role, showMyIdealRole, ...props}) {
    const {
        loading,
        skillData,
        cliftonStrengthData,
        cliftonStrengthColors,
        roleRequirements,
        removeRelationFromResponsibility,
        addRelationToResponsibility,
        achievementOptions
    } = useRoleData(role);
    
    const { portalURL, Descriptions } = useConstant();
    const { cvs } = useCV();
    const cv = React.useMemo(() => {
        if (cvs && cvs.length) return cvs.find(cv => cv.mcb_cvid === role._mcb_cv_value) || {};
        return {};
    }, [cvs, role]);

    if (loading) return <LoadingSpinner style={{minHeight: "33rem"}}/>;
    
    return (
        <>
        <Card
            title='Your Ideal Job'
            description={Descriptions['ideal-job']}
            DescriptionControl={DescriptionControl}
            {...props}
        >
            <div className="row px-3">
                <div className="col-12 col-lg-6 col-xl-6 py-3 px-1">
                    <div>
                        <h3 className="h4">{role?.mcb_roletitle}</h3>
                        <p className="fs-5 mb-1"><b>Company</b>: <span className="text-success">{role?.mcb_interestedcompany}</span></p>
                        <p className="fs-5 mb-1"><b>Sector</b>: <span className='text-success'>{getSectorNames(role.mcb_interestedsectors)}</span></p>
                        <p className="fs-5 mb-1"><b> Reporting to</b>: {role?.mcb_reportingto}</p>
                        <p className="fs-large mb-1"><b>Base Remuneration</b>: ${Number(role?.mcb_baserenumeration).toLocaleString()} {
                            role?.mcb_renumerationtype === 861400001? "per year" : "per hour"}</p>
                        <p className="fs-large mb-1"><b>At Risk Remuneration</b>: ${Number(role?.mcb_atriskrenumeration).toLocaleString()}</p>
                        <h5 className="mt-3">General Comments</h5>
                        <RichTextDisplay
                            value={role?.mcb_generalcomments || ""}
                            readOnly={true}
                            className="read-only fs-larger"
                            theme="snow"
                        />
                        <h5 className="mt-3">Not Interested</h5>
                        <p className="fs-5 mb-1"><b>Company</b>: <span className="text-danger">{role.mcb_notinterestedcompany}</span></p>
                        <p className="fs-5 mb-1"><b>Sector</b>: <span className="text-danger">{getSectorNames(role.mcb_notinterestedsectors)}</span></p>
                    </div>
                    <div>
                        <h3 className='h4'>Your Skills for the Ideal Job</h3>
                        <div className="px-4 py-2">
                            {skillData?.length? <GraphComponent data={skillData} />: "No required skills are present in the relevant achievements."}
                        </div>
                    </div>
                    <div>
                        <h3 className='h4'>Your CliftonStrengths for the Ideal Job</h3>
                        <CheckCliftonStrength>
                            <div className="px-4 py-2">
                                {cliftonStrengthData?.length?  <GraphComponent colors={cliftonStrengthColors} data={cliftonStrengthData} />
                                    : "No required CliftonStrengths are present in the relevant achievements."}
                            </div>
                        </CheckCliftonStrength>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 d-flex flex-column gap-3">
                    {!!roleRequirements?.length && <>
                        <h3 className='h4'>Requirements for Your Ideal Job</h3>
                        <div className="d-flex flex-column position-relative gap-4 flex-grow-1">
                            <div className='position-lg-absolute w-100 h-100 overflow-y-auto'>
                            {roleRequirements.map((req) => {
                                return (
                                    <div key={req.mcb_responsibilityid}>
                                        <h5 className="h5">{req?.achievements?.length? <CheckCircleIcon className="text-success me-3 icon"/> 
                                            : <CrossCircleIcon className="text-danger me-3 icon"/>}{req.mcb_responsibility}</h5>
                                        <ul className='d-flex flex-column gap-3'>
                                            {req?.achievements?.map((achievement) => {
                                                return (
                                                    <li key={achievement.mcb_abcid} className='d-flex flex-row flex-nowrap'>
                                                        <div className="fs-x-large">
                                                            <AchievementIcon className="icon"/>
                                                        </div>
                                                        <RichTextDisplay
                                                            value={achievement?.mcb_achievementstatement || ""}
                                                            readOnly={true}
                                                            className='fs-larger flex-grow-1 read-only'
                                                            theme="snow"
                                                        />
                                                        <div>
                                                            <RemoveAdditionalOption onRemove={() => {
                                                                removeRelationFromResponsibility(
                                                                    req.mcb_responsibilityid, 
                                                                    "mcb_ABCResponsibility",
                                                                    achievement.mcb_abcid
                                                                );
                                                            }}/>
                                                        </div>
                                                    </li>
                                                );
                                            })}

                                            {req?.achievements?.length < 3 && (
                                                <Input
                                                    type="select"
                                                    onChange={(value) => {
                                                        if (value) {
                                                            addRelationToResponsibility(
                                                                req.mcb_responsibilityid, 
                                                                "mcb_ABCResponsibility",
                                                                value
                                                            );
                                                        }
                                                    }}
                                                    value=""
                                                    classNames={{
                                                        control: (a) => addClassNames(a?.className, "p-1")
                                                    }}
                                                    components={{
                                                        Option: function DeletableProp({children, ...props}) {
                                                            return (
                                                                <div 
                                                                    className={'d-flex react-select__option_container' + (props?.isFocused? ' focused' : '')}
                                                                >
                                                                    <ReactSelectComponents.Option {...props}>
                                                                        <RichTextDisplay
                                                                            value={children || ""}
                                                                            readOnly={true}
                                                                            className='fs-larger flex-grow-1 read-only'
                                                                            theme="snow"
                                                                        />
                                                                    </ReactSelectComponents.Option>
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                    options={achievementOptions.filter(abc => req?.achievements.every(achievement => achievement.mcb_abcid !== abc.value))}
                                                    placeholder="Add Achievement"
                                                    preAddOn={{
                                                        children: <AddIcon className="icon me-0"/>
                                                    }}
                                                />
                                            )}
                                        </ul>
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    </>}

                    {role?._mcb_cv_value && (
                        <div className='d-flex flex-row justify-content-end gap-3'>
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href={`${portalURL}File/download.aspx?Entity=mcb_cv&Attribute=mcb_cvdocument&Id=${role?._mcb_cv_value}`}
                                className='btn btn-primary w-max-content unstyled align-self-end'
                            ><DownloadIcon className="icon"/> Download Generic CV</a>
                            <HTMLDocxLink HTML={cv.mcb_coverletter} name={`CoverLetter_${role.mcb_roletitle}.docx`}>
                                Download Cover Letter
                            </HTMLDocxLink>
                        </div>
                    )}
                </div>
            </div>
        </Card>
        {showMyIdealRole && <MyIdealRole role={role} className="mt-3"/>}
        </>
    );
}