import React from 'react';
import { PlusCircleFill as Plus } from '../Icons';

function ArrayItemComponent({item, index, onChange, FormComponent, ...props}) {
    const _onChange = React.useCallback((value) => onChange(data => { 
        const newArray = [...data];
        newArray[index] = typeof value === "function"? value(data[index]): value;
        return newArray;
    }), [onChange, index]);
    const _onRemove = React.useCallback(() => onChange((array) => {
        const newArray = [...array];
        newArray.splice(index, 1);
        return newArray;
    }), [onChange, index]);
    return (
        <FormComponent 
            value={item}
            onChange={_onChange}
            onRemove={_onRemove}
            onSubmit={_onChange}
            {...props}
        />
    );
}

export default function FormArray({
    value, onChange, onValidityCheck,
    FormComponent, AddButtonComponent,
    addButtonLabel, ...props
}) {
    const addData = React.useCallback((data = {}) => {
        onChange([...(value || []), data]);
    }, [value, onChange]);

    const ValidityChecks = React.useRef([]);

    React.useEffect(() => {
        onValidityCheck(() => {
            if (Array.isArray(ValidityChecks.current)) {
                return ValidityChecks.current.reduce((acc, check) => {
                    return acc && check();
                }, true);
            }
        });
    }, [onValidityCheck]);

    const onValidityCheckAdd = React.useCallback((validityCheck) => {
        ValidityChecks.current.push(validityCheck);
    }, []);

    const onValidityCheckRemove = React.useCallback((validityCheck) => {
        ValidityChecks.current = ValidityChecks.current.filter((c) => c !== validityCheck);
    }, []);

    const formProps = React.useMemo(() => ({
        onValidityCheckAdd,
        onValidityCheckRemove,
    }), [onValidityCheckAdd, onValidityCheckRemove]);

    AddButtonComponent = AddButtonComponent || (({onClick}) => (
        <button type="button" className="btn btn-outline-link-color" onClick={onClick}><Plus className="me-2"/> {addButtonLabel || "Add"}</button>
    ));

    const array = value || [];
    return (
        <div className='d-flex flex-column gap-4'>
            
            {array.map((item, index) => {
                return (
                    <ArrayItemComponent 
                        formProps={formProps}
                        key={`item-${index}`}
                        item={item}
                        index={index}
                        onChange={onChange}
                        FormComponent={FormComponent}
                        {...props}
                    />
                );
            })}
            <div key="add-Button" className="mt-2">
                <AddButtonComponent onClick={() => addData({})} />
            </div>
        </div>
    );
    
}