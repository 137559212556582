import React from 'react';
import useABC from '../TableContext/ABCContext';
import useSkill from '../TableContext/SkillContext';
import useResponsibility from '../TableContext/ResponsibilityContext';
import useQualification from '../TableContext/QualificationContext';
import useRole from '../TableContext/RoleContext';
import useProfile from '../TableContext/ProfileContext';
import { generateIdealRoleHTML } from '../../Functions/Generator/IdealRoleGenerator';
import RoleStatus from '../../Constants/RoleStatus.json';

export default function useMyIdealRoleCompute() {
    const { profileData, updateProfile, loading: profileLoading } = useProfile();
    const { roles, updateRole, loading: roleLoading } = useRole();
    const { abcs, loading: abcLoading } = useABC();
    const { responsibilities, loading: respLoading } = useResponsibility();
    const { qualifications, loading: qualLoading } = useQualification();
    const { skills, loading: skillsLoading } = useSkill();
    
    const loading = React.useMemo(() =>
        abcLoading || respLoading || skillsLoading || qualLoading,
    [abcLoading, respLoading, skillsLoading, qualLoading]);
    const role = React.useMemo(() => roles?.find((role) => role.mcb_rolestatus === RoleStatus.Ideal), [roles])

    const value = React.useMemo(() => {
        if (loading || !role) {
            return;
        }
        return generateIdealRoleHTML(role, responsibilities, qualifications, abcs, skills);
    }, [role, abcs, responsibilities, qualifications, skills, loading]);
    
    React.useEffect(() => {
        if (loading || !value) return;
        if (!profileLoading && profileData._mcb_idealrole_value !== role.mcb_roleid) {
            updateProfile({ ...profileData, _mcb_idealrole_value: role.mcb_roleid }, false);
        }

        if (!roleLoading && role.mcb_rac !== value) {
            updateRole({ ...role, mcb_rac: value }, false);
        }
    }, [loading, profileLoading, roleLoading, value, profileData, updateProfile, role, updateRole]);

}