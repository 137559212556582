import React from 'react';
import useIdealRoleJourneyContext from './IdealRoleContext';
import Requirement, { getBindedSidePanel } from '../../Components/RequirementStep';

export default function IdealRoleRequirement(props) {
    const journeyContext = useIdealRoleJourneyContext();
    return <Requirement {...props} {...journeyContext}/>;
}

IdealRoleRequirement.SideComponent = getBindedSidePanel(useIdealRoleJourneyContext);

IdealRoleRequirement.stepName = "Requirements";