import { addClassNames } from '../../Functions/Helper/Component';
import processPPR from './processPPR';

function processNumberingRecursively({NumberingXML, ...param}) {
    const numbering = {};
    const abstractNumbering = {};

    if (param.abstractNumId && !abstractNumbering[param.abstractNumId]) 
        abstractNumbering[param.abstractNumId] = {};
    if (param.lvl && !abstractNumbering[param.abstractNumId][param.lvl])
        abstractNumbering[param.abstractNumId][param.lvl] = { style: {} };

    switch(NumberingXML._tag) {

        case 'w:num':
            param.numId = NumberingXML._attrs['w:numId'];
            break;
        
        case 'w:abstractNumId':
            const abstractNumId = NumberingXML._attrs['w:val'];
            if (param.numId && abstractNumId) {
                numbering[param.numId] = abstractNumId;
            }
            break;
        
        case 'w:abstractNum':
            param.abstractNumId = NumberingXML._attrs['w:abstractNumId'];
            break;
        
        case 'w:lvl':
            param.lvl = NumberingXML._attrs['w:ilvl'];
            break;
        
        case 'w:start':
            if (param.lvl && param.abstractNumId) {
                abstractNumbering[param.abstractNumId][param.lvl].start = NumberingXML._attrs['w:val'];
            }
            break;

        case 'w:pPr':
            const initStyle = abstractNumbering[param.abstractNumId][param.lvl].style || {};
            const {style} = processPPR(NumberingXML)
            abstractNumbering[param.abstractNumId][param.lvl].style = {...initStyle, ...style};
            break;
        
        case 'w:numFmt':
            if (param.lvl && param.abstractNumId) {
                let listStyleType;
                switch(NumberingXML._attrs['w:val']) { 
                    case 'decimal':
                    case 'cardinalText':
                    case 'ordinal':
                    case 'ordinalText':
                        listStyleType = 'decimal';
                        break;
                    case 'upperRoman':
                    case 'upperLetter':
                        listStyleType = 'upper-roman';
                        break;
                    case 'lowerRoman':
                    case 'lowerLetter':
                        listStyleType = 'lower-roman';
                        break;
                    default:

                        listStyleType = 'disc';
                }
                abstractNumbering[param.abstractNumId][param.lvl].style.listStyleType = listStyleType;   
            }
            break;
        
        case 'w:lvlJc':
            if (param.lvl && param.abstractNumId)
                abstractNumbering[param.abstractNumId][param.lvl].style.textAlign = NumberingXML._attrs['w:val'];
            break;
        
        case 'w:lvlText': 
            if (param.lvl && param.abstractNumId)
                abstractNumbering[param.abstractNumId][param.lvl].dataset =
                    Object.assign(
                        abstractNumbering[param.abstractNumId][param.lvl].dataset || {},
                        { numFormat: NumberingXML._attrs['w:val'] }
                    );
            break;
        
        case 'w:isLgl':
            abstractNumbering[param.abstractNumId][param.lvl].className = 'lgl';
            break;
        
        default:
            break;
    }

    if (Array.isArray(NumberingXML._children) && NumberingXML._children.length > 0) {
        NumberingXML._children.forEach(child => {
            const parsedNumber = processNumberingRecursively({NumberingXML: child, ...param});
            Object.assign(numbering, parsedNumber.numbering);
            Object.entries(parsedNumber.abstractNumbering || {}).forEach(([abstractNumId, abstractNumber]) => {
                Object.entries(abstractNumber || {}).forEach(([lvl, lvlData]) => {
                    if (!abstractNumbering[abstractNumId]) abstractNumbering[abstractNumId] = {};
                    if (!abstractNumbering[abstractNumId][lvl]) abstractNumbering[abstractNumId][lvl] = {};
                    const abstractNumberData = abstractNumbering[abstractNumId][lvl] || {};
                    const { style, className, ...props} = lvlData;
                    Object.assign(abstractNumberData, props);
                    abstractNumberData.style = Object.assign(abstractNumberData.style || {}, style);
                    abstractNumberData.className = addClassNames(abstractNumberData.className, className);
                    abstractNumbering[abstractNumId][lvl] = abstractNumberData;

                });
            });
        });
    }
    return {numbering, abstractNumbering};
}

export default function processNumbering(NumberingXML) {
    const { numbering, abstractNumbering } = processNumberingRecursively({NumberingXML});
    Object.entries(numbering).forEach(([numId, abstractNumId]) => {
        const props = abstractNumbering[abstractNumId]["0"] || abstractNumbering[abstractNumId]["1"] || Object.values(abstractNumbering[abstractNumId])[0];
        const type = ((props.style.listStyleType || "disc") === "disc")? "ul" : "ol";
        numbering[numId] = { type, props };
    });
    return numbering;
}