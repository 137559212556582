import React from 'react';
import Card from './Generic/Card';
import useABC from '../Provider/TableContext/ABCContext';
import useRole from '../Provider/TableContext/RoleContext';
import LoadingSpinner from './Generic/LoadingSpinner';
import GraphComponent from './Generic/Graph';
import RoleStatus from '../Constants/RoleStatus.json';
import useProfile from '../Provider/TableContext/ProfileContext';
import CliftonStrength from '../Constants/CliftonStrengths.json';
import { getDataColors } from '../Functions/Helper/Color';
import CliftonStrengthAssociationModal from './CliftonStrengthModal';
import useConstant from '../Provider/ConstantContext';
import CheckCliftonStrength from './CheckCliftonStrength';

export default function CliftonGraphCard(props) {
    const {abcs, loading: abcLoading} = useABC();
    const {roles, loading: roleLoading} = useRole();
    const {profileData, loading: profileLoading} = useProfile();

    const [data, colors] = React.useMemo(() => {
        if (!profileData || !profileData?.mcb_has_cliftonstrength) return [];
        const experienceRoleIds = [];
        roles?.forEach(role => {
            if ([RoleStatus.Current, RoleStatus.Previous].includes(role.mcb_rolestatus)) {
                experienceRoleIds.push(role.mcb_roleid);
            }
        });
        const strengthCount = {};
        
        abcs?.forEach(achievement => {
            if (experienceRoleIds.includes(achievement?._mcb_role_value)) {
                let strengths = (achievement?.mcb_cliftonstrengths || "").split(",");
                strengths.forEach(strength => {
                    if (Object.keys(strengthCount).includes(strength)) {
                        strengthCount[strength] += 1;
                    } else {
                        strengthCount[strength] = 1;
                    }
                });
            
            }   
        })
        const data = Object.entries(strengthCount).map(([id, value]) => {
            let option;
            if(Object.values(CliftonStrength).some(({options, color}) => {
                const foundOption = options.find(option => `${option.value}` === id);
                if (foundOption) {
                    option = {...foundOption, color};
                    return true;
                } else return false;
            })) {
                return ({
                    label: option.label,
                    value,
                    color: option.color
                });
            } else return false;
        }).filter(a => a !== false && a.value !== 0).sort((a, b) => b.value - a.value);
        const otherDatas = data.splice(10);
        const colors = getDataColors(data);
        if (otherDatas.length > 0 ) {
            data.push({
                label: "Others",
                isEmpty: true,
                value: otherDatas.reduce((acc, cur) => acc + cur.value, 0)
            });
        }
        return [data, colors];

    }, [abcs, profileData, roles]);

    const { Descriptions } = useConstant();
    return (
        <Card
            title='Your CliftonStrength® Distribution'
            description={Descriptions['strength']}
            {...(profileData?.mcb_has_cliftonstrength? {footer: (<CliftonStrengthAssociationModal/>)} :{})} {...props}
        >
            {(abcLoading || profileLoading || roleLoading)? <LoadingSpinner/> 
                : (
                    <CheckCliftonStrength>
                        {data?.length? (
                            <div className="px-4"><GraphComponent colors={colors} data={data} /></div>
                        ) : (
                            <div className="px-4 py-3 fs-5">You haven't got any strengths yet.</div>
                        )}
                    </CheckCliftonStrength>
                )
            }
        </Card>
    );
}