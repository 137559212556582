import React from 'react';
import LoadingSpinner from '../Generic/LoadingSpinner';
import processTemplate from 'docx-templates';
import useSkill from '../../Provider/TableContext/SkillContext';
import useRole from '../../Provider/TableContext/RoleContext';
import RoleStatus from '../../Constants/RoleStatus.json';
import useQualification from '../../Provider/TableContext/QualificationContext';
import useResponsibility from '../../Provider/TableContext/ResponsibilityContext';
import useABC from '../../Provider/TableContext/ABCContext';
import { monthDate } from '../../Functions/Helper/Month';
import { CloudDownload as DownloadIcon } from '../Icons';
import { processRichTextXML } from '../../Functions/Helper/Docx';
import XMLView from '../XMLView';
import HTMLDocxLink from '../HTMLDocxLink';

export function CVDisplay({ formData: _formData, cvBytes, setCVBytes, template, loading }) {
    const { responsibilities, loading: responsibilitiesLoading } = useResponsibility();
    const { abcs, loading: abcsLoading } = useABC();
    const { skills, loading: skillsLoading } = useSkill();
    const { roles, loading: rolesLoading } = useRole();
    const { qualifications, loading: qualificationsLoading } = useQualification();
    const [formData, setFormData] = React.useState(_formData);


    // adding a bit delay to ensure that we are not rendering for every minor changes.
    React.useEffect(() => {
        if (_formData !== formData) {
            if (!formData) {
                setFormData(_formData);
            } else {
                let mounted = true;
                setTimeout(() => {
                    if (mounted) setFormData(_formData);
                }, 678);
                return () => { mounted = false; };
            }
        }
    }, [_formData, formData]);

    const cvData = React.useMemo(() => (formData?.cv || {}), [formData?.cv]);
    
    const dataLoading = React.useMemo(() => !formData
        || skillsLoading
        || rolesLoading
        || qualificationsLoading
        || responsibilitiesLoading
        || abcsLoading,
        [formData, skillsLoading, rolesLoading, qualificationsLoading, responsibilitiesLoading, abcsLoading]
    );

    const [cvKeySkills, cvSkills] = React.useMemo(() => {
        const keySkills = dataLoading ? [] : (cvData.skills || []).map(skill => (
            skills.find(({ mcb_skillid }) => skill === mcb_skillid)?.mcb_skill || ""
        ));
        const cvSkills = keySkills.join(", ");

        return [keySkills, cvSkills];
    }, [skills, cvData?.skills, dataLoading]);

    const cvKeyAchievements = React.useMemo(() => {
        const cvKeyAchievements = [];
        (formData?.cv?.keySkillsAchievements || []).forEach(({ mcb_fullstatement, mcb_index }) => {
            cvKeyAchievements.push({ achievementStatement: mcb_fullstatement, mcb_index   });
        });
        return cvKeyAchievements.sort((a, b) => a.mcb_index - b.mcb_index);
    }, [formData?.cv?.keySkillsAchievements]);

    const [cvExperiences, cvGroupedExperiences, cvPreviousRoles] = React.useMemo(() => {
        const cvExperiences = [];
        const cvGroupedExperiences = [];
        const cvPreviousRoles = [];
        if (!dataLoading) {
            const _abcs = [...abcs];
            const _responsibilities = [...responsibilities];
            let removedRoles = [];
            roles.forEach(role => {
                if (cvData.cvRoles.includes(role.mcb_roleid) && ((role.mcb_rolestatus === RoleStatus.Current) || (role.mcb_rolestatus === RoleStatus.Previous))) {
                    const achievements = [];
                    const responsibilities = [];
                    const roleSkills = [];
                    for (let c = 0; c < _abcs.length; c++) {
                        const abc = _abcs[c];
                        if (abc._mcb_role_value === role.mcb_roleid) {
                            if ( cvData.cvRoles.includes(abc?._mcb_role_value) && cvData.achievements.includes(abc?.mcb_abcid)) {
                                abc.mcb_ABCSkill.forEach(({ mcb_skillid }) => {
                                    const skill = skills.find((skill) => skill.mcb_skillid === mcb_skillid);
                                    if (skill) {
                                        const roleSkillsIndex = roleSkills.findIndex(roleSkill => roleSkill.id === skill.mcb_skillid);
                                        if (roleSkillsIndex === -1) {
                                            roleSkills.push({ id: skill.mcb_skillid, skill: skill.mcb_skill, count: 1 });
                                        } else {
                                            roleSkills[roleSkillsIndex].count++;
                                        }
                                    }
                                });
                            
                                achievements.push({ achievementStatement: abc.mcb_achievementstatement, mcb_index: abc.mcb_index });
                            }
                            _abcs.splice(c, 1);
                            c--;
                        } else if (removedRoles.length && removedRoles.includes(abc._mcb_role_value)) {
                            _abcs.splice(c, 1);
                            c--;
                        }
                    }

                    for (let c = 0; c < _responsibilities.length; c++) {
                        const responsibility = _responsibilities[c];
                        if (responsibility._mcb_role_value === role.mcb_roleid) {
                            responsibilities.push({
                                name: responsibility.mcb_responsibility || "",
                                description: responsibility.mcb_description || ""
                            });
                            _responsibilities.splice(c, 1);
                            c--;
                        } else if (removedRoles.length && removedRoles.includes(responsibility._mcb_role_value)) {
                            _responsibilities.splice(c, 1);
                            c--;
                        }
                    }

                    const roleData = {
                        achievements: achievements.sort((a, b) => (a.mcb_index || 0) - (b.mcb_index || 0)),
                        responsibilities,
                        roleTitle: role.mcb_roletitle,
                        keySkills: roleSkills.sort((a, b) => b.count - a.count).map(({ skill }) => skill).join(", "),
                        companyName: role.mcb_rolecompanyname,
                        companyBlurb: role.mcb_rolehtml,
                        challenge: role.mcb_challenge,
                        startedDate: monthDate(role.mcb_starteddate),
                        endedDate: role.mcb_rolestatus === RoleStatus.Current ? "Present" : monthDate(role.mcb_endeddate),
                    };
                    roleData.date = `${roleData.startedDate} - ${roleData.endedDate}`;

                    roleData.startedSort = new Date(role.mcb_starteddate).getTime();
                    roleData.endedSort = role.mcb_rolestatus === RoleStatus.Current ? new Date().getTime() : new Date(role.mcb_endeddate).getTime();
                    let append = true;

                    for (let c = 0; c < cvExperiences.length; c++) {
                        const experience = cvExperiences[c];
                        if (role.startedSort > experience.startedSort
                            || (role.startedSort === experience.startedSort && role.endedSort > experience.endedSort)) {
                            cvExperiences.splice(c, 0, roleData);
                            append = false;
                            break;
                        }
                    }
                    if (append) cvExperiences.push(roleData);
                } else {
                    removedRoles.push(role.mcb_roleid);
                }
            });
        }
        cvExperiences.forEach((experience) => {
            if (!cvPreviousRoles.includes(experience.roleTitle)) cvPreviousRoles.push(experience.roleTitle);

            const lastGroup = cvGroupedExperiences[cvGroupedExperiences.length - 1];
            if (lastGroup && lastGroup.companyName && lastGroup.companyName === experience.companyName) {
                if (!lastGroup.companyDescription) lastGroup.companyDescription = experience.companyBlurb;
                if (!lastGroup.challenge) lastGroup.challenge = experience.challenge;

                if (!lastGroup.startedSort || lastGroup.startedSort > experience.startedSort) {
                    lastGroup.startedSort = experience.startedSort;
                    lastGroup.startedDate = experience.startedDate;
                }
                if (!lastGroup.endedSort || lastGroup.endedSort < experience.endedSort) {
                    lastGroup.endedSort = experience.endedSort;
                    lastGroup.endedDate = experience.endedDate;
                }
                lastGroup.companyDate = `${lastGroup.startedDate} - ${lastGroup.endedDate}`;

                if (!lastGroup.roles) lastGroup.roles = [experience];
                else lastGroup.roles.push(experience);

                if (!lastGroup.responsibilities) lastGroup.responsibilities = [...experience.responsibilities];
                else lastGroup.responsibilities.push(...experience.responsibilities);
                
                if (!lastGroup.achievements) lastGroup.achievements = [...experience.achievements];
                else lastGroup.achievements.push(...experience.achievements);
            } else {
                cvGroupedExperiences.push({
                    companyName: experience.companyName,
                    companyDescription: experience.companyBlurb,
                    challenge: experience.challenge,
                    startedSort: experience.startedSort,
                    startedDate: experience.startedDate,
                    endedSort: experience.endedSort,
                    endedDate: experience.endedDate,
                    companyDate: `${experience.startedDate} - ${experience.endedDate}`,
                    roles: [experience],
                    responsibilities: [...experience.responsibilities],
                    achievements: [...experience.achievements]
                });
            }
        });
        return [cvExperiences, cvGroupedExperiences, cvPreviousRoles];
    }, [cvData.cvRoles, cvData.achievements, roles, abcs, responsibilities, skills, dataLoading]);

    const cvQualifications = React.useMemo(() => (cvData.qualifications || []).map((qId) => {
        if (dataLoading) return [];
        const qualification = (qualifications || []).find(({ mcb_qualificationid }) => mcb_qualificationid === qId);
        if (qualification) return {
            name: qualification.mcb_qualification,
            institure: qualification.mcb_institution,
            level: qualification.mcb_qualificationlevel,
            field: qualification.mcb_fieldofstudy,
            dateEarned: new Date(qualification.mcb_dateearned).getFullYear().toString()
        }; else return false;
    }).filter(a => a).sort((a, b) => a.date?.localeCompare(b.date) || -1), [cvData.qualifications, dataLoading, qualifications]);

    const xmlViewProps = React.useRef({ XML: {}, links: {} });

    React.useEffect(() => {
        if (!dataLoading && template) {
            const ctx = { numberingDetails: [], links: [] };
            processTemplate({
                template: new Uint8Array(template),
                data: {
                    firstName: cvData.mcb_firstname || "",
                    lastName: cvData.mcb_lastname || "",
                    mainQualification: cvData.mcb_mainqualification || "",
                    email: cvData.mcb_email || "",
                    phone: cvData.mcb_phone || "",
                    currentCity: cvData.mcb_currentcity || "",
                    linkedinUrl: cvData.mcb_linkedin || "",
                    profileSummary: cvData.mcb_profilesummary?.replace(/\n/g, "") || "",
                    sectors: cvData.mcb_sectors || "",
                    sectorsOfInterest: cvData.mcb_sectors?.split(",").map(d => d.trim()) || [],
                    skills: cvSkills || "",
                    keySkills: cvKeySkills,
                    previousRoles: cvPreviousRoles,
                    experiences: cvExperiences || [],
                    achievements: cvKeyAchievements || [],
                    groupedExperiences: cvGroupedExperiences || [],
                    qualifications: cvQualifications || []
                },
                failFast: false,
                preBuildXML: (xml, documentComponent, reports, relations) => {
                    const _xml = processRichTextXML(ctx, xml, documentComponent, reports);
                    if (documentComponent === "main.xml") {
                        xmlViewProps.current.relations = relations;
                    }
                    Object.entries(reports.links).forEach(([key, { url }]) => {
                        xmlViewProps.current.links[key] = url;
                    });

                    xmlViewProps.current.XML[documentComponent] = _xml;
                    return _xml;
                }
            }).then((cvDocx) => {
                window.currentCVData = cvDocx.buffer
                setCVBytes(window.currentCVData);
            }).catch(console.error);

        }
    }, [template, dataLoading, setCVBytes, cvData, cvSkills, cvKeySkills, cvExperiences, cvPreviousRoles, cvGroupedExperiences, cvKeyAchievements, cvQualifications]);

    if (loading || !formData.cvDOCX) return <LoadingSpinner>Loading CV...</LoadingSpinner>;
    else if (xmlViewProps.current?.XML
        && ['numbering.xml', 'main.xml'].every(key => Object.keys(xmlViewProps.current.XML).includes(key)
        )) return (
            <>
                <div className="d-flex flex-grow-1 justify-content-end" style={{ maxHeight: "2.7rem" }}>
                    <button
                        className='btn btn-primary me-2 rounded-pill'
                        onClick={() => {
                            const blob = new Blob([formData.cvDOCX], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                            const cvUrl = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = cvUrl;
                            a.download = cvData.mcb_cvname || `CV_${formData.mcb_roletitle}.docx`;
                            a.style.display = "none";
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(cvUrl);
                        }}
                        
                    ><DownloadIcon className="icon" /> Download CV</button>
                
                    <HTMLDocxLink rounded HTML={cvData.mcb_coverletter} name={`CoverLetter_${ cvData.mcb_cvname || formData.mcb_roletitle}.docx`}>
                        Download Cover Letter
                    </HTMLDocxLink>
                </div>
                <div className="w-100">
                <XMLView {...xmlViewProps.current} />
                </div>
            </>
        ); else return <></>;
}
