import React from 'react';
import ProfessionModal from './ProfessionModal';
import useRole from '../Provider/TableContext/RoleContext';
import { formDataToProfession, professionToFormData } from '../Functions/Helper/Model';
import useResponsibility from '../Provider/TableContext/ResponsibilityContext';
import useABC from '../Provider/TableContext/ABCContext';

export default function HookedProfessionModal({value: _value, onSubmit: _onSubmit, onChange: _onChange, title, ...props}) {
    const { processRoleChanges } = useRole();
    const { responsibilities, processResponsibilityChanges } = useResponsibility();
    const { abcs, processABCChanges } = useABC();

    const [value, setValue] = React.useState(professionToFormData(_value, responsibilities, abcs));
    React.useEffect(() => setValue(professionToFormData(_value, responsibilities, abcs)), [_value, responsibilities, abcs]);
    
    const onSubmit = React.useCallback(async (preProcessData) => {
        const profession = typeof preProcessData === "function"?  
            preProcessData(value || {}) : {...(value || {})};
        const responsibilityChanges = [], abcChanges = [];
        const roleChange = formDataToProfession(profession, responsibilityChanges, abcChanges)

        const result = await Promise.all([
            processRoleChanges([roleChange]),
            processResponsibilityChanges(responsibilityChanges),
            processABCChanges(abcChanges)
        ]);
        
        if (typeof _onSubmit === "function") return await _onSubmit(result);
        return true;
    }, [_onSubmit, processRoleChanges, processResponsibilityChanges, processABCChanges, value]);
    
    
    const onChange = React.useCallback((value) => {
        setValue(value);
        if (typeof _onChange === "function") _onChange(value);
        return value;
    }, [_onChange]);
    
    return (
        <ProfessionModal 
            onSubmit={onSubmit}
            onChange={onChange}
            value={value}
            {...props}
        />
    );
}