
export default async function InviteUserActionHandler(json) {
    if(/^\/register/i.test(window.location.pathname)) {
        try {
            document.getElementById("InvitationCode").value = json.invitationCode;
            setTimeout(() => {
                document.getElementById("submit-redeem-invitation").click();
            }, 100);
            return {success: true};
        } catch (err) {
            return {success: false, error: err};
        }
    } else {
        return {success: false, error: new Error("Not on invitation page.")};
    }
}

