import CreateTableContext from './TableContext';

const fields = [ "mcb_templatedocumentid", "mcb_name", "mcb_document", "mcb_type" ];
const readOnly = [ "modifiedon"];

function processContextData({datas, downloadFileFromData, ...opts}) {
    const cvTemplates = datas?.filter((d) => d.mcb_type === 861400000)
        .sort((a, b) => a.mcb_name.localeCompare(b.mcb_name)) || [];
    
    const coverLetterTemplates = datas?.filter((d) => d.mcb_type === 861400001)
        .sort((a, b) => a.mcb_name.localeCompare(b.mcb_name)) || [];
    
    const genericTemplates = datas?.filter((d) => d.mcb_type === 861400002)
        .sort((a, b) => a.mcb_name.localeCompare(b.mcb_name)) || [];    
        const genericTemplate = genericTemplates[0];

    const allTemplates = datas?.sort((a, b) => a.mcb_name.localeCompare(b.mcb_name));
    
    const downloadTemplate = (template, ...args) => {
        return downloadFileFromData(template.mcb_templatedocumentid, "mcb_document", ...args)
    };

    let genericTemplateDocx = null;
    let genericTemplateDocxPromise = null;
    
    const getGenericTemplateDocx = genericTemplate && (async () => {
        if (genericTemplateDocx) return genericTemplateDocx;
        if (!genericTemplateDocxPromise)  
            genericTemplateDocxPromise = await downloadTemplate(genericTemplate, false);
        const { arrayBuffer } = await genericTemplateDocxPromise;
        genericTemplateDocx = arrayBuffer;
        return genericTemplateDocx;

    });
    return {
        ...opts,
        cvTemplates,
        coverLetterTemplates,
        genericTemplates,
        genericTemplate,
        allTemplates,
        downloadTemplate,
        getGenericTemplateDocx,
        
    };
}

const [useTemplateDocument, TemplateDocumentProvider] = CreateTableContext(
    "mcb_templatedocument", {  processContextData, fields, readOnly }
);

export default useTemplateDocument;
export { useTemplateDocument, TemplateDocumentProvider };
