import CliftonStrengths from './CliftonStrengths.json';
import QualificationLevel from './QualificationLevel.json';
import RoleLevel from './RoleLevel.json';
import RoleStatus from './RoleStatus.json';
import Sector from './Sector.json';
import Descriptions from './Descriptions.json';

const Constants = {
    portalURL: process.env.MCB_PORTAL_URL,
    CliftonStrengths,
    QualificationLevel,
    RoleLevel,
    RoleStatus,
    Sector,
    Descriptions
}
export default Constants;