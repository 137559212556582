import useMyABCCompute from "./MyABCCompute";
import useMyIdealRoleCompute from "./MyIdealRoleCompute";
import useMyRACCompute from "./MyRACCompute";
import useRequirementsAchievementCompute from "./RequirementsAchievementCompute";

export default function ComputeProvider({ children }) {
    useMyABCCompute();
    useMyIdealRoleCompute();
    useMyRACCompute();
    useRequirementsAchievementCompute();
    return children;
}