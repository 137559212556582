import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { localeCompare as localeDateCompare } from '../../Functions/Helper/Month';
import useRole from '../../Provider/TableContext/RoleContext';
import useCV from '../../Provider/TableContext/CVContext';
import ApplicationStatus from '../../Constants/ApplicationStatus.json';
import ApplicationStatusColor from '../../Constants/ApplicationStatusColors.json';
import usePowerFlow from '../../Provider/PowerFlow';
import useActionDialog from '../../Provider/ ActionDialogContext';

export default function useCVTableData({ limit = 10 }) {
    const location = useLocation();
    const { emailCVs } = usePowerFlow();
    const [pageNumber, archived, searchParams] = React.useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageNumber = parseInt(searchParams.get("i"));
        const archived = searchParams.get("archived");
        if (isNaN(pageNumber)) return [0, archived, searchParams];
        else return [pageNumber, archived, searchParams];
    }, [location.search]);

    const archivedUrl = React.useMemo(() => {
        const archievedSearchParams = new URLSearchParams(searchParams.toString());
        archievedSearchParams.set('archived', 1);
        return `${location.pathname}?${archievedSearchParams.toString()}`;
    }, [searchParams, location.pathname]);

    const notArchivedUrl = React.useMemo(() => {
        const nonArchcievedSearchParams = new URLSearchParams(searchParams.toString());
        nonArchcievedSearchParams.delete('archived');
        return `${location.pathname}?${nonArchcievedSearchParams.toString()}`;
    }, [searchParams, location.pathname]);

    const [search, setSearch] = React.useState(searchParams.get("q") || "");
    const [sortColumn, setSortColumn] = React.useState("createdon");
    const [sortDirection, setSortDirection] = React.useState(true);
    const [filter, setFilter] = React.useState({});
    const [checked, setChecked] = React.useState([]);

    const sortColumnClickFactory = React.useMemo(() => (column) => () => {
        if (sortColumn === column) setSortDirection((d) => !d);
        else {
            setSortColumn(column);
            setSortDirection(true);
        }
    }, [sortColumn]);

    const { cvs, updateCV, createCV, loading: cvLoading } = useCV();
    const { roles, loading: roleLoading } = useRole();
    const loading = useMemo(() => cvLoading || roleLoading, [cvLoading, roleLoading]);

    const archiveCV = React.useCallback((...cvIds) => {
        const cv = cvs?.filter(cv => cvIds.includes(cv.mcb_cvid));
        if (cv?.length) {
            cv.forEach(cv => {
                updateCV({ ...cv, statecode: '1' });
            });
        }
    }, [cvs, updateCV]);

    const unArchiveCV = React.useCallback((...cvIds) => {
        const cv = cvs?.filter(cv => cvIds.includes(cv.mcb_cvid));
        if (cv?.length) {
            cv.forEach(cv => {
                updateCV({ ...cv, statecode: 0 });
            });
        }
    }, [cvs, updateCV]);
    const { prompt } = useActionDialog();

    const duplicateCV = React.useCallback(async (cvId, cvName) => {
        const cv = cvs?.find(cv => cv.mcb_cvid === cvId);
        if (cv) {
            let mcb_cvname, invalidName = true;
            do {
                if (mcb_cvname) mcb_cvname = await prompt("The CV name already exists. Please enter a different name", mcb_cvname );
                else mcb_cvname = await prompt("Enter the name of the new CV", `${cv.mcb_cvname}`);
                if (mcb_cvname === null) return null;
                for (let c = 0; c < cvs.length; c++) {
                    if (cvs[c].mcb_cvname === mcb_cvname) {
                        invalidName = true;
                        break;
                    }
                    else invalidName = false;
                }
            } while (invalidName);
            
            const data = await createCV({...cv, mcb_cvname, mcb_cvid: undefined});
            return data;
        }
    }, [cvs, createCV, prompt]);

    const { processedCVs, company_names, cv_titles, application_statuses } = useMemo(() => {
        if (loading) return [];

        const company_names = [];
        const cv_titles = [];
        const application_statuses = [];

        let processedCVs = cvs?.map(cv => {
            const cvRoles = roles.filter(role => role._mcb_cv_value === cv.mcb_cvid);
            if (!cv_titles.includes(cv.mcb_title)) cv_titles.push(cv.mcb_title);
            const companyNames = [];
            const applicationStatuses = [];
            const applicationStatusLabels = [];
            const applicationStatusDisplay = [];
            
            cvRoles.forEach(role => {
                const companyName = role.mcb_companyname_ || role.mcb_rolecompanyname || role.mcb_interestedcompany;
                if (!company_names.includes(companyName)) company_names.push(companyName);
                if (companyName && !companyNames.includes(companyName)) companyNames.push(companyName);

                if (role.mcb_applicationstatus && !applicationStatuses.includes(role.mcb_applicationstatus)) {
                    const applicationStatusLabel = Object.entries(ApplicationStatus).find(([, value]) => value === role.mcb_applicationstatus)?.[0];
                    const applicationStatusColor = ApplicationStatusColor[applicationStatusLabel] || "var(--mo-gray-400)";
                    applicationStatuses.push(role.mcb_applicationstatus);
                    applicationStatusLabels.push(applicationStatusLabel);
                    if (application_statuses.every(({ value }) => value !== role.mcb_applicationstatus))
                        application_statuses.push({
                            value: role.mcb_applicationstatus,
                            label: applicationStatusLabel,
                            color: applicationStatusColor
                        });
                    applicationStatusDisplay.push(
                        <div
                            className="rounded-pill text-light px-2 py-1 text-center flex-shrink-0"
                            style={{ backgroundColor: applicationStatusColor }}
                            key={role.mcb_applicationstatus}
                        >
                            {applicationStatusLabel}
                        </div>
                    );

                }
            });
            const created_date = new Date(cv.createdon);
            created_date.localeCompare = localeDateCompare;
            return {
                ...cv,
                created_date,
                company_name: companyNames.join(", "),
                application_statuses: applicationStatuses,
                application_status_label: applicationStatusLabels.join(", "),
                application_status_display: <div className="d-flex flex-wrap gap-2">{applicationStatusDisplay}</div>,
                archived: cv.statecode === 1 || cv.statecode === "1"
            }
        });
        return { processedCVs, company_names, cv_titles, application_statuses };
    }, [loading, cvs, roles]);

    const [visibleCVs, pageCount] = React.useMemo(() => {
        const filtered = Object.values(filter).some(value => value && value !== "all");

        const trimmedSearch = search.trim();
        let finalCVs = processedCVs?.slice() || [];
        finalCVs = finalCVs?.sort((a, b) => {
            const fieldA = a[sortColumn] || "";
            const fieldB = b[sortColumn] || "";
            if (sortDirection) return fieldA.localeCompare(fieldB);
            else return fieldB.localeCompare(fieldA);
        }) || [];

        finalCVs = finalCVs.filter(cv => archived ? cv.archived : !cv.archived);

        if (trimmedSearch?.length || filtered) {
            const keywords = search.split(" ");
            finalCVs = finalCVs.filter(cv => {
                const cvRoles = roles.filter(role => role._mcb_cv_value === cv.mcb_cvid);
                return (
                    !trimmedSearch?.length
                    || (keywords?.every(keyword => {
                        const k = keyword.trim().toLowerCase();
                        if (!k?.length) return true;
                        else {
                            return ["mcb_cvname", "mcb_title", "mcb_profilesummary"]
                                .some(attrib => cv[attrib]?.toLowerCase().includes(k))
                                || cvRoles.some(role => ["mcb_roletitle", "mcb_companyname_", "mcb_rolecompanyname"]
                                    .some(attrib => role[attrib]?.toLowerCase().includes(k)));
                        }
                    }))
                ) && Object.entries(filter).every(([key, value]) => {
                    if (!value || value === "all") return true;
                    else if (typeof value?.test === "function") return value.test(value, cv[key]);
                    else if (Array.isArray(value)) return value.includes(cv[key]);
                    else return cv[key] === value;
                });
            });
        }

        return [finalCVs?.slice(limit * pageNumber, limit * (pageNumber + 1)), Math.ceil(finalCVs?.length / limit)];
    }, [processedCVs, roles, search, archived, filter, sortColumn, sortDirection, pageNumber, limit]);

    return {
        processedCVs, visibleCVs, loading, search, setSearch,
        company_names, cv_titles, application_statuses,
        sortColumn, sortDirection, sortColumnClickFactory,
        checked, setChecked, setFilter, filter,
        pageCount, pageNumber, searchParams,
        archiveCV, unArchiveCV, archivedUrl, notArchivedUrl, archived,
        emailCVs, duplicateCV
    }
}