import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { addClassNames } from '../../Functions/Helper/Component';
import { 
    InfoCircleFill as InformationIcon
} from './Icons';
import './Card.scss';

export default function Card({ id, preTitle, title, width, header, footer, description, DescriptionControl, classNames, className, children}) {
    const [tooltipId1, tooltipId2] = React.useMemo(() => [
        Math.random().toString(36).substring(2, 9),
        Math.random().toString(36).substring(2, 9)
    ], []);

    return (
        <div id={id} className={addClassNames("mcb-card card", className, classNames?.cardWrapper)} style={{ width}}>
            <div className={addClassNames("card-body", classNames?.cardBody)}>
                {(title || header||description) && (
                    <div className={addClassNames("header", classNames?.header)}>

                            {(title || header) && (
                                <div className={addClassNames("header-content", classNames?.headerContent)}>
                                    {preTitle}
                                    <div className="d-inline-block" data-tooltip-id={tooltipId1}>
                                        {title && <h5 className={addClassNames("header-title", classNames?.headerTitle)}>{title}</h5>}
                                        {header}
                                        <ReactTooltip id={tooltipId1} place="bottom">
                                            <div style={{maxWidth: "min(666px, 80vw)"}}>
                                                {description}
                                            </div>
                                        </ReactTooltip>
                                    </div>
                                </div>
                            )}
                        {DescriptionControl? (
                            <div data-tooltip-id={tooltipId2}>
                                <DescriptionControl/>
                                <ReactTooltip id={tooltipId2} place="bottom">
                                    <div style={{maxWidth: "min(666px, 80vw)"}}>
                                        {description}
                                    </div>
                                </ReactTooltip>
                            </div>
                        ) : description && (
                            <div 
                                data-tooltip-id={tooltipId2}
                                className={addClassNames("header-information", classNames?.headerInformation)}
                            >
                                <InformationIcon/>
                                <ReactTooltip id={tooltipId2} place="bottom">
                                    <div style={{maxWidth: "min(666px, 80vw)"}}>
                                        {description}
                                    </div>
                                </ReactTooltip>
                            </div>
                        )}
                    </div>
                )}
                <div className={addClassNames("body", classNames?.body)}>{children}</div>
                <div className={addClassNames("footer", classNames?.footer)}>{footer}</div>

            </div>
        </div>
    );
}