import React from 'react';
import LoadingSpinner from './Generic/LoadingSpinner';
import Card from './Generic/Card';
import RichTextDisplay from './RichTextDisplay';
import HTMLDocxLink from './HTMLDocxLink';
import { addClassNames } from '../Functions/Helper/Component';

const Description = (name, HTML) => () => <HTMLDocxLink HTML={HTML} rotate name={name}> Download MyIdealRole</HTMLDocxLink>;

export default function MyIdealRole({role, className}) {
    
    const children = !role?.mcb_rac? <LoadingSpinner/> : <RichTextDisplay value={role?.mcb_rac}/>;
    
    return (
        <Card
            title="MyIdealRole"
            className={addClassNames("w-100", className)}
            description="Downloads MyIdealRole as MS Word Document"
            DescriptionControl={Description(`MyIdealRole`, role?.mcb_rac)}
        >
            {children}
        </Card>
    );
}