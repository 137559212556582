import { ActionViaFrame } from "./FrameActionHelper";
import apiCall from "./AjaxViaFrame";
// ForgotPassword prints the success message in a dialog box
// usePortalMessageHandler will look for this message and respond FORGOT_PASSWORD_SUCCESS
// to handle this case there are required additional logics.
import forgotPassword from "./ForgotPasswordViaFrame";


// these causes redirection of iframe to new url 
// so the usePortalFrame handles the redirection and redirect app accordingly
const  signIn = ActionViaFrame("LOG_IN");
const  inviteUser = ActionViaFrame("INVITATION");
const  registerUser = ActionViaFrame("REGISTER");


const actions = {
    apiCall,
    signIn,
    inviteUser,
    registerUser,
    forgotPassword
};

export default actions;