import React from "react";

export default function LogoWheel(props){
    const rand = React.useMemo(() => Math.floor(Math.random() * 1000).toString(), []);
    const idRand = React.useCallback((id) => `${id}-${rand}`, [rand]);
    return (
        <svg {...props} version="1.1" viewBox="0 0 686.22498 686.22498">
            <defs id={idRand("gradient-def")}>
            <linearGradient id={idRand("pitch-gradient")}>
                <stop style={{"stopColor": "#00ffda", "stopOpacity": 1}} offset="0.24175826" id="stop16"></stop>
                <stop style={{"stopColor": "#2b00ff", "stopOpacity": 1}} offset="0.49191085" id="stop25"></stop>
                <stop style={{"stopColor": "#b800fe", "stopOpacity": 1}} offset="0.64194137" id="stop24"></stop>
                <stop style={{"stopColor": "#fb3758", "stopOpacity": 1}} offset="0.80036628" id="stop21"></stop>
                <stop style={{"stopColor": "#faff00", "stopOpacity": 1}} offset="1" id="stop18"></stop>
            </linearGradient>
            <linearGradient xlinkHref={"#" + idRand("pitch-gradient")} id={idRand("gradient-assoc")} x1="120.24007" y1="-721.99713" x2="547.0799" y2="-24.773806" gradientUnits="userSpaceOnUse" gradientTransform="rotate(75,-102.62477,-3.5841206)"></linearGradient>
            </defs>
            <g id={idRand("groups")} transform="translate(3.1122662,3.112257)">
                <path id={idRand("spinner-logo")} style={{
                    fill: `url('#${idRand("gradient-assoc")}')`,
                    stroke: "#000000",
                    "strokeWidth": 0,
                    "strokeLinecap": "round",
                    "paintOrder": "markers stroke fill",
                }} d="M 315.27396,4.3229829 A 150,150 0 0 0 202.00321,127.5049 a 150,150 0 0 1 14.37233,4.36847 150,150 0 0 1 94.46286,162.8037 150,150 0 0 1 -110.68892,-152.26652 150,150 0 0 1 1.85373,-14.90565 150,150 0 0 0 -179.421338,90.64951 150,150 0 0 0 72.678556,187.42336 150,150 0 0 1 8.596832,-12.3186 150,150 0 0 1 69.54003,-48.03746 150,150 0 0 1 14.17927,-3.86807 150,150 0 0 1 100.12006,12.66101 150,150 0 0 1 -178.83182,57.93293 150,150 0 0 1 -13.604372,-6.36981 150,150 0 0 0 30.770202,198.65267 150,150 0 0 0 200.7082,-11.20589 150,150 0 0 1 -9.0582,-11.98181 150,150 0 0 1 19.72615,-187.21113 150,150 0 0 1 -0.40539,188.22357 150,150 0 0 1 -10.26256,10.96937 150,150 0 0 0 198.43909,32.12344 150,150 0 0 0 51.66341,-194.45034 150,150 0 0 1 -14.49257,5.01561 150,150 0 0 1 -171.79707,-76.30765 150,150 0 0 1 9.98507,-3.77951 150,150 0 0 1 168.74451,62.0251 150,150 0 0 1 7.56006,13.04645 A 150,150 0 0 0 668.41478,252.00153 150,150 0 0 0 499.74117,142.7371 150,150 0 0 1 499.7378,158.11146 150,150 0 0 1 374.07624,297.91841 150,150 0 0 1 484.70305,145.93551 150,150 0 0 1 499.74117,142.7371 150,150 0 0 0 357.79479,0.46564373 150,150 0 0 0 315.27396,4.3229829 Z"></path>
            </g>
        </svg>
    );
}