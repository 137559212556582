import React from "react";
import useABC from '../../Provider/TableContext/ABCContext';
import useSkill from '../../Provider/TableContext/SkillContext';
import useResponsibility from '../../Provider/TableContext/ResponsibilityContext';
import useQualification from '../../Provider/TableContext/QualificationContext';
import useRole from "../../Provider/TableContext/RoleContext";
import { generateRACHTML } from '../../Functions/Generator/RACGenerator';
import RoleStatus from '../../Constants/RoleStatus.json';

export default function useMyRACCompute() {
    const { roles, updateRole, loading: rolesLoading} = useRole();
    const { abcs, loading: abcLoading } = useABC();
    const { responsibilities, loading: respLoading } = useResponsibility();
    const { qualifications, loading: qualLoading } = useQualification();
    const { skills, loading: skillsLoading } = useSkill();
    
    const loading = React.useMemo(() =>
        rolesLoading || abcLoading || respLoading || skillsLoading || qualLoading
    , [rolesLoading, abcLoading, respLoading, skillsLoading, qualLoading]);
    
    React.useEffect(() => {
        if (!loading) {
            roles.forEach(role => {
                if (role.mcb_rolestatus !== RoleStatus.Applying) return;
                const value = generateRACHTML(role, responsibilities, qualifications, abcs, skills);
                if (value && value !== role.mcb_rac) {
                    updateRole({
                        ...role,
                        mcb_rac: value
                    }, false);
                }
            });
        }
    }, [loading, roles, rolesLoading, responsibilities, qualifications, abcs, skills, updateRole]);
    
}