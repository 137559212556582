import React from 'react';
import useCallbackContext from '../Components/Generic/CallbacksContext';

export default function useFormCallback({formRef, onSubmit, isNew, forceCallback}) {
    const callbackContext = useCallbackContext();
    React.useEffect(() => {
        if (
            callbackContext?.addCallback
            && callbackContext?.removeCallback
            && (!isNew || forceCallback)
        ) {
            const callback = async () => {
                if (formRef?.current) {
                    if (formRef?.current.reportValidity()) {
                        if (typeof onSubmit === "function") return await onSubmit();
                        else return true;
                    } else return false;
                } else {
                    if (typeof onSubmit === "function") return await onSubmit();
                    else return true;
                }
            }
            callbackContext.addCallback(callback);
            return () => {
                callbackContext.removeCallback(callback);
            };

        }
    }, [isNew, formRef, onSubmit, callbackContext, forceCallback]);
    return callbackContext;
}

export function useCallbackSubmission(onSubmit) {
    const { callback } = useCallbackContext();
    const onFormSubmit = React.useCallback(async () => {
        if (typeof onSubmit !== "function") return true;
        const formDataSetters = typeof callback === "function"? await callback() : [];
        if (formDataSetters?.length) {
            if (formDataSetters.some(setter => setter === false)) return false;
            else return await onSubmit((data) => {
                return formDataSetters.reduce((acc, setter) => {
                    if (typeof setter === "function")  return setter(acc);
                    else if (typeof setter === "object") return {...acc, ...setter};
                    else return acc;
                }, data);
            });
        } else {
            return await onSubmit();
        }
    }, [onSubmit, callback]);

    return onFormSubmit;
}
