import { generateRequestId, awaitIframe } from './FrameActionHelper';
import { sleepAsync } from '../Helper/Async';

async function sendAjaxRequestMessage(iframeRef, ajaxOptions) {
  const requestId = generateRequestId();
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res; reject = rej; });

  let iframeTimeout;
  const handleMessage = (event) => {
    
    try {
      if (!iframeRef.src.includes(event.origin)) return
      const response = event.data;
      
      if (response.requestId === requestId) {
        if(iframeTimeout) {
          clearTimeout(iframeTimeout);
          iframeTimeout = 0;
        }  
        switch (response.action) {
          case "API_REQUEST_RECV":
            setTimeout(function () {
              window.removeEventListener('message', handleMessage);
              reject(new Error("API Timeout"));
            }, 30000);
            break;
          case "API_REQUEST_RESPONSE":
            if (response.success) {
              resolve(response.result);
            } else {
              reject(response.error);
            }
            window.removeEventListener('message', handleMessage);
            break;
          default:
            break;
        }
      }
    } catch (e) { 
      console.error(e);
    }
  };
  window.addEventListener('message', handleMessage);

  const jsonData  = {
    action: "API_REQUEST",
    requestId,
    data: ajaxOptions
  };
  
  iframeTimeout = setTimeout(function () {
    window.removeEventListener('message', handleMessage);
    reject(new Error("iframe Timeout"));
  }, 1000);

  iframeRef.contentWindow.postMessage(jsonData, '*');

  return await promise;
}
 

/**
 * Performs an AJAX request using an iframe.
 * @param {HTMLIFrameElement} iframeRef - The reference to the iframe element.
 * @param {Object} ajaxOptions - The options for the AJAX request.
 * @returns {Promise} A promise that resolves with the response or rejects with an error.
 */
export default async function AjaxViaFrame(iframeRef, ajaxOptions, retries = 10) {
  
  await awaitIframe(iframeRef, (iframeRef) => {
    return iframeRef.src.includes("/web-api");
  } );
  let tries = 0;
  while(tries++ < retries) {
    try {
      return await sendAjaxRequestMessage(iframeRef, ajaxOptions);
    } catch (err) {
      if (err.status === 404) {
        throw err;
      }
      await sleepAsync(500);
      console.error(err);
    }
  }

  throw new Error("AjaxViaFrame failed");
}
  