import React, { useEffect } from 'react';
import Form from './Generic/Form';
import { SingleInputColumn } from './Generic/InputGrouping';
import SkillInput from './SkillInput';
import { addClassNames } from '../Functions/Helper/Component';
import CliftonStrengthInput from './CliftonStrengthInput/CliftonStrengthInput';
import { XCircle as ClearIcon, Robot as AIIcon } from './Icons';
import usePowerFlow from '../Provider/PowerFlow';
import useActionDialog from '../Provider/ ActionDialogContext';
import RichTextDisplay from './RichTextDisplay';
export default function AchievementForm({ value, isNew, onChange, Collapse, Collapsable, ActionComponent, onSubmit, onCancel, className }) {
    const formRef = React.useRef(null);
    const { aiGenerate } = usePowerFlow();
    // const onChangeOverride = React.useCallback((value, alias) => {
    //     onChange((oldValue) => {
    //         const result =  {
    //             ...oldValue,
    //             [alias]: value
    //         };
    //         if (result?.mcb_achievement && result?.mcb_benefit) {
    //             result.mcb_achievementstatement = `<p><strong>${result.mcb_benefit.trim() || ""}</strong> by ${result.mcb_achievement.trim() || ""}</p>`;
    //         } else {
    //             result.mcb_achievementstatement = "";
    //         }
    //         return result;
    //     });
    // }, [onChange]);
    const { confirm } = useActionDialog();

    const forceCallback = React.useMemo(() => {
        return value?.skills?.length > 0 || ["mcb_achievement", "mcb_benefit", "mcb_achievementstatement"].some((alias) => (
            value?.[alias] && value[alias].trim().length > 0
        ));
    }, [value]);

    return (
        <Form
            ref={formRef}
            onSubmit={onSubmit}
            className={addClassNames("achievement-form d-flex flex-column gap-4 mb-4", className)}
            isNew={isNew}
            forceCallback={forceCallback}
        >

            {isNew && (forceCallback || Collapsable) && ( <button
                type="button"
                className="btn btn-transparent text-danger fs-4 position-absolute t-3 r-0"
                title="Clear Form"
                onClick={() => {
                    onChange(() => ({}));
                    if (Collapsable) Collapse();
                }}
            ><ClearIcon/></button>)}
            <>
            <SingleInputColumn
                state={value}
                setState={onChange}
                inputs={[
                    [
                        'mcb_achievement', 'textarea', (
                            <span>
                                <strong>Task/Action</strong> (What I did / delivered?)
                            </span>
                        ), { required: true, rows: 1 }
                    ], [
                        'mcb_benefit', 'textarea', (
                            <span>
                                <strong>Benefit/Result</strong> / Outcome (Gain to the business from your achievement)
                            </span>
                        ), { required: true, rows: 1 }
                    ], [
                        'skills', SkillInput, (
                            <span>
                                <strong>Skills</strong> (1,2 or 3 words e.g. Leadership)
                            </span>
                        ), { required: true }
                    ], [
                        'cliftonstrengths', CliftonStrengthInput, (
                            <span>
                                <strong>Clifton Strengths</strong>
                            </span>
                        ), { multiselect: true }
                    ], [
                        'mcb_achievementstatement', 'richtext', (
                            <span>
                                <strong>Achievement Statement</strong> (Outcome / Benefit followed by the how - context)  <em className='fs-smaller'>(max 500 char)</em>
                                <button type="button" className="btn btn-ai-generate rounded-pill fs-smaller" onClick={async (e) => {
                                    try {
                                        const benefit = (value?.mcb_benefit || "").trim().replace(/[.!?]$/, "");
                                        let achievement = (value?.mcb_achievement || "").trim();
                                        achievement = achievement[0].toLowerCase() + achievement.slice(1);
                                        let mcb_achievementstatement;
                                        if(await confirm(
                                            <AchievementGenerationModal
                                                achievement={achievement}
                                                benefit={benefit}
                                                onChange={(value) => {
                                                    mcb_achievementstatement = value;
                                                }}
                                                correctHTML={aiGenerate}
                                            />
                                        )) {
                                            onChange((oldValue) => ({...oldValue, mcb_achievementstatement }));
                                        };
                                    } catch(err) {
                                        console.error(err);
                                    }
                                }}><AIIcon className="icon"/>AI Generate</button>
                            </span>
                        ), { required: true, maxCharacterCount: 500 }
                    ]
                ]}
            />
            </>
            {ActionComponent && (
                <div key="actions">
                    <ActionComponent {...{onSubmit, onCancel, value, submitType: "submit"}} />
                </div>
            )}
        </Form>
    );
}

function AchievementGenerationModal({
    achievement, benefit, onChange, correctHTML
}) {
    const [generatedStatements, setGeneratedStatements] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [achievementStatement, setAchievementStatement] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const achievementGenerated = React.useRef(false);

    const generateAchievementStatement = React.useCallback(async () => {
        const startedVerbs = generatedStatements.map((statement) => {
            return statement.split(/<\/?\w+>/).join("").split(/[\s,]+/)[0];
        }).filter((verb) => verb);
        if (startedVerbs.length < 3) startedVerbs.push("enhanced");
        if (generatedStatements.length < 5) {
            setLoading(true);
            try {
                const result = await correctHTML(`${benefit} by ${achievement}`,
                    "format as `<p><strong>benefit, statistics and result</strong> by task and action taken</p>`. correct grammar and capitalization, start with past perfect verb not "
                    + startedVerbs.join(", ")
                );
                setGeneratedStatements((a) => [result, ...a]);
                setAchievementStatement(result);
                typeof onChange === "function" && onChange(result);    
            } catch (err) {
                console.error(err);
                setError(err);
            }
            setLoading(false);
        }
    }, [correctHTML, achievement, benefit, generatedStatements, onChange]);
    useEffect(() => {
        if (error){
            const setErrorTimeout = setTimeout(() => {
                setError(null);
            }, 3000);
            return () => clearTimeout(setErrorTimeout);
        }
    }, [error]);

    useEffect(() => {
        if (!achievementGenerated.current) {
            achievementGenerated.current = true;
            generateAchievementStatement();
        }
    }, [generateAchievementStatement]);
    
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <span>Please Confirm Generated Output:</span>    
                <button type="button" disabled={loading || generatedStatements.length >= 4} className="btn btn-ai-generate btn-success rounded-pill fs-smaller" onClick={async (e) => {
                    e.preventDefault();
                    await generateAchievementStatement();
                }}><AIIcon className="icon"/>Generate More ({4 - (generatedStatements?.length || 0)})</button>
            </div>
            {loading && <div className="spinner-border text-primary" role="status"/>}
            {error && <div className="alert alert-danger">{error.message}</div>}
            <>
                {generatedStatements.map((statement, index) => (
                    <div key={index} className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="generatedStatement"
                            key={`generatedStatement${index}`}
                            id={`generatedStatement${index}`}
                            value={statement}
                            checked={achievementStatement === statement}
                            onChange={() => {
                                setAchievementStatement(statement);
                                typeof onChange === "function" && onChange(statement);
                            }}
                        />
                        <label className="form-check-label" htmlFor={`generatedStatement${index}`}>
                            <RichTextDisplay value={statement}/>
                        </label>
                    </div>
                ))}
            </>
            <i className="fs-smaller text-center w-100">You are encouraged to review the achievement statement and revise if required. </i>
        </div>
    )
}
