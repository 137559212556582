import React from 'react';

const CallbackContext = React.createContext();

export function CallbackContextProvider({callbackRef, children}) {
    const callbacks = React.useRef([]);
    const value = React.useMemo(() => {
        const result = ({
            callback: async () => {
                return await Promise.all(callbacks.current
                    .map(callback => callback()));
            },
            callbacks: () => callbacks.current,
            addCallback: (callback) => {
                callbacks.current.push(callback); 
            },
            removeCallback: (callback) => {
                callbacks.current = callbacks.current.filter((c) => c !== callback);
            },
        });
        if (callbackRef) callbackRef.current = result;
        return result;
    }, [callbackRef]);
    return (
        <CallbackContext.Provider value={value}>
            {children}
        </CallbackContext.Provider>
    );
}

export default function useCallbackContext() {
    return React.useContext(CallbackContext);
}
