import React from 'react';

export default function AccountFrameLayout({title, link, redirect}) {
    React.useEffect(() => {
        if (redirect) {
            const redirect = setTimeout(() => {
                window.location.href = redirect;
            }, 3000);
            return () => {clearTimeout(redirect);}
        }
    }, [redirect]);
    React.useEffect(() => {
        const pageHeading = document.getElementsByClassName("page-heading")[0];
        if (pageHeading) pageHeading.style.display = "none";
        const mainContent = document.getElementById("mainContent");
        let titleElement;
        Array.from(mainContent.parentNode.children).forEach(d => {
            if (d.id === "mainContentTitle") {
                titleElement = d;
            } else if(d.id!== "mainContent") d.style.display = "none"; 
        });
        if (title && !titleElement) {
            titleElement = document.createElement('h1');
            titleElement.classList.add("h2");
            titleElement.id = "mainContentTitle";
            titleElement.innerText = title;
            mainContent.prepend(titleElement);
        }

        document.getElementById('root').style.display ="none";

        document.body.style.fontSize = "14px";
        document.body.style.backgroundColor = "transparent";
        document.body.style.overflow = "hidden";
        
        const primaryButton = mainContent.getElementsByTagName('button')[0] || mainContent.getElementsByTagName('a')[0];
        if (primaryButton && primaryButton.classList.contains("btn-primary")) {
            primaryButton.style.border = "none";
            primaryButton.style.backgroundColor = "var(--mo-link-color)";
            primaryButton.style.color = "white";
            if (link && primaryButton.tagName === "A") primaryButton.href = link;
        }

        Array.from(mainContent.getElementsByTagName("fieldset")[0].children).forEach(d => {
            if (d?.tagName === "LEGEND") {
                d.style.display = "none";
            }
        });

        const handleMessage = (event) => {
            const iframeHeight = document.documentElement.scrollHeight;
            if (event.data === 'getIframeHeight') {
                event.source.postMessage({ type: "iframeHeight", iframeHeight}, event.origin);
            } else if (event.data === 'mount-check') {
                event.source.postMessage({ type: "iframeHeight", iframeHeight}, event.origin);
                event.source.postMessage({type: 'mounted'}, event.origin);
            }
        };

        window.addEventListener('message', handleMessage);
        window.parent.postMessage({type: 'mounted'}, "*");

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [title, link]);
    
    return (<div/>);
}