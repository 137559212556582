import React from 'react';
import Input from '../Generic/Input';
import { filterByDate, filterDateLabel } from './FilterHelper';

export default function DateOptions({ filter, setFilter }) {
    return [
        <div className="d-flex flex-row gap-3 p-2 flex-nowrap">
            <div>
                <span>From: </span>
                <label>
                    <input
                        type='checkbox'
                        checked={!filter?.created_date?.customFrom}
                        onChange={(e) => {
                            setFilter((filter) => {
                                const created_date = filter?.created_date || { test: filterByDate };
                                created_date.customFrom = !e.target.checked;

                                return filterDateLabel({...filter, created_date});
                            });
                        }}
                    /> Beginning</label>
                <Input 
                    type="month"
                    className={filter?.created_date?.customFrom? "" : "d-none" }
                    value={filter?.created_date?.from || null}
                    onChange={(value) => {
                        setFilter((filter) => {
                            const created_date = filter?.created_date || { test: filterByDate };
                            created_date.from = value;
                            created_date.fromValue = new Date(value);

                            return filterDateLabel({...filter, created_date});
                        });
                    }}
                />
            </div>
            <div>
                <span>To: </span>
                <label>
                    <input
                        type='checkbox'
                        checked={!filter?.created_date?.customTo}
                        onChange={(e) => {
                            setFilter((filter) => {
                                const created_date = filter?.created_date || { test: filterByDate };
                                created_date.customTo = !e.target.checked;
                                if (!created_date.to) {
                                    created_date.to = new Date().toISOString().split("T")[0];
                                    created_date.toValue = new Date(created_date.to);
                                }
                                return filterDateLabel({...filter, created_date});
                            });
                        }}
                    /> Today</label>
                <Input 
                    type="month"
                    className={filter?.created_date?.customTo? "" : "d-none" }
                    value={filter?.created_date?.to || null}
                    onChange={(value) => {
                        setFilter((filter) => {
                            const created_date = filter?.created_date || { test: filterByDate };
                            created_date.to = value;
                            created_date.toValue = new Date(value);
                            return filterDateLabel({...filter, created_date});
                        });
                    }}
                />
            </div>
        </div>
    ];
}