import React from "react";

export default function RichTextDisplay({value, css}) {
    const containerRef = React.useRef();
    const shadowRootRef = React.useRef(null);
    React.useEffect(() => {
        if (!containerRef.current) return;
        if (!shadowRootRef.current) {
            shadowRootRef.current = containerRef.current.attachShadow({mode: 'open'});
        }
        shadowRootRef.current.innerHTML = value;
        if (css) {
            const style = document.createElement('style');
            style.textContent = css;
            shadowRootRef.current.appendChild(style);
        }
    }, [value, css]);
    return (
        <div
            ref={containerRef}
        />
    );
}
