import { formatData } from "./Format.Helper";
import { divHTML, normalizeRichText, spanHTML, strongHTML, styleHTML, tableHTML } from "./Generator.Helper";
import { generateRACTableRowsHTML } from "./RACTable.Genrator";

/**
 * 
 * @param {{ 
*      keySkills: { id: string, name: string }[],
*      keyStrengths: { id: string, name: string }[] 
*  }} data 
*/
function generateCoreSkillStrengthSummary(data) {
  /**
   * 
   * @param {{ id: string, name: string }[]} keyItems 
   * @returns 
   */
  function processKeyItems(keyItems) {
      return keyItems.map(function(keyItem) {
          return spanHTML(keyItem.name + " ");
      }).join(spanHTML(", "))
  }
  return [
      [[
          ["Core Competencies/Skills (Headings only):", { options: "rowspan=\"2\""}],
          [processKeyItems(data.keySkills), { options: "colspan=\"2\""}],
      ]], [[
          [processKeyItems(data.keyStrengths), { options: "colspan=\"2\""}],
      ]]
  ];
}

function generateFormHTML(data) {
    const name = window.context.user.name,
    {
        roleTitle,
        reportingTo,
        baseRemuneration,
        atRiskRemuneration,
        interestedSector,
        notInterestedSector,
        interestedCompany,
        notInterestedCompany,
        generalComments,
    } = data;
        
    return [
        [[ 
            strongHTML("My Name:"), 
            [name, { 
                style: "background-color: #9bcb3d",
                options: "colspan=\"2\""
            }]
        ]], [[ 
            strongHTML("Role/Job Titles / Operating Level of Interest:"),
            [roleTitle, { options: "colspan=\"2\"" }]
        ]], [[ 
            strongHTML("Reporting to:"),
            [reportingTo, { 
                style: "background-color: #9bcb3d",
                options: "colspan=\"2\""
            }]
        ]], [[
            [ strongHTML("Remuneration Banding (Salary/Hourly rate):"), { 
                options: "rowspan=\"2\""
            }],
            strongHTML("At Risk Remuneration. i.e. % Bonus / Equity / Other:"),
            strongHTML ("Base Salary/Hourly Rate From:")
        ]], [[
            baseRemuneration,
            atRiskRemuneration
        ]], [[
            strongHTML("Sectors and Industries of Interest: "),
            spanHTML("Interested In:&nbsp;", "color:rgb(155, 203, 61);") 
                + spanHTML(interestedSector),
            spanHTML("Not Interested In:&nbsp;", "color:rgb(237, 125, 49)")
                + spanHTML(notInterestedSector)
        ]], [[
            strongHTML("Potential Companies: "),
            spanHTML("Interested In:&nbsp;", "color:rgb(155, 203, 61);") 
                + spanHTML(interestedCompany),
            spanHTML("Not Interested In:&nbsp;", "color:rgb(237, 125, 49)")
                + spanHTML(notInterestedCompany)
        ]],
        ...generateCoreSkillStrengthSummary(data),
        [[
            strongHTML("General comments (what else would you like to focus on:"),
            [generalComments && normalizeRichText(generalComments), { 
                options: "colspan=\"2\""
            }]
        ]], [[
            ["", 
            { 
                style: "background-color: #9bcb3d",
                options: "colspan=\"3\""
            }]
        ]], [[
            [strongHTML("Key Challenges/Responsibilities I will be addressing / am interested in." ),{
                options: "valign=\"top\""
            }],
            [divHTML(strongHTML("Previous experience and outcomes"))
                + divHTML(strongHTML("(Proven Capability) Achievements?")),{
                    options: "valign=\"top\""
                }],
            [strongHTML("Existing key competencies and skills I will apply"),{
                options: "valign=\"top\""
            }]
                
        ]]
    ];
}

export function generateIdealRoleHTML(role, responsibilities, qualifications, abcs, skills) {
    const data = formatData(role, responsibilities, qualifications, abcs, skills);
    return '<div data-wrapper="true" style="font-family:Calibri;font-size:11pt">'
       + tableHTML(
           { options: "cellspacing=\"0\" cellpadding=\"3\" border=\"1\" docx-table-width=\"13958\"" },  
           ...generateFormHTML(data),
           ...generateRACTableRowsHTML(data)
       ) + '</div>' + styleHTML;
}
