export function formattedValue(value) {
    if (value === null) return "";
    else return `${value}@OData.Community.Display.V1.FormattedValue`;
}

export function processMultipleSelection(value) {
    return value.split(",").map(function(val) {
        return val.trim();
    }).join(", ");
}

/**
 * 
 * @param {object} role  
 */
function formatRoleData(role, responsibilityData, qualificationData){
    const responsibilities = {},
        keySkills = [],
        keyStrengths = [],
        tertiaryQualifications = [],
        createdon = role.mcb_datesubmitted || role.createdon;

    responsibilityData?.forEach(responsibility => {
        if (responsibility._mcb_role_value === role.mcb_roleid) {
            responsibilities[responsibility.mcb_responsibilityid] = {
                responsibility: responsibility.mcb_description,
                abcs: (responsibility.mcb_ABCResponsibility || []).map(({mcb_abcid}) => mcb_abcid)
            };
        }
    });
    
    (role.mcb_KeySkills || []).forEach(function({mcb_skillid}) {
        if (!keySkills.includes(mcb_skillid))
            keySkills.push(mcb_skillid);
    });
    const cliftonStrengths = (role.mcb_cliftonstrength || "")?.split(",");
    
    cliftonStrengths.forEach(function(cliftonStrengthId) {
        if (!keyStrengths.includes(cliftonStrengthId)) 
            keyStrengths.push(cliftonStrengthId);
    });
    
    (role.mcb_TertiaryQualifications|| []).forEach(function({mcb_qualificationid}) {
        const qualification = qualificationData.find(q => q.mcb_qualificationid === mcb_qualificationid);
        if (qualification) tertiaryQualifications.push(qualification.mcb_qualification);
    });

    return {
        reportingTo: role.mcb_reportingto,
        baseRemuneration: role.mcb_baserenumeration,
        atRiskRemuneration: role.mcb_atriskrenumeration + (role.mcb_renumerationtype === 861400000? " per hour": ""),
        interestedSector: role[formattedValue("mcb_interestedsectors")],
        notInterestedSector: role[formattedValue("mcb_notinterestedsectors")],
        interestedCompany: role.mcb_interestedcompany,
        notInterestedCompany: role.mcb_notinterestedcompany,
        generalComments: role.mcb_generalcomments,
        roleTitle: role.mcb_roletitle,
        companyName: role.mcb_companyname_,
        createdon,
        responsibilities,
        keySkills,
        keyStrengths,
        tertiaryQualifications
    };
}

/**
 * 
 * @param {Array<object>} abcs 
 */
function formatABCData(abcs, skillData){
    let achievements = {};
    let skills = {};
    let strengths = {};
    abcs.forEach(function(abc) {
        let achievementData = {
            achievement: abc.mcb_achievementstatement,
            skills: [],
            strengths: []
        };

        if(Array.isArray(abc.mcb_ABCSkill)) {
            abc.mcb_ABCSkill.forEach(function({ mcb_skillid}) {
                const skill = skillData.find(skill => skill.mcb_skillid === mcb_skillid);
                if (skill) {                
                    achievementData.skills.push(mcb_skillid);

                    if(typeof skills[mcb_skillid] !== "object") {
                        skills[mcb_skillid] = {
                            name: skill.mcb_skill,
                            count: 1
                        };
                    } else {
                        skills[mcb_skillid].count++;
                
                    }
                }
            });
        }
        const strengthNames = abc[formattedValue("mcb_cliftonstrengths")]?.split(",") || "";
        abc.mcb_cliftonstrengths.split(",").forEach((strengthId, idx) => {
            let strengthName = strengthNames[idx]?.trim();
            if (strengthName) {
                achievementData.strengths.push(strengthId);
                if(typeof strengths[strengthId] !== "object") {
                    strengths[strengthId] = {
                        name: strengthName,
                        count: 1
                    };
                } else {
                    strengths[strengthId].count++;
                }
            }
        });
        achievements[abc.mcb_abcid] = achievementData;
    });
    return { achievements, skills, strengths }
}

export function formatData(roleData, responsibilityData, qualificationData, abcData, skillData) {
    const result = Object.assign({},
        formatRoleData(roleData, responsibilityData, qualificationData), 
        formatABCData(abcData, skillData)
    );
    const responsibilityIds = Object.keys(result.responsibilities);
    const achievementIds = Object.keys(result.achievements);
    
    // converts keySkills and keyStrengths to count sorted array of { id, name } 
    const keyDatas = [ ["keySkills", "skills"], ["keyStrengths", "strengths"]].map(function(idxs) {
        const keyData = result[idxs[0]] || [], descData = result[idxs[1]];
        const sortedData = [];
        const countData = [];
        keyData.forEach(function(id) {
            let desc = descData[id];
            if (desc) {
                const { name, count } = desc;
                const entry = { id, name };
                const idx = countData.findIndex((data) => data.count < count);
                if (idx === -1) {
                    sortedData.push(entry);
                    countData.push({ count, data: entry });
                } else {
                    sortedData.splice(idx, 0, entry);
                    countData.splice(idx, 0, { count, data: entry });
                }
            }
        });
        return sortedData;
    });

    const keySkills = keyDatas[0],
        keyStrengths = keyDatas[1];

    const achievements = achievementIds.map(function(achievementId) {
        const achievement = result.achievements[achievementId];
        let score = 0;
        responsibilityIds.forEach((rid ) => {
            const {abcs} = result.responsibilities[rid];
            if (abcs.includes(achievementId)) {
                score += 1;
            }
        });

        keyStrengths.forEach(function(keyData) {
            if (achievement.strengths.includes(keyData.id)) {
                score += 1.5;
            }
        });

        keySkills.forEach(function(keySkill) {
            if (achievement.skills.includes(keySkill.id)) {
                score += 1.5;
            }
        });
        
        result.achievements[achievementId] = achievement;
        return Object.assign(achievement, { achievementId, score });
    }).sort(function(a, b) {
        return b.score - a.score;
    });

    const responsibilities = responsibilityIds.map(function(responsibilityId) {
        const responsibility = result.responsibilities[responsibilityId];
        const respAchievements = [];
        responsibility.abcs.forEach((mcb_abcid) => {
            const ach = achievements.find(({achievementId}) => achievementId === mcb_abcid);
            if (ach) respAchievements.push(ach);
        });
        return Object.assign(responsibility, {
            responsibilityId,
            achievements: respAchievements
        });
    }).sort(function(b, a) {
        const scores = [a, b].map(function(resp) {
            return resp.achievements.reduce(function(prevVal, currVal) {
                if (typeof prevVal === "object") return (currVal.score || 0) + (prevVal.score || 0);
                else if (typeof prevVal === "number") return (currVal.score || 0) + (prevVal || 0);
                return  (currVal.score || 0);
            }, 0);
        });
        return scores[1] - scores[0];
    });
    return Object.assign({}, result, {
        achievements, responsibilities,
        keySkills, keyStrengths
    });

}

