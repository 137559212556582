import React from 'react';
import useProfile from '../Provider/TableContext/ProfileContext';
import IdealRoleEditPanel from './IdealRoleEditPanel';
import IdealRoleBanner from './IdealRoleBanner';
import useRole from '../Provider/TableContext/RoleContext';
import RoleStatus from '../Constants/RoleStatus.json';
import { addClassNames } from '../Functions/Helper/Component';
import LoadingSpinner from './Generic/LoadingSpinner';
import { useLocation } from 'react-router';

export default function IdealRolePanel({ className, ...props}) {
    const { loading: profileLoading } = useProfile();
    const { roles, loading: roleLoading } = useRole();
    const location = useLocation();
    React.useEffect(() => {
        if (!roleLoading && location.hash === "#ideal-job") {
            document.getElementById("ideal-job")?.scrollIntoView({ behavior: "smooth" });
        } 
    }, [roleLoading, location.hash]);
    const idealRole = React.useMemo(() => {
        return roles?.find(role => role.mcb_rolestatus === RoleStatus.Ideal);
    }, [roles]);

    if (profileLoading || roleLoading) return <LoadingSpinner/>;
    if (idealRole) {
        return (
            <IdealRoleEditPanel
                className={addClassNames(className, "w-100")}
                role={idealRole}
                {...props}
            />
        );
    } else {
        return <IdealRoleBanner className={addClassNames(className, "w-100")} {...props}/>;
    }
}