import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProvideAll from './Provider';
import LoadingSpinner from "./Components/Generic/LoadingSpinner";
import {
  Api, SignIn, ForgotPassword,
  Home, Profile, IdealRole,
  ApplyingRoleSetup, Achievements,
  JobApplications,
  AccountFrameLayout,
  AccountSettings,
  Invitation, CVs,
  CVSetup
} from './Routes';
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import './App.scss';
import { initializePDFJS } from './Functions/ReactPDF';


function App() {
  if (/^\/(?:SignIn|Register|Account\/Login\/(?:ForgotPassword|Register|LogOff)|web-api)/gi.test(window.location.pathname))
    return <Api/>;
  else if (/^\/en-US\/Account\/Manage\/(?:SetPassword|ChangeEmail|ConfirmEmail)/gi.test(window.location.pathname))
    return <AccountFrameLayout />;
  else if (/^\/Account\/Login\/(?:ResetPassword|ResetPasswordConfirmation)/gi.test(window.location.pathname)) 
    return <AccountFrameLayout title="Create a new Password" link="/sign-in"/>;
  else if (/^\/en-US\/Account\/LogIn\/ConfirmEmail/gi.test(window.location.pathname)) 
    return <AccountFrameLayout title="Email Confirmed" redirect="/account-settings"/>;
  else {
    initializePDFJS();
    return (
      <div className="App">
        <ProvideAll>
          <Routes>
            <Route path="sign-out" element={<LoadingSpinner fullScreen/>}/>
            <Route path="sign-in" element={<SignIn/>}/>
            <Route path="invitation" element={<Invitation/>}/>
            <Route path="forgot-password" element={<ForgotPassword/>}/>
            <Route path="profile" element={<Profile/>}>
              <Route path=":step" element={<Profile/>}/>
            </Route>
            <Route path="achievements" element={<Achievements/>}/>
            <Route path="account-settings" element={<AccountSettings/>}/>
            <Route path="ideal-job" element={<IdealRole/>}>
              <Route path=":step" element={<IdealRole/>}/>
            </Route>
            <Route path="applying-job-setup" element={<ApplyingRoleSetup/>}>
              <Route path=":step" element={<ApplyingRoleSetup/>}>
                <Route path=":id" element={<ApplyingRoleSetup/>}/>
              </Route>
            </Route>
            <Route path="cv-setup" element={<CVSetup/>}>
              <Route path=":id" element={<CVSetup/>}/>
            </Route>
            <Route path="job-applications" element={<JobApplications/>}>
              <Route path=":id" element={<JobApplications/>}/>
            </Route>
            <Route path="cvs" element={<CVs/>}/>
            <Route path="*" element={<Home/>}/>
          </Routes>
        </ProvideAll>
      </div>
    );
  }
}

export default App;
