import React, { useState } from 'react';
import { Link } from '../Components/Generic/Link';
import { usePortalApi } from '../Provider/PortalApiContext';
import { ArrowLeft as LeftArrow, EnvelopeAtFill as EmailIcon } from '../Components/Generic/Icons';
import Input from '../Components/Generic/Input';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const portalApi = usePortalApi();

    const handleEmailChange = (value) => {
        setEmail(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var result = await portalApi.forgotPassword({email});
        if (result.success) {
            setSuccessMessage(result.message);
            setEmail("");
        }
    };

    return (
        <div className='auth-page'>
            <h2 className='h4 my-2'>Send  Recovery Email</h2>
            {portalApi?.validationMessage && <ul className="validation-message"> 
                {portalApi.validationMessage.split("\n").map((m, i) => (
                    <li key={i}>{m}</li>
                ))}
            </ul>}
            {successMessage? <div className="success-message">{successMessage}</div> : null}
            <form onSubmit={handleSubmit}>
                <Input
                    label="Email"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    className="my-2"
                    type="email"
                />
                <div className="Links">
                    <button className="btn btn-link-color" type="submit"><EmailIcon className="icon"/> Send Mail</button>
                    <Link to="/sign-in"><LeftArrow/> Sign In</Link>
                </div>

            </form>
        </div>
    );
};

export default ForgotPassword;
