import React from 'react';
import useABC from '../Provider/TableContext/ABCContext';
import useResponsibility from '../Provider/TableContext/ResponsibilityContext';
import useCV from '../Provider/TableContext/CVContext';
import useProfile from '../Provider/TableContext/ProfileContext';
import Sectors from '../Constants/Sector.json';
import useQualification from '../Provider/TableContext/QualificationContext';
import RoleStatus from  '../Constants/RoleStatus.json';
import { UserReadableError } from '../Functions/Error';
import useRole from '../Provider/TableContext/RoleContext';
import { stripHtml } from '../Functions/Helper/RichText';
import useSkill from '../Provider/TableContext/SkillContext';
import useCVSkillAchievement from '../Provider/TableContext/CVSkillAchievementContext';

export default function useCreatedRoleContext({ role, updateRole, roleStatus, roleStatusName, cvName }) {
    const { cvs, processCVChanges, uploadFileToCV, loading: cvLoading } = useCV();
    const { profileData } = useProfile();
    const { responsibilities, processResponsibilityChanges, loading: responsibilityLoading } = useResponsibility();
    const { roles, loading: roleLoading } = useRole();
    const { qualifications } = useQualification();
    const { skills, loading: skillLoading } = useSkill();
    const { abcs, loading: abcLoading } = useABC();
    const [formData, setFormData] = React.useState({});
    const { cvskillachievements, processCVSkillAchievementChanges } = useCVSkillAchievement();
    const [loading, setLoading] = React.useState(!role || responsibilityLoading || abcLoading || cvLoading);
    const setCVBytes = React.useCallback((bytes) => {
        setFormData(oFD => {
            return {
                ...oFD,
                cvDOCX: bytes
            };
        });
    }, []);
    
    React.useEffect(() => {
        if (!(!role || roleLoading || responsibilityLoading || abcLoading || skillLoading || cvLoading)) {
            
            role.cliftonstrengths = (role?.mcb_cliftonstrength?.split(",")|| []).filter(a => a && !isNaN(a)).map(a => Number(a));
            role.qualifications = (role?.mcb_TertiaryQualifications?.map(({mcb_qualificationid}) => mcb_qualificationid) || []);
            
            const skillCounter = {};
            const potentialSkillsAchievements = {};

            role.requirements = responsibilities.filter(responsibility => {
                return responsibility?._mcb_role_value === role?.mcb_roleid;
            })?.map(({mcb_ABCResponsibility, ...responsibility}) => ({
                ...responsibility,
                achievements: (mcb_ABCResponsibility?.map(({ mcb_abcid }) => {
                    let abc = abcs?.find(({ mcb_abcid: abcId }) => abcId === mcb_abcid);
                    abc.mcb_ABCSkill?.forEach(({mcb_skillid}) => {
                        skillCounter[mcb_skillid] = (skillCounter[mcb_skillid] || 0) + 1;
                        // if this is a keySkill
                        if (role?.mcb_KeySkills.some((ks) => ks.mcb_skillid === mcb_skillid)) {
                            if (!potentialSkillsAchievements[mcb_skillid]) potentialSkillsAchievements[mcb_skillid] = [];
                            const potentialSkillAchievements =  potentialSkillsAchievements[mcb_skillid];
                            if (!potentialSkillAchievements.some(a => a.mcb_abcid === abc.mcb_abcid)) {
                                potentialSkillAchievements.push(abc);
                            }
                        }
                    });
                    return abc;
                }).filter(a => a) || [])
            })).sort((a,b) => (a.mcb_index || 0) - (b.mcb_index || 0));
            

            role.skills = (role?.mcb_KeySkills || [])
                .map(({mcb_skillid}) => mcb_skillid)
                .sort((a, b) => {
                    return (skillCounter[b] || 0) - (skillCounter[a] || 0);
                });

            const cvId = role?._mcb_cv_value || formData.CV;
            let cv  = cvs?.find(cv => cv?.mcb_cvid === cvId);
            if (cv) {
                cv = {...cv, keySkillsAchievements : cvskillachievements.filter(a =>
                    a._mcb_cv_value === cv?.mcb_cvid).sort((a,b) => (a?.mcb_index || 0) - (b.mcb_index||0)
                ) };
                if (formData.Duplicate) {
                    cv.mcb_cvid = undefined;
                    cv.mcb_cvname = cv.mcb_cvname + " - Copy";
                }
            } 
            if (!cv || formData.Duplicate) {
                const mcb_CVRoles = [];
                const cvRoles = [];
                roles.filter(({mcb_rolestatus}) => 
                    mcb_rolestatus === RoleStatus.Current || mcb_rolestatus === RoleStatus.Previous
                ).forEach(({mcb_roleid}) => {
                    mcb_CVRoles.push({ mcb_roleid });
                    cvRoles.push(mcb_roleid);
                });
                const cvMainQualifications = qualifications
                    .filter(({mcb_qualificationid}) => 
                        role?.mcb_TertiaryQualifications?.some(({mcb_qualificationid: qid}) => 
                            qid === mcb_qualificationid
                        )
                    ).sort((a,b) => new Date(b.mcb_dateearned) - new Date(a.mcb_dateearned))
                    .map(({mcb_abbreviation}) => mcb_abbreviation).join(", ");
    
                let keySkillsAchievements = [];
                const bookedAchievements = [];
                // handle skills with the least amount of achievements first...
                Object.entries(potentialSkillsAchievements).sort(([,a], [,b]) => a.length - b.length)
                    .forEach(([mcb_skillid, abcs]) => {
                        for (let c = 0; c < abcs.length; c++) {
                            const abc = abcs[c];
                            if (!bookedAchievements.includes(abc.mcb_abcid)) {
                                bookedAchievements.push(abc.mcb_abcid);
                                const abcData = stripHtml(abc.mcb_achievementstatement);
                                const skillName = skills.find(a => a.mcb_skillid === mcb_skillid)?.mcb_skill;
                                const mcb_fullstatement = `<p><strong>${skillName}: </strong><span>${abcData}</span></p>`;
                                const mcb_statement = `${skillName}: ${abcData}`.slice(0,100);
                                keySkillsAchievements.push({
                                    mcb_fullstatement,
                                    mcb_statement,
                                    _mcb_abc_value: abc.mcb_abcid,
                                    _mcb_skill_value: mcb_skillid,
                                    mcb_index: skillCounter[mcb_skillid] || 0
                                });
                                return;
                            }
                        }
                        if (bookedAchievements[mcb_skillid]) return;
                        
                    });

                if (cv) {
                    keySkillsAchievements = [
                        ...keySkillsAchievements,
                        ...(cv?.keySkillsAchievements?.filter(kSA => (
                            keySkillsAchievements 
                            && !keySkillsAchievements.some(
                                a => a._mcb_abc_value === kSA._mcb_abc_value && a._mcb_skill_value === kSA._mcb_skill_value
                            )
                        )).map(({
                            mcb_fullstatement,
                            mcb_statement,
                            _mcb_abc_value,
                            _mcb_skill_value,
                            mcb_index
                        }) => ({
                            mcb_fullstatement,
                            mcb_statement,
                            _mcb_abc_value,
                            _mcb_skill_value,
                            mcb_index: keySkillsAchievements.length + mcb_index
                        })) || [])
                    ];
                }
                  
                keySkillsAchievements = keySkillsAchievements.sort((a,b) => b.mcb_index - a.mcb_index)
                    .map((a, mcb_index) => ({...a, mcb_index}));
                cv =   {
                    ...(
                        cv || {
                        mcb_cvname: cvName,
                        mcb_CVABC: abcs.map(({mcb_abcid}) => ({ mcb_abcid })) || [],
                        mcb_CVRoles,
                        cvRoles,
                        mcb_CVQualifications: role.mcb_TertiaryQualifications,
                        mcb_CVKeySkill: role.mcb_KeySkills,
                        mcb_cliftonstrength: role.mcb_cliftonstrength
                    }),
                    mcb_roletitle: role?.mcb_roletitle,
                    mcb_rolecompanyname: role?.mcb_rolecompanyname,
                    mcb_firstname: profileData?.firstname,
                    mcb_lastname: profileData?.lastname,
                    mcb_email: profileData?.emailaddress1,
                    mcb_phone: profileData?.telephone1,
                    mcb_title: role?.mcb_roletitle,
                    mcb_mainqualification: cvMainQualifications,
                    mcb_profilesummary: profileData?.adx_publicprofilecopy,
                    mcb_currentcity: profileData?.address1_city,
                    mcb_linkedin: profileData?.mcb_linkedin,
                    mcb_sectors: profileData?.sectors
                        .map(sector => Sectors.find(({value}) => value === sector)?.label || false)
                        .filter(a => a).join(", "),
                    keySkillsAchievements
                    
                };
            }
            
            setFormData((oldFormData) => ({
                ...role,
                ...oldFormData,
                cv: {
                    ...cv,
                    skills: (cv?.mcb_CVKeySkill?.map(({mcb_skillid}) => mcb_skillid) || []),
                    cliftonstrengths: (cv?.mcb_cliftonstrength?.split(",")|| []).filter(a => a && !isNaN(a)).map(a => Number(a)),
                    achievements: (cv?.mcb_CVABC?.map(({mcb_abcid}) => mcb_abcid) || []),
                    qualifications: (cv?.mcb_CVQualifications?.map(({mcb_qualificationid}) => mcb_qualificationid) || []),
                    cvRoles: (cv?.mcb_CVRoles?.map(({mcb_roleid}) => mcb_roleid) || [])
                },
                CV: oldFormData.CV || cv.mcb_cvid || "new-cv",
                Duplicate: oldFormData?.Duplicate,
            }));
            setLoading(false);
        }

    }, [
        cvName, role, profileData, formData.CV, formData.Duplicate,
        roles, skills, qualifications, responsibilities, abcs, cvs, cvskillachievements,
        roleLoading, abcLoading, responsibilityLoading, cvLoading, skillLoading
    ]);

    const updateRequirements = React.useCallback(async () => {
        if (!formData?.mcb_roleid) throw new Error(`Unknown ${roleStatusName} Role`);

        const formRequirements = (formData || {})?.requirements;

        if (!(Array.isArray(formRequirements) && formRequirements?.length)) return false;
        
        setLoading(true);
        const responsibilityChanges = formRequirements.map(({achievements, ...resp}, mcb_index) => {
            return {
                ...resp,
                _mcb_role_value: formData?.mcb_roleid,
                mcb_index,
                mcb_ABCResponsibility: achievements
            };
        });
        responsibilities.forEach(({ _mcb_role_value, mcb_responsibilityid}) => {
            if (
                _mcb_role_value === formData?.mcb_roleid
                && formRequirements.every((fr) => fr.mcb_responsibilityid !== mcb_responsibilityid)
            ) {
                responsibilityChanges.push({mcb_responsibilityid, deleted: true});
            }
        });

        const role = {mcb_roleid: formData?.mcb_roleid, mcb_rolestatus: roleStatus};
        const mcb_KeySkills= (formData?.skills || []).map((mcb_skillid) => ({ mcb_skillid }));
        const mcb_TertiaryQualifications = (formData?.qualifications || []).map((mcb_qualificationid) => ({ mcb_qualificationid }));
        const mcb_cliftonstrength = (formData?.cliftonstrengths || [])?.join(",");

        if (mcb_KeySkills?.length) role.mcb_KeySkills = mcb_KeySkills;
        if (mcb_cliftonstrength.length) role.mcb_cliftonstrength = mcb_cliftonstrength;
        if (mcb_TertiaryQualifications?.length) role.mcb_TertiaryQualifications = mcb_TertiaryQualifications;

        await Promise.all([
            processResponsibilityChanges(responsibilityChanges),
            updateRole(role)
        ]);

        setLoading(false);
        return true;
    }, [formData, responsibilities, processResponsibilityChanges, updateRole, roleStatus, roleStatusName]);

    const updateCV = React.useCallback(async() => {
        if (!formData?.mcb_roleid) throw UserReadableError(`Unknown ${roleStatusName} Job`);

        const formCV = (formData || {})?.cv || {};
        const cvDocx = (formData || {})?.cvDOCX;
        if (!cvDocx) throw UserReadableError(`Please Generate a CV by Selecting the CV Template on the Right Side of the Page`);
        const keySkillsAchievements = formCV?.keySkillsAchievements || [];
        setLoading(true);
        try {
            
            formCV.mcb_CVRoles = formCV.cvRoles.map(mcb_roleid => ({ mcb_roleid }));
            formCV.mcb_CVABC = formCV.achievements?.map((mcb_abcid) => ({ mcb_abcid }));

            formCV.mcb_CVKeySkill = formCV.skills?.map((mcb_skillid) => ({ mcb_skillid }));
            formCV.mcb_cliftonstrength = (formCV.cliftonstrengths || []).join(",");
            if (!formCV.mcb_cliftonstrength) delete formCV.mcb_cliftonstrength;
            formCV.mcb_CVQualifications = formCV.qualifications?.map((mcb_qualificationid) => ({ mcb_qualificationid }));
            const cvName = formCV.mcb_cvname || ["CV", formCV.mcb_roletitle, formCV.mcb_rolecompanyname].filter(a => a).join(" ");
            formCV.mcb_cvname = cvName;
            
            const changes = [
                formCV,
                ...(cvs || []).filter(cv =>
                    cv?._mcb_applyingrole_value === formData?.mcb_roleid 
                    && cv?.mcb_cvid === formCV?.mcb_cvid
                ).map(({mcb_cvid}) => ({mcb_cvid, deleted: true}))
            ];

            const cvData = await ((await processCVChanges(changes))[0]);
            const cvId = cvData?.mcb_cvid;
            const promises = [];
            if (cvDocx && cvId) {
                promises.push(uploadFileToCV(cvId, "mcb_cvdocument", cvDocx, (cvName || "CV") + ".docx"));
            }
            if (cvId && keySkillsAchievements.length) {
                promises.push(processCVSkillAchievementChanges(keySkillsAchievements.map((skillAchievement, mcb_index) => ({
                    ...skillAchievement, mcb_index, _mcb_cv_value: cvId
                }))));
            }
            if (formData.mcb_roleid && cvId !== formData._mcb_cv_value) {
                promises.push(updateRole({ mcb_roleid: formData.mcb_roleid, _mcb_cv_value: cvId }));
            }

            await Promise.all(promises);
            return true;
        } catch (err) {
            throw err;
        } finally {
            setLoading(false);
        }
    }, [formData, cvs, processCVChanges, uploadFileToCV, updateRole, processCVSkillAchievementChanges, roleStatusName]);

    return { formData, setFormData, setCVBytes, loading, setLoading, updateRequirements, updateCV };
}