
export default async function RegisterUserActionHandler(json) {
    if(/^\/account\/login\/register/i.test(window.location.pathname)) {
        try {
            document.getElementById("EmailTextBox").value = json.email;
            const userName = document.getElementById("UserNameTextBox");
            if (userName) userName.value = json.userName;
            document.getElementById("PasswordTextBox").value = json.password;
            document.getElementById("ConfirmPasswordTextBox").value = json.confirmPassword;

            setTimeout(() => {
                document.getElementById("SubmitButton").click();
            }, 100);
            return {success: true};
        } catch (err) {
            return {success: false, error: err};
        }
    } else {
        return {success: false, error: new Error("Not on register page.")};
    }
}

