import React from 'react';
import Input from './Input';

export function StateInput({state, setState, alias, type, label, props:__props}) {
    const { onChange: _onChange, onChangeOverride, ..._props } = __props || {};
    // for responsive input
    const [stateCacheValue, setStateCacheValue] = React.useState(state[alias]);

    React.useEffect(() => {
        setStateCacheValue(state[alias]);
    }, [state, alias]);

    React.useEffect(() => {
        if (state[alias] !== stateCacheValue) {
            const timeout = setTimeout(() => {
                if (typeof onChangeOverride === "function") {
                    onChangeOverride(stateCacheValue, alias);
                } else {
                    setState((_state) => {
                        return ({
                            ..._state,
                            [alias]: stateCacheValue
                        });
                    });
                    if (typeof(_onChange) === "function") {
                        _onChange(stateCacheValue, alias);
                    }
                }

            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [_onChange, onChangeOverride, stateCacheValue, alias, setState, state]);

    // const onChange = React.useCallback((value) => {

    //     if (typeof onChangeOverride === "function") {
    //         onChangeOverride(value, alias);
    //     } else {
    //         setState((_state) => {
    //             return ({
    //                 ..._state,
    //                 [alias]: typeof value === "function" ? value(_state[alias]) : value
    //             });
    //         });
    //         if(typeof(_onChange) === "function") {
    //             _onChange(value, alias);
    //         }
    //     }
    // }, [setState, _onChange, onChangeOverride, alias]);

    const props = {
        ..._props,
        label: label,
        value: stateCacheValue || "",
        onChange: setStateCacheValue
        
    };
    
    if (typeof type === "function") {
        const TypeComponent = type;
        return <TypeComponent key={alias} {...props}/>;
    } else {
        return (
            <Input {...props} key={alias} type={type} />
        );
    }

}

export function SingleInputColumn({state, setState, inputs}) {
    return inputs.map(([alias, type, label, props]) => {
        return (
            <StateInput key={alias} {...{state, setState, alias, type, label, props}} />
        );
    });
}

export function TwoInputColumns(props) {
    const inputs = SingleInputColumn(props);
    const children = [];
    for (let c = 0; c < inputs.length; c += 2) {
        children.push(<div key={c} className="d-flex flex-row flex-wrap flex-md-nowrap gap-3">
            {inputs[c]}
            {inputs[c + 1]}
        </div>);
    }
    return (
        <>{children}</>
    );
}
