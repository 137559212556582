function calcScreenDPI() {
    const el = document.createElement('div');
    el.style = 'width: 1in;'
    document.body.appendChild(el);
    const dpi = el.offsetWidth;
    document.body.removeChild(el);

    return dpi;
}

function destylize(htmlDOM) {
    [
        "display",
        "padding",
        "margin",
        "fontSize",
        "lineHeight",
        "wordBreak",
        "wordWrap",
        "color",
        "backgroundColor",
        "border",
        "borderRadius",
    ].forEach(function(styleName) {
        htmlDOM.style[styleName] = "inherit!important";
    });    
}

/**
 * 
 * @param  {string|[RegExp, string]} entriesDelimiter
 * @param  {string|[RegExp, string]} kvDelimiter 
 * @param  {...string} entries 
 */
export function mergeEntriesLiterals(entriesDelimiter, kvDelimiter, ...entries) {
    const result = {};
    function getSplitter(delimiter) {
        if (Array.isArray(delimiter)) return delimiter[0];
        return delimiter;
    }
    function getJoiner(delimiter) {
        if (Array.isArray(delimiter)) return delimiter[1];
        return delimiter;
    }

    entries.forEach(function(entry) {
        if (!entry) return;
        entry.split(getSplitter(entriesDelimiter)).forEach(function(eachEntry) {
            if (!eachEntry) return;
            const kv = eachEntry.split(getSplitter(kvDelimiter));
            if (!kv[0]) return;
            else if (result[kv[0]]) {
                result[kv[0]] = undefined;
                delete result[kv[0]];
            }
            
            if (kv[1]) result[kv[0].trim()] = kv[1].trim();
            
        });
    });

    return Object.entries(result).map(function(kv) {
        return kv.join(getJoiner(kvDelimiter));
    }).join(getJoiner(entriesDelimiter));
}

/**
 * 
 * @param  {...string} styles 
 */
export function mergeStyles(...styles) {
    return mergeEntriesLiterals(";", ":", ...styles);
}

/**
 * 
 * @param  {...string} options 
 */
export function mergeOptions(...options) {
    return mergeEntriesLiterals([/\s+/, " "], "=", ...options);
}

export function encloseHTML(tag, html, style = "", options = "") {
    let result =  "<" + tag;
    if (style) {
        result += " style=\"" + style + "\" ";
    }
    if (options) {
        result += " " + options;
    }
    result += ">" + (html || "") + "</"+tag+">";

    return result;
}

export function aHTML(href, html, style = "") {
    return encloseHTML("a", html, style, "href=\"" + href + "\"")
}

export function divHTML(html, style = "") {
    return encloseHTML("div", html, style, "")
}

export function emHTML(html) {
    return encloseHTML("em", html);
}

export function strongHTML(html) {
    return encloseHTML("strong", html);
}

/**
 * 
 * @param {string} html 
 * @param  {{style: string, options: string}} opt
 * @returns 
 */
function tdHTML(html, opt) {
    try {
        if (html.charAt(0) !== "<" || html.charAt(html.length - 1) !== ">") html = spanHTML(html);
    } catch(err) {
        // console.log(html);
        // console.warn(err);
    }
    return encloseHTML("td", html, opt.style, opt.options);
}

/**
 * 
 * @param  {{style: string, options: string, override: boolean }} opt
 * @param  {...string|[string, {style: string, options: string}]} tdDatas 
 * @returns 
 */
export function trHTML(opt, ...tdDatas) {
    opt = opt || {};
    let html = tdDatas.map(function(tdData) {
        let tdOpt = Object.assign({}, opt);
        
        if (Array.isArray(tdData)) {
            if (tdData[1]) {
                if (tdData[1].options)
                    tdOpt.options = mergeOptions(tdOpt.options, tdData[1].options);
                if (tdData[1].style)
                    tdOpt.style = mergeStyles(tdOpt.style, tdData[1].style);
            }

            return tdHTML(tdData[0], tdOpt);
        } else if (typeof tdData === "string"){
            if (opt.override) {
                return tdData;
            } else {
                return tdHTML(tdData, tdOpt);
            }
        }return false;
    }).filter(data => data !== false).join("");
    return encloseHTML("tr", html, opt.style, opt.options);
}


/**
 * 
 *  @param  {{style?: string, options?: string}} opt
 *  @param  {...string|[
 *      string|Array<string|[
 *          string,
 *          {style: string, options: string}
 *      ]>, {
 *          style?: string,
 *          options?: string,
 *          override?: boolean
 *      }
 *  ]} trDatas
 * @returns string
 */
export function tableHTML(opt, ...trDatas) {
    opt = opt || {};
    let html = trDatas.map(function(trData) {
        if (typeof trData === "string") {
            return trData;
        } else if (Array.isArray(trData)){
            let trOPT = trData[1] || {};
            if (typeof(trData[0]) === "string") {
                return trHTML(trOPT, trData[0]);
            } else if (Array.isArray(trData[0])) {
                return trHTML(trOPT, ...trData[0]);
            }
            
        } 
        return false;
    }).filter(data => data !== false).join("");
    return encloseHTML(
        "table", html,
        opt.style || "font-size:10pt; width:100%",
        opt.options || "cellspacing=\"0\" cellpadding=\"1\" border=\"1\""
    );
}

export function liHTML(html) {
    return encloseHTML("li", html);
}

export function ulHTML(html) {
    return encloseHTML("ul", html);
}

export const styleHTML = "<style>"
    + "h1,h2,h3,h4{font-family:'Calibri Light'!important;color:#2e74b5!important}"
    + "h1{font-size:16pt!important}h3{font-size:12pt!important}"
    + "pre{font-family:inherit!important;font-size:inherit!important}"
    + "td{padding-left:1rem}"
    + "</style>"

export function h1HTML(html) {
    return encloseHTML(
        "h1", html
    );
}

export function h3HTML(html) {
    return encloseHTML(
        "h3", html
    );
}

export function preHTML(html) {
    return encloseHTML("pre", html);
}

export function spanHTML(html, style = "", options = "") {
    return encloseHTML(
        "span", html || "",
        style, options
    );
}

export function representiveHTML(representingHTML, docxHTML, tagName = "span",style) {
    return encloseHTML(
        tagName,
        representingHTML,
        "white-space:pre-wrap!important" + (style? ";" + style : ""),
        "data-docx-content=\"" +  encodeURIComponent(docxHTML) + "\""
    );
}

export function htHTML(colDat, representingHTML) {
    let count, htWidth;
    if (Array.isArray(colDat)) {
        count = colDat[0];
        htWidth = colDat[1];
    } else {
        count = colDat
    }
    let result = "<span class=\"docx-tab\" data-count=\"" + count + "\""
    if (htWidth) {
        result += " data-ht-width=\"" + htWidth + "\""
    }
    result  += ">";
    
    if (representingHTML) {
        result += representingHTML;
    } else {
        for(let c = 0; c < count; c++) result += "&#9;";
    }
    
    result += "</span>";
    return result;
}

export const newlineHTML =  "<br/>";

export function normalizeRichText(richText) {
    let calcDiv = document.createElement("div");
    calcDiv.innerHTML = richText;

    if (calcDiv.children.length && calcDiv.children[0].innerHTML)
        return calcDiv.children[0].innerHTML;
    else return richText; 
}

export function formatDate(date) {
    const d = new Date(date);
    //*
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    /*/
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    //*/
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();
    
    return month + " " + year;
}


/**
 * Legacy Calculation: using tabStops now
 * Calculate how much tabs are required in each data to align column
 * @param  {...Array<String>} columns the data is column aligned for ease of calculation
 */
export function calculateAlignment(...columns) {
    const calcPRE = document.body.appendChild(
        document.createElement("pre")
    );

    destylize(calcPRE);
    calcPRE.style.fontFamily = "Calibri";
    calcPRE.style.fontSize = "11pt";
    
    let eachHTWidth = 0.5 * calcScreenDPI(); // Tabs are Aproximately 0.5inch
    let totalHTCount = 0;

    let processedColumns = columns.map(function(column) {
        let maxOffsetWidth = 0;
        let columns = column.map(function(html) {
            let span = calcPRE.appendChild(document.createElement("span"));
            span.innerHTML = html;
            if (span.offsetWidth > maxOffsetWidth ) {
                maxOffsetWidth  = span.offsetWidth;
            }
            return span;
        });

        let maxHTCount = Math.ceil(maxOffsetWidth/eachHTWidth);
        totalHTCount += maxHTCount;
        
        return {maxHTCount, columns};
    });
    
    const lastColIndex = columns.length - 1;
    let additionalExpands = 0, firstColExpand = 0;
    if (totalHTCount < 12) {
        // divide the remaining to every row other then the last
        additionalExpands =  (12 - totalHTCount) / lastColIndex;
        additionalExpands = Math.floor(additionalExpands);
        
        // put remaining expand to first col
        firstColExpand = 12 - totalHTCount - (additionalExpands * (lastColIndex - 1));
    }

    let tabCountCOLs = processedColumns.map(function(processedColumn, idx) {
        let maxHTCount = processedColumn.maxHTCount,
        columns = processedColumn.columns;
        if (idx) {
            maxHTCount += additionalExpands;
        } else {
            maxHTCount += firstColExpand;
        }

        return columns.map(function(colSpan) {
            if (idx === lastColIndex) return 0; // no tabs for last col
            let currentHTCount = Math.floor(colSpan.offsetWidth / eachHTWidth);
            return [maxHTCount - currentHTCount, maxHTCount];
        });

    })
    

    document.body.removeChild(calcPRE);

    return tabCountCOLs;
}

/**
 * Align the columns with appropriate tabs. Each argument represent a column in the table.
 * column is used instead of row for ease of calculation
 * @param {...Array<String>} columns the data is column aligned for ease of calculation
 */
export function tabAlignHTML(...columns){
    const calcSpans = document.createElement("span");
    let calculatedTABs = calculateAlignment(...columns);

    return columns.map(function(colHTML, colIDX) {
        return colHTML.map(function(html, dataIDX) { 
            let tabData = calculatedTABs[colIDX][dataIDX];;
            
            calcSpans.innerHTML = html;
            if (calcSpans.children.length) {
                // tabbedSpan = calcSpans;
            } else {
                // Append the html to a span if its not nested
                // as htHTML returns content contained in span
                calcSpans.innerHTML = "";
                calcSpans.appendChild(document.createElement("span"))
                    .innerHTML = html;
            }
            if (colIDX < columns.length - 1) // don't add tabs to the last html 
                calcSpans.innerHTML = calcSpans.innerHTML + htHTML(tabData);
            return calcSpans.innerHTML;
        });
    });
}