import React from 'react';
import useApplyingRoleJourneyContext from './ApplyingRoleContext';
import CVSidePanel from '../../Components/CV/CVSidePanel';
import CVForm from '../../Components/CV/CVForm';

export default function ApplyingRoleDetails(props) {
    const context = useApplyingRoleJourneyContext();
    return <CVForm
        {...props}
        {...context}
        loadingText="Loading Applying Role Data" 
        redirectUrl={() => context?.formData?.mcb_roleid && `/job-applications/${context?.formData?.mcb_roleid}`}
    />;
}

export function SidePanel({className}) {
    const { formData, setFormData, setCVBytes, loading } = useApplyingRoleJourneyContext();
    return (<CVSidePanel {...{className, formData, setFormData, setCVBytes, loading}} />);
}

ApplyingRoleDetails.SideComponent = SidePanel;

ApplyingRoleDetails.stepName = "CV for the Role";