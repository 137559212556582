import React from 'react';
import { useLocation } from 'react-router';
import { Link } from './Link';
import { addClassNames } from '../Functions/Helper/Component';

function LinkToPage({ active, urlParam, page }) {
    return (
        <li className={`page-item ${active ? "active" : active === false? "inactive" : ""}`}>
            <Link
                to={`?${urlParam}`}
                className="page-link unstyled"
            >{page}</Link>
        </li>
    );
}

export default function Pagination({ pageNumber, label, pageCount, center = true }) {
    const {search} = useLocation();
    const newSearchParam = React.useCallback((page) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("i", page);
        return searchParams.toString();
    }, [search]);

    const pageLinks = React.useMemo(() => {
        const links = [];

        links.push(
            <LinkToPage
                key="prev" 
                {...(pageNumber > 0? {
                    urlParam: newSearchParam(pageNumber - 1),
                } : {
                    active: false,
                    urlParam: newSearchParam(0),
                
                })}
                page="Previous"
            />
        );

        for (let i = 0; i < pageCount; i++) {
            if (i > 2) {
                if (i < pageNumber - 2) {
                    i = pageNumber - 2;
                    links.push(<span key="pre-ellipse">...</span>);
                } else if (i > pageNumber + 2 && i < pageCount - 2) {
                    i = pageCount - 3;
                    links.push(<span key="post-ellipse">...</span>);
                }
            } 
            if (pageCount - pageNumber )
            links.push(<LinkToPage key={i} active={i === pageNumber} urlParam={newSearchParam(i)} page={i + 1} />);
        }

        links.push(
            <LinkToPage
                key="nxt"
                {...(pageNumber + 1 < pageCount? {
                    urlParam: newSearchParam(pageNumber + 1),
                } : {
                    active: false,
                     urlParam: newSearchParam(pageCount - 1),
                
                })}
                page="Next"
            />
        );
        return links;
    }, [pageCount, pageNumber, newSearchParam]);
    if (pageCount > 1) return (
        <nav aria-label={label}>
            <ul className={addClassNames("pagination", center && "justify-content-center")}>
                {pageLinks}
            </ul>
        </nav>
    ); else return <></>;

}