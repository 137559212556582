import React from 'react';
import  { RoleProvider } from './RoleContext';
import { ResponsibilityProvider } from './ResponsibilityContext';
import { QualificationProvider } from './QualificationContext';
import { ABCProvider } from './ABCContext';
import { SkillProvider } from './SkillContext';
import { ProfileProvider } from './ProfileContext';
import { CVProvider } from './CVContext';
import { TemplateDocumentProvider } from './TemplateContext';
import { CVSkillAchievementProvider } from './CVSkillAchievementContext';

export default function TableContextProvider({children}) {
    return (
        <ProfileProvider>
        <RoleProvider>
        <ResponsibilityProvider>
        <QualificationProvider>
        <ABCProvider>
        <SkillProvider>
        <CVProvider>
        <TemplateDocumentProvider>
        <CVSkillAchievementProvider>
            {children}
        </CVSkillAchievementProvider>
        </TemplateDocumentProvider>
        </CVProvider>
        </SkillProvider>
        </ABCProvider>
        </QualificationProvider>
        </ResponsibilityProvider>
        </RoleProvider>
        </ProfileProvider>
    );
}