import React from "react";

export default function SelectOptions({ options, value, onChange }) {
    const allSelected = !value?.length || options.every((option) => {
        const optionVal = option?.key || option;
        if (Array.isArray(value)) return value.includes(optionVal);
        return false;
    });

    const result = [ 
        <label>
            <input
                type="checkbox"
                checked={allSelected}
                className="me-2"
                onChange={(e) => {
                    if(e.target.checked) {
                        onChange(options.map(({key}) => key));
                    } else {
                        onChange([]);
                    }
                }}
                
            /> All</label>,
            "divider"
    ];

    options.forEach((option) => {
        const optionVal = option?.key || option;
        const label = option?.label || option;

        result.push(
            <label>
                <input
                    type="checkbox"
                    className="me-2"
                    checked={allSelected || value?.includes(optionVal)}
                    onChange={(e) => {
                        if (allSelected) onChange([optionVal]);
                        else if(e.target.checked) {
                            onChange([...(value||[]), optionVal]);
                        } else {
                            onChange(value?.filter(v => v !== optionVal));
                        }
                    }}
                /> {label}</label>
        );
    });

    return result;
}