import React from 'react';
import useABC from '../../Provider/TableContext/ABCContext';
import useRole from '../../Provider/TableContext/RoleContext';
import useSkill from '../../Provider/TableContext/SkillContext';
import useProfile from '../../Provider/TableContext/ProfileContext';
import { generateABCHTML } from '../../Functions/Generator/ABCGenerator';

export default function useMyABCCompute() {
    const { profileData, updateProfile, loading: profileLoading } = useProfile();
    const { abcs, loading: abcLoading } = useABC();
    const { roles, loading: rolesLoading } = useRole();
    const { skills, loading: skillsLoading } = useSkill();
    
    const loading = React.useMemo(() => abcLoading || rolesLoading || skillsLoading || profileLoading, [abcLoading, rolesLoading, skillsLoading, profileLoading]);

    const value = React.useMemo(() => {
        if (loading) {
            return "Loading...";
        }
        return generateABCHTML(roles, abcs, skills);
    }, [abcs, roles, skills, loading]);

    React.useEffect(() => {
        if (!loading && profileData.mcb_myabcs !== value) {
            updateProfile({ ...profileData, mcb_myabcs: value }, false);
        }
    }, [loading, value, profileData, updateProfile]);

}