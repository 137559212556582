import React from 'react';
import { CloudDownload as DownloadIcon } from './Icons';
import { processRichTextXML, runOnChild } from '../../Functions/Helper/Docx';
import processTemplate from 'docx-templates';
import useTemplateDocument from '../../Provider/TableContext/TemplateContext';
import { addClassNames } from '../../Functions/Helper/Component';



export default function HTMLDocxLinkMaker({ HTML, name, children, rounded = false, rotate = false }) {
    const { getGenericTemplateDocx } = useTemplateDocument();
    const [ loading, setLoading ] = React.useState(false);
    const [ error, setError ] = React.useState(null);
    React.useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError(null);
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [error]);
    const Download = React.useCallback(async() => {
        setLoading(true);
        try {
            const genericTemplateDocx = await getGenericTemplateDocx()
            if (genericTemplateDocx) {
                const ctx = {numberingDetails: [], links: []};
                const HTMLDocx = await processTemplate({
                    template: new Uint8Array(genericTemplateDocx),
                    data: { HTML: HTML },
                    failFast: false,
                    preBuildXML: (xml, documentComponent, reports) => {
                        if (rotate && documentComponent === "main.xml") {
                            runOnChild(xml, (a) => a._tag === "w:pgSz", (pgSz) => {
                                let w = pgSz._attrs['w:w'];
                                pgSz._attrs['w:w'] = pgSz._attrs['w:h'];
                                pgSz._attrs['w:h'] = w;
                            });
                        }
                        return processRichTextXML(ctx, xml, documentComponent, reports);
                    }
                });
                const blob = new Blob([HTMLDocx.buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.style.display = "none";
                a.download = `${name}.docx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setLoading(false);
                return;
            }
        } catch (error) {
            console.error(error);
        }
        setError("Unable to complete the request please try again later.");
        setLoading(false);
    }, [getGenericTemplateDocx, name, rotate, HTML]);
    if (HTML)
        return (
            <button
                className={addClassNames('btn', error? "btn-danger" : "btn-link-color", rounded && 'rounded-pill')}
                disabled={loading || error}
                onClick={Download}
            >{loading? <div className="spinner-border text-primary icon" role="status"/> : <DownloadIcon className="icon"/>}{error || children}</button>
        );
    else return <></>;
}