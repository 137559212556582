import React from 'react';
import Form from './Generic/Form';
import { TrashFill as RemoveIcon } from './Generic/Icons';
import { SingleInputColumn, TwoInputColumns } from './Generic/InputGrouping';
import AchievementsInput from './AchievementsInput';
import { bindOnChange } from '../Functions/Helper/OnChange';
import useRole from '../Provider/TableContext/RoleContext';
import { useLocation } from 'react-router';
import { ResponsibilitiesInput } from './ResponsibilitiesInput';
import useActionDialog from '../Provider/ ActionDialogContext';

function ProfessionalAchievementInput({value, onChange}) {
    const addButtonProps= React.useMemo(() => ({ label: "Add Achievement"}), []);
    const updateButtonProps= React.useMemo(() => ({ label: "Update Achievement"}), []);
    const _onChange = React.useMemo(() => bindOnChange(onChange, "achievements"), [onChange]);
    return (
        <>
            <h3 className="h4 mb-3 mt-4 d-flex justify-content-between">Achievements</h3>
            <AchievementsInput 
                values={value?.achievements || []}
                onChange={_onChange}
                addButtonProps={addButtonProps}
                updateButtonProps={updateButtonProps}
                newButtonLabel={"Add Achievement"}
                defaultNewCollapsed

            />
        </>
    );
}

export default function ProfessionalForm({ 
    value, rolesData, onSubmit, onCancel, onChange,
    ActionComponent, onRemove, formProps, loading
}) {
    const formRef = React.useRef(null);
    const { hash  } = useLocation();
    const { roles } = useRole();
    React.useEffect(() => {
        if (value.mcb_roleid === hash.slice(1)) {
            document.getElementById(value.mcb_roleid).scrollIntoView({behavior: "smooth"});
        }
    }, [hash, value.mcb_roleid]);
    const onChangeOverride = React.useCallback((value, alias) => {
        onChange((oldValue) => {
            const result =  {
                ...oldValue,
                [alias]: value
            };
            (rolesData || roles || []).sort((a, b) => new Date(b.mcb_starteddate) - new Date(a.mcb_starteddate)).some(role => {
                if (
                    role.mcb_rolecompanyname
                    && role.mcb_rolehtml
                    && role.mcb_rolecompanyname === result.mcb_rolecompanyname
                ) {
                    result.mcb_rolehtml = role.mcb_rolehtml;
                    return true;
                } else {
                    return false;
                }
            });
            return result;
        });
    }, [onChange, roles, rolesData]);
    const { confirm } = useActionDialog();

    React.useEffect(() => {
        const companyNamesDataList = document.getElementById("unique-company-names");
        if (!companyNamesDataList) {
            const datalist = document.createElement("datalist");
            datalist.id = "unique-company-names";
            const uniqueCompanyNames = new Set();
            (rolesData || roles || []).forEach(role => {
                uniqueCompanyNames.add(role.mcb_rolecompanyname);
            });
            uniqueCompanyNames.add(value?.mcb_rolecompanyname);
            uniqueCompanyNames.forEach((companyName) => {
                if (companyName) {
                    const option = document.createElement("option");
                    option.value = companyName;
                    datalist.appendChild(option);
                }
            });
            document.body.prepend(datalist);

            return () => {
                datalist.remove();
            }
        }
    }, [roles, rolesData, value]);

    return (
        <div className="professional form d-flex flex-column gap-2" {...(value?.mcb_roleid?{id: value?.mcb_roleid}: {})}>
            <div className="h3 mb-3 d-flex justify-content-between align-items-center">
                <div>{value?.mcb_roletitle || "Add Title"}</div>
                <div className="text-end flex-grow-1 fs-smaller">
                    {value?.mcb_rolecompanyname}
                </div>
                {onRemove && (
                    <button
                        className='btn btn-transparent text-danger fs-smaller'
                        type="button"
                        onClick={async (...args) => {
                            if (await confirm("This could not be undone. Are you sure?")) {
                                onRemove(...args);
                            }
                        }}
                        > 
                        <RemoveIcon/>
                    </button>
                )}
                
            </div>
            <Form
                {...formProps}
                ref={formRef}
                className="d-flex flex-column gap-4 mb-4">
                <TwoInputColumns
                    state={value}
                    setState={onChange}
                    inputs={[
                        ['mcb_roletitle', 'text', 'Title', {required: true}],
                        ['mcb_rolecompanyname', 'text', 'Company Name', {required: true, list: "unique-company-names", onChangeOverride}],
                        ['mcb_starteddate', 'month', 'Started Date', {
                            required: true,
                            name: "mcb_starteddate",
                            maxDate: (!value.currentJob && value?.mcb_endeddate)? new Date(value?.mcb_endeddate) : new Date()
                        }],
                        ['mcb_endeddate', 'month', 'Ended Date', {
                            required: !value?.currentJob,
                            disabled: value?.currentJob,
                            minDate: value?.mcb_starteddate? new Date(value?.mcb_starteddate) : undefined,
                            maxDate: new Date(),
                            name: "mcb_endeddate",
                            disabledValue: "present"
                        }]
                    ]}
                />
                <SingleInputColumn
                    state={value}
                    setState={onChange}
                    inputs={[
                        ['currentJob', 'checkbox', <span className="fs-smaller">I am currently working in this role</span>],
                        ['mcb_rolehtml', 'richtext', (
                            <span><strong>Company Blurb</strong> (Some blurb about company) <em className='fs-smaller'>(max 350 char)</em></span>
                        ), { required: true, maxCharacterCount: 350 }],
                        ['mcb_challenge', 'richtext', (
                            <span><b>The Challenge</b> (Why were you appointed to the role?) <em className='fs-smaller'>(max 350 char)</em></span>
                        ), { required: true, maxCharacterCount: 350 }]
                    ]}
                />
            </Form>
            <ResponsibilitiesInput
                key={loading? "loading-responsibilities" : "responsibilities"}
                value={value}
                onChange={onChange}
            />
            <ProfessionalAchievementInput
                key={loading? "loading-achievement" : "achievement"}
                value={value}
                onChange={onChange}
            />
            {ActionComponent && (
                <div key="actions">
                    <ActionComponent {...{onSubmit, onCancel, value, submitType: "submit"}} />
                </div>
            )}
        </div>
    );
}