import React from 'react';
import FormModal from './Generic/FormModal';
import { FloppyFill as FloppyIcon } from './Generic/Icons';
import { addClassNames } from '../Functions/Helper/Component';
import ProfessionForm from './ProfessionForm';

export default function ProfessionalModal({title, actionName, classNames, ...props}) {
    const _classNames = {
        modalTitleClassName: "btn-primary",
        modalDialogClassName: "modal-dialog-centered", 
        modalTriggerClassName: "border-0 fs-5 p-0 shadow-none text-decoration-underline",
        closeActionClassName: "btn icon-button",
        submitActionClassName: "btn btn-primary"
    };

    Object.entries(classNames || {}).forEach(([key, value]) => {
        _classNames[key] = addClassNames(value, _classNames[key]);
    });

    return (
        <FormModal
            title={(title ||  `${props?.value?.mcb_roleid? "Update" : "Add"} Profession`)}
            actionName={(actionName || <><FloppyIcon className="me-2"/>{props?.value?.mcb_roleid? "Update" : "Add"} Role</>)}
            classNames={_classNames}
            BodyComponent={ProfessionForm}
            {...props}
        />
    );
}