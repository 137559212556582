import React from 'react';
import AchievementForm from './AchievementForm';
import ArrayInput from './Generic/ArrayInput';
import "./AchievementsInput.scss";
import RichTextDisplay from './RichTextDisplay';

export function AchievementDisplay({value}) {
    return (
        <RichTextDisplay value={value?.mcb_achievementstatement} css={" p { margin-top: 0px; }"}/>
    );
}

export default function AchievementsInput({values, ...props}) {
    const _values = React.useMemo(() => (values || []), [values]);
    return (
        <ArrayInput
            inputClassName="mb-4" 
            newItemLabel="Add Achievement"
            newItemClassName="card text-bg-light shadow-sm p-4 mb-3"
            editItemLabel="Edit Achievement"
            editItemClassName="card text-bg-light shadow-sm p-4 mb-3"
            InputComponent={AchievementForm}
            DisplayComponent={AchievementDisplay}
            noActionComponents={true}
            keyFunction={(v) => v?.mcb_abcid}
            values={_values}
            {...props}
        />
    );
}