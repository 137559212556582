import React from 'react';
import Input from './Generic/Input';
import { addClassNames } from '../Functions/Helper/Component';
import useCVOption from '../Hooks/useCVOptions';

export default function CVSelectionsInput({className, additionalOptions, ...props}) {
    const [options, loading] = useCVOption();
    
    return (
        <div>
            <Input
                {...props}
                type='select'
                isLoading={loading}
                className={addClassNames('primary-select', className)}
                options={[...(additionalOptions || []), ...(options || [])]}
            />
        </div>
    );
}