import React from 'react';
import RoundedIcon from './RoundedIcon';
import { addClassNames } from '../../Functions/Helper/Component';

export default function SimpleCard({ className, Icon, backgroundColor,  children}) {
    return (
        <div className={addClassNames('card text-bg-light  p-3 d-flex flex-row flex-nowrap gap-3', className)}>
            <RoundedIcon
                Icon={Icon}
                className={'fs-2'}
                backgroundColor={backgroundColor}
                color="var(--mo-light)"
            />
            <div className="d-flex flex-grow-1 gap-3 justify-content-between align-items-center">
                {children}
            </div>
        </div>
    );
}