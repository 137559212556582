import { formattedValue } from './Format.Helper';
import { tableHTML, formatDate, strongHTML, spanHTML, divHTML, styleHTML, newlineHTML, normalizeRichText } from './Generator.Helper';

export function formatABCData(allRoles, allABCs, allSkills){
    const roles = {},
        achievements = {},
        skills = allSkills.reduce(function(comp, skill) {
            comp[skill.mcb_skillid] = {
                name: skill.mcb_skill,
                count: 0
            };
            return comp;
        }, {}),
        cliftonStrengths = {};

    allABCs.forEach(function(abc) {
        const role = { ...(allRoles.find((role) => role.mcb_roleid === abc._mcb_role_value) || {})};
        let achievementData = {
            id: abc.mcb_abcid,
            achievement: abc.mcb_achievement,
            benefit: abc.mcb_benefit,
            achievementstatement: abc.mcb_achievementstatement,
            role,
            skills: [],
            cliftonStrengths: (abc[formattedValue("mcb_cliftonstrengths")] || "").split(";")
        };

        if(Array.isArray(abc.mcb_ABCSkill)) {
            abc.mcb_ABCSkill.forEach(function({mcb_skillid: skillId}) {
                if(typeof skills[skillId] === "object") {
                    achievementData.skills.push(skillId);
                    skills[skillId].count++;
                }
            });
        }
        
        achievementData.cliftonStrengths.forEach(function(cliftonStrength) {
            if (!cliftonStrength) return;
            if(typeof cliftonStrengths[cliftonStrength] !== "number") {
                cliftonStrengths[cliftonStrength] = 0;
            } 
            cliftonStrengths[cliftonStrength]++;
        });

        achievements[abc.mcb_abcid] = achievementData;

        
        if (role.mcb_roleid) {
            if(typeof roles[role.mcb_roleid] === "object") {
                roles[role.mcb_roleid].achievements.push(achievementData);
            } else {
                roles[role.mcb_roleid] = {
                    title: role.mcb_roletitle,
                    companyName: role.mcb_rolecompanyname,
                    startedDate: formatDate(role.mcb_starteddate),
                    endedDate: role.mcb_rolestatus === 470620002? 
                        formatDate(role.mcb_endeddate)
                        : "CURRENT",
                    achievements: [achievementData]
                };
            }
        }            
        
    });
    const result = { roles, achievements, skills, cliftonStrengths };
    
    result.roles = Object.entries(roles).sort(function(roleA, roleB) {
        return new Date(roleB[1].startedDate).getTime() 
            - new Date(roleA[1].startedDate).getTime();
    }).map(function([id, role]) {
        const achievements = (role.achievements || []).map(function(achievement) {
                let score = 0;
                
                achievement.skills.forEach(function(skill) {
                    score += skills[skill]?.count || 0;
                });
                
                achievement.cliftonStrengths.forEach(function(strength) {
                    score += cliftonStrengths[strength];
                });
                
                return Object.assign(achievement, { 
                    score,
                    skills: achievement.skills.sort((a, b) => skills[b].count - skills[a].count).map(a => skills[a].name),
                    cliftonStrengths: achievement.cliftonStrengths.sort((a, b) => cliftonStrengths[b] - cliftonStrengths[a])
                });
            }).sort(function(achA, achB) {
                return achB.score - achA.score;
            });

        return Object.assign(role, { id, achievements});
    });

    return result;
}


function generateTableData(data) {
    let tableRows = [];
    
    data.roles.forEach(function(role) {
        const achievementCount = role.achievements.length;

        role.achievements.forEach(function(achievement, achIdx) {
            const result = [];
            if (!achIdx) {
                result.push([
                    [
                        divHTML(role.title),
                        divHTML(role.companyName),
                        divHTML(role.startedDate + " - " + role.endedDate)
                    ].join(newlineHTML),
                    { 
                        options: "rowspan=\"" + achievementCount + "\"",
                        style: "border-bottom:1px solid black; border-left: 1px solid black;"
                    }
                ]);
            }
            result.push(spanHTML(achievement.achievement));
            result.push(spanHTML(achievement.benefit));

            const strengths = [[
                divHTML(
                    achievement.skills.map(function(skill) {
                        return spanHTML(skill);
                    }).join(spanHTML(", "))
                ), divHTML(
                    achievement.cliftonStrengths.map(function(cliftonStrength) {
                        return spanHTML(cliftonStrength);
                    }).join(spanHTML(", "))
                )
            ].join(newlineHTML), { style: "border-left:1px solid black;" }];
        
            result.push(strengths);
            result.push(normalizeRichText(achievement.achievementstatement));
            
            tableRows.push([result]);
        });
    });

    return tableRows;
}

export function generateABCHTML(roles, abcs, skills) {
    const data = formatABCData(roles, abcs, skills);
    return '<div data-wrapper="true" style="font-family:Calibri;font-size:11pt">'
        + tableHTML(
            { options: "cellspacing=\"0\" cellpadding=\"1\" border=\"1\" docx-table-width=\"13958\"" },  
            [[ 
                [strongHTML("Company / Title / From - To "), {
                    style: "border-left:1px solid black;"
                }], 
                spanHTML("A", "color:#1f4979;font-size:18pt;font-weight:700;")
                    + strongHTML("chievement (What I did/delivered) "),
                spanHTML("B", "color:#c0504d;font-size:18pt;font-weight:700;")
                    + strongHTML("enefit / Outcome (Gain to the business from your achievement) "),
                divHTML( spanHTML("C", "color:#76923c;font-size:18pt;font-weight:700;")
                    + strongHTML("ompetencies 1, 2 or 3 words. Competency title. (e.g. Leadership) ")
                ) + divHTML(spanHTML("These are shown on the first page of your CV"
                    + " supported by an Achievement Statement as evidence "
                    + "you have demonstrated the competency"
                )), [
                    spanHTML("CV A", "color:#c0504d;font-size:18pt;font-weight:700;")
                    + strongHTML("chievement ")
                    + spanHTML("S", "color:#c0504d;font-size:18pt;font-weight:700;")
                    + strongHTML("tatement: (Outcome / Benefit followed by the how - context)"), {
                    style: "border-right:1px solid black;"
                }]
            ], { 
                style: "background-color: #e7e6e6;"
                    + "border-left: 0px solid transparent;"
                    + "border-right: 0px solid transparent;",
                options: "valign=\"top\""
            }],
            ...generateTableData(data)
        ) + '</div>' + styleHTML;
}