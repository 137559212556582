import React from 'react';
import Input from './Input';
import Form from '../../Components/Generic/Form';
import ArrayInput from '../../Components/Generic/ArrayInput';
import { FloppyFill as FloppyIcon, ChevronLeft } from '../Icons';

export default function StringArrayInput({toValue, toObject, toDisplay, toKey, inputProps, ...props}) {
    const InputComponent = React.useCallback(({value, onSubmit, onChange, isNew, onCancel}) => { 
        return (
            <Form onSubmit={onSubmit} isNew={isNew}>
                <Input            
                    value={(typeof toValue === "function"?  toValue(value) : value) || ""}
                    onChange={typeof toObject === "function"? (d) =>  onChange(toObject(d, value)) : onChange}
                    addOn={{
                        children: (
                            <div className="btn-group">
                                {!isNew && (
                                    <button 
                                        onClick={onCancel}
                                        className="btn btn-transparent pe-3"
                                        type="button"
                                    >
                                        <ChevronLeft className="me-2"/> Cancel
                                    </button>
                                )}
                                <button 
                                    onClick={onSubmit}
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                >
                                    <FloppyIcon className="me-2"/> {isNew? "Add" : "Save"}
                                </button>
                            </div>
                        )
                    }}
                    {...(typeof inputProps === "function"? inputProps({value, onSubmit, onChange, isNew, onCancel}) 
                        :inputProps? inputProps : {})}
                    
                />
            </Form>
        )
    }, [toValue, inputProps, toObject]);
    const DisplayComponent = React.useCallback(({value}) => {
        return <span>{
            typeof toDisplay === "function"? toDisplay(value)
            : typeof toValue === "function"? toValue(value)
            : value.length? value : ""
        }</span>;
    }, [toValue, toDisplay]);
    return (
        <ArrayInput
            InputComponent={InputComponent}
            DisplayComponent={DisplayComponent}
            newValue=""
            keyFunction={toKey}
            noActionComponents
            {...props}
        />
    );
}