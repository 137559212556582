import React from 'react';
import FormModal from './Generic/FormModal';
import CliftonStrengthForm from './CliftonStrengthForm';
import { PlusCircle as AddIcon } from './Generic/Icons';
import useABC from '../Provider/TableContext/ABCContext';

export default function CliftonStrengthAssociationModal({title, actionName,  onClose: _onClose,...props}) {
    const { abcs, loading, updateABC } = useABC();
    const [formData, setFormData] = React.useState({ cliftonstrengths: [], achievements: []});

    const onClose = React.useCallback(() => {
        _onClose?.(); 
    }, [_onClose]);


    const onSubmit = React.useCallback(async () => {
        if (!formData?.achievements?.length) return false;
        const promises = [];
        
        formData.achievements.forEach(achievement => {
            const abc = abcs.find(({mcb_abcid}) => mcb_abcid === achievement);
            const abcCliftonStrengths = abc?.mcb_cliftonstrengths?  abc?.mcb_cliftonstrengths?.split(",") : [];
            let change = false;
            
            formData.cliftonstrengths.forEach(cliftonstrength => {
                if (!abcCliftonStrengths.includes(`${cliftonstrength}`)) {
                    abcCliftonStrengths.push(`${cliftonstrength}`);
                    change = true;
                }    
            });
            const newABC = {...abc, mcb_cliftonstrengths: abcCliftonStrengths.join(",")};
            if (change) promises.push(updateABC(newABC));
        });

        await Promise.all(promises);
        return true;
    }, [formData, abcs, updateABC]);
    
    return (
        <>
            <FormModal
                title={(title || "Add CliftonStrengths®")}
                actionName={(actionName || <><AddIcon className="me-2"/> Add CliftonStrengths®</>)}
                classNames={{
                    modalTitleClassName: "btn-primary",
                    modalDialogClassName: "modal-dialog-centered", 
                    modalTriggerClassName: "btn-outline-dark border-0 fs-5 p-0 shadow-none text-decoration-underline",
                    closeActionClassName: "btn icon-button",
                    submitActionClassName: "btn btn-primary",
                }}
                BodyComponent={CliftonStrengthForm}
                onClose={onClose}
                formData={formData}
                onSubmit={onSubmit}
                setFormData={setFormData}
                loading={loading}
                {...props}
            />
        </>
    );
}