import React from 'react';
import Form from '../../Components/Generic/Form';
import { SingleInputColumn } from '../../Components/Generic/InputGrouping';
import CliftonStrengthInput from '../../Components/CliftonStrengthInput/CliftonStrengthInput';
import SkillInput from '../../Components/SkillInput';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import RequirementsInput from '../../Components/RequirementsInput';
import QualificationsInput from '../QualificationsInput';
import { Tooltip } from 'react-tooltip';
import CVSelectionsInput from '../CVSelection';
export { SidePanel, getBindedSidePanel } from './SidePanel';

const tooltip = ({ id, data }) => {
    const isError = data.color === "var(--mo-danger)";
    let text = isError?
        "No achievement with this skill has been added to requirements.\n"
        + "Please click on the skill to see all the achievements with the skill."
        : "Click to see all the achievements with this skill.";
    return (
        <Tooltip key={id} id={id} place="top" style={{zIndex: "9999", whiteSpace: "pre-wrap"}} effect="solid">
            <div>{text}</div>
        </Tooltip>
    );
};

export default function Requirement({ onNext, next, formData, setFormData, updateRequirements, loading }) {
    const formRef = React.useRef();
    const [highlight, setHighlight] = React.useState(false)
    onNext(async () => {
        if(formRef.current?.reportValidity() && formData.requirements.length) {
            return await updateRequirements();
        }
        if (!highlight) setHighlight(true);
        return false;
    });
    
    const onChange = React.useCallback((data) => {
        return setFormData(fd => {
            if (typeof data === "function") {
                data = data(fd);
            }
            const dKeys = Object.keys(data);
            const fdKeys = Object.keys(fd);
            
            if (dKeys.length !== fdKeys.length || dKeys.some(key => data[key] !== fd[key])) {
                if (data.requirements !== fd.requirements || data.skills !== fd.skills) {
                    const skillCounter = {};
                    data?.requirements?.forEach(requirement => {
                        requirement?.achievements?.forEach(achievement => {
                            achievement?.mcb_ABCSkill?.forEach((skill) => {
                                if (skill.mcb_skillid) {
                                    skillCounter[skill.mcb_skillid] = (skillCounter[skill.mcb_skillid] || 0) + 1;
                                }
                            });
                        });
                    });
                    const skills = data?.skills?.sort((a, b) => {
                        return (skillCounter[b] || 0) - (skillCounter[a] || 0);
                    }) || [];
                    return { ...(data || fd || {}), skills};
                } else {
                    return data;
                }
            }
            return fd;
            
        });
    }, [setFormData]);

    const cloneCV = React.useMemo(() => formData.CV && formData.CV !== "new-cv" && !formData._mcb_cv_value, [formData.CV, formData._mcb_cv_value])

    const inputs = React.useMemo(() => {
        const onClick = (data, e) => {
            e.preventDefault();
            e.stopPropagation();
            const sidePanel = document.getElementsByClassName("journey-side-panel")[0];
            if (sidePanel) sidePanel.scrollTo({ top: 0, behavior: 'smooth' });
            const wrapper = document.getElementsByClassName("journey-wrapper")[0];
            if (wrapper) wrapper.scrollTo({ left: wrapper.clientWidth, behavior: 'smooth' });
            setFormData(fd => fd.skillSearch === data.value? fd : ({...fd, skillSearch: data.value }));
        };
        const associatedSkills = [];
        formData.requirements?.forEach(requirement => {
            requirement.achievements?.forEach(achievement => {
                achievement.mcb_ABCSkill?.forEach(({mcb_skillid}) => {
                    if (!associatedSkills.includes(mcb_skillid)) associatedSkills.push(mcb_skillid); 
                });
            });
        });
        return [
            [
                'skills', SkillInput, (
                    <span>
                        <strong>Required Skills</strong> (skills that are required for the job.)
                    </span>
                ), { required: true, highlightNotIn: associatedSkills, 
                    onClick, tooltip, menuPlacement: "top" }
            ], [
                'cliftonstrengths', CliftonStrengthInput, (
                    <span>
                        <strong>Required CliftonStrengths</strong>
                    </span>
                ), { multiselect: true, menuPlacement: "top" }
            ], [
                'qualifications', QualificationsInput, (
                    <span>
                        <strong>Your Qualifications</strong> (Qualifications for the job.)
                    </span>
                ), { multiselect: true, menuPlacement: "top" }
            ], [
                'CV', CVSelectionsInput, (
                    <span>
                        <strong>Select the CV to use</strong> (You can use previously generated cv.)
                    </span>
                ), { menuPlacement: "top", additionalOptions: [{ label: "New CV", value: "new-cv"}] }
            ]
        ].concat(cloneCV? [[ 
            "Duplicate", "checkbox", "Duplicate the CV."
        ]]: []);
    }, [setFormData, formData.requirements, cloneCV])

    if (loading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            Loading...
        </LoadingSpinner>
    );
    return (
        <div>
            <h3 className="h5">Requirements for Job</h3>            
            <RequirementsInput
                className={"mb-5"}
                onChange={onChange}
                values={formData}
                invalid={highlight && !formData.requirements.length}
            />
            <Form 
                ref={formRef}
                onSubmit={(e) => { 
                    e.preventDefault();
                    next(); 
                }}
                className="my-2 d-flex flex-column gap-3"
            >
                <SingleInputColumn
                    state={formData}
                    setState={onChange}
                    inputs={inputs}
                />
            </Form>
        </div>
    );
};