import React from 'react';
import { TwoInputColumns, StateInput } from '../../Components/Generic/InputGrouping';
import Form from '../../Components/Generic/Form';
import useIdealRoleJourneyContext from './IdealRoleContext';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import Sector from '../../Constants/Sector.json';
import ChipDropdown from '../../Components/Generic/ChipDropdown';
const sortedSectors = Sector.sort((a, b) => a.label.localeCompare(b.label));

export default function RoleDetails({ onNext, next }) {
    const { formData, setFormData, updateIdealRoleDetails, loading } = useIdealRoleJourneyContext();
    
    const formRef = React.useRef();
    onNext(async () => {
        if(formRef.current.reportValidity()) {
            await updateIdealRoleDetails();
            return true;
        }
        return false;
    });

    
    if (loading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            Loading Ideal Job Data...
        </LoadingSpinner>
)

    
    return (
        <Form 
            ref={formRef}
            onSubmit={(e) => { 
                e.preventDefault();
                next(); 
            }}
            className="my-2 d-flex flex-column gap-3"
        >
            <h3 className="h5">Job Details</h3>
            <TwoInputColumns
                state={(formData || {})}
                setState={setFormData}
                inputs={[
                    ['mcb_roletitle', 'text', 'Job Title', {required: true}],
                    ['mcb_reportingto', 'text', 'Reporting To', {required: true}],
                ]}
            />
            <StateInput
                state={(formData || {})}
                setState={setFormData}
                alias='mcb_generalcomments'
                type='richtext'
                label={<span>General Comments <em className='fs-smaller'>(max 250 char)</em></span>}
                props={{
                    maxCharacterCount: 250
                }}
            />
            <h3 className="h5">Interests</h3>
            <TwoInputColumns
                state={(formData || {})}
                setState={setFormData}
                inputs={[
                    [
                        'mcb_baserenumeration',
                        'number', (
                            <>
                                <label>Renumeration</label><br/>
                                <span className="fs-smaller">The amount of the remuneration you are interested in</span>
                            </>
                        ),
                        {
                            addOn: {
                            children: <ChipDropdown 
                                defaultValue={861400000}
                                onChange={(mcb_renumerationtype) => setFormData((od) => ({ ...od, mcb_renumerationtype }))}
                                value={formData.mcb_renumerationtype}
                                options={[
                                    { label: "per year", value: 861400000},  
                                    { label: "per hour", value: 861400001}    
                                ]} 
                            />
                            },
                            preAddOn: {
                            children: <div className="font-weight-bold">$</div>
                            },
                            inputClassName: "text-end text",
                            min: 0,
                            step: 1
                        }
                    ],
                    [
                        'mcb_atriskrenumeration',
                        'number', (
                            <>
                                <label>At Risk Renumeration</label><br/>
                                <span className="fs-smaller">The additional bonus/commissions you would want.</span>
                            </>
                        ),
                        {
                            // addOn: {
                            //   children: <ChipDropdown 
                            //     onChange={(mcb_renumerationtype) => setFormData((od) => ({ ...od, mcb_renumerationtype }))}
                            //     value={formData.mcb_renumerationtype}
                            //     options={[
                            //         { label: "per year", value: 861400000},  
                            //         { label: "per hour", value: 861400001}    
                            //     ]} 
                            //   />
                            // },
                            preAddOn: {
                                children: <div className="font-weight-bold">$</div>
                            },
                            inputClassName: "text-end",
                            min: 0,
                            step: 1
                        }
                    ],
                    ['interestedSections', 'multiselect', (
                        <>
                            <label>Interested Sectors</label><br/>
                            <span className="fs-smaller">The sector you are interested in.</span>
                        </>
                    ), {
                        options: sortedSectors,
                        className: "primary-select",
                        placeholder: "Select Sector"
                    }],
                    ['notInterestedSections', 'multiselect', (
                        <>
                            <label>Not Interested Sectors</label><br/>
                            <span className="fs-smaller">The sector you are not interested in.</span>
                        </>
                    ), {
                        options: sortedSectors,
                        className: "danger-outline-select",
                        placeholder: "Select Sector"
                    }],
                    ['mcb_interestedcompany', 'text', (
                        <>
                            <label>Interested Company</label><br/>
                            <span className="fs-smaller">The companies you are interested in.</span>
                        </>
                    )],
                    ['mcb_notinterestedcompany', 'text', (
                        <>
                            <label>Not Interested Company</label><br/>
                            <span className="fs-smaller">The companies you are not interested in.</span>
                        </>
                    )]
                ]}
            />              
        </Form>
    );
}

RoleDetails.stepName = "Job Details";