import React, { useState } from 'react';
import { 
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon
} from '../Icons';
import { Document, Page } from 'react-pdf';
import "./PDFViewer.scss";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

export default function PDFViewer({ id, zoom, file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const lastId = React.useRef(null);
  window.lastPDFFile = file;
  React.useEffect(() => {
    if (lastId.current !== id) {
      setPageNumber(1);
      lastId.current = id;
    }
  }, [id]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  let PDFDisplay = "";
  try {
    PDFDisplay = (
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} scale={zoom} />
      </Document>
    );
  } catch (err) {
    PDFDisplay = (
      <div className="d-flex flex-grow-1 flex-column gap-4 align-items-center justify-content-center w-100">
        <div className="alert alert-danger w-100">
          <h5>Error loading PDF</h5>
          <p>There was an error loading the PDF. Please try again later.</p>
        </div>
      </div>
    );
  }
  
  return (
    <>
    <div className="d-flex flex-grow-1 flex-column gap-4 align-items-center w-100">
      <div className="d-flex position-relative flex-grow-1 align-items-center justify-content-center w-100">
        <div className="position-absolute w-100 h-100 overflow-auto">
          {PDFDisplay}
        </div>
        
      </div>
      {numPages > 1 && (
        <div className="card d-flex flex-row gap-3 flex-nowrap align-items-center">
          <button
            type="button"
            className="icon-button"
            disabled={pageNumber <= 1}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          ><LeftIcon className="icon me-0"/></button>
          <div>{pageNumber} of {numPages}</div>
          <button
            type="button"
            className="icon-button"
            disabled={pageNumber >= numPages}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          ><RightIcon className="icon me-0"/></button>
        </div>
      )}
    </div>
    </>
  );
}