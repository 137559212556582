import React from 'react';
import useIdealRoleJourneyContext from './IdealRoleContext';
import CVSidePanel from '../../Components/CV/CVSidePanel';
import CVForm from '../../Components/CV/CVForm';

export default function IdealRoleCVDetails(props) {
    const context = useIdealRoleJourneyContext();
    return <CVForm
        {...props}
        {...context}
        loadingText="Working on Ideal Role Data" 
        redirectUrl={() => "/#ideal-job"}
    />;
}

export function SidePanel({className}) {
    const { formData, setFormData, setCVBytes, loading } = useIdealRoleJourneyContext();
    return (<CVSidePanel {...{className, formData, setFormData, setCVBytes, loading}} />);
}

IdealRoleCVDetails.SideComponent = SidePanel;

IdealRoleCVDetails.stepName = "Generic CV";