import React from 'react';
import { ReactComponent as LogoSVG } from '../../Assets/logo.svg';
import MCBLogo from '../../Assets/logo.png';
import './Logo.scss';
import { addClassNames } from '../../Functions/Helper/Component';
import LogoWheelSvg from './LogoWheelSVG';

export default function Logo({ mini, className, ...props }) {
    return (
        <div {...props} className={addClassNames("logo-container", className)}>
            {mini? (
                <div className="logo">
                    <LogoWheelSvg className="mcb-logo" />
                </div>
            ) : ( 
                <div className="logo-aspect-ratio-box">
                    <div className="logo-position">
                        <div className="logo me-0">
                            <LogoSVG className="mcb-logo"/>
                            <img src={MCBLogo} className="mcb-brand" alt="MyCareerBrand"/>
                        </div>
                            
                    </div>
                    
                </div>
            )}
        </div>
    );
}