import React from 'react';
import LoadingSpinner from './Generic/LoadingSpinner';
import Card from './Generic/Card';
import RichTextDisplay from './RichTextDisplay';
import HTMLDocxLink from './HTMLDocxLink';
import useProfile from '../Provider/TableContext/ProfileContext';

const Description = (HTML) => () => <HTMLDocxLink HTML={HTML} rotate name="MyABCs"> Download MyABCs</HTMLDocxLink>;

export default function MyABCs() {
    const { profileData, loading } = useProfile();

    const children = loading || !profileData?.mcb_myabcs? <LoadingSpinner/> : <RichTextDisplay value={profileData.mcb_myabcs}/>;
    
    return (
        <Card
            title="MyABCs"
            className="w-100"
            description="Downloads this ABC as MS Word Document"
            DescriptionControl={Description(profileData.mcb_myabcs)}
        >
            {children}
        </Card>
    );
}