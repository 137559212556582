export default function processRPR(xml) {
    const context = {};
    const style = {};

    if (xml._children?.length) {
        const childStyles = {};
        const csStyle = {};
        xml._children.forEach(child => {
            const childData = processRPR(child);
            const { style: childStyle, context: { cs, ...childContext} } = childData;
            Object.assign(childStyles, childStyle);
            if (cs) Object.assign(csStyle, cs);
            Object.assign(context, childContext);
        });
        Object.assign(style, csStyle, childStyles);
    }

    switch (xml._tag) {
        case "w:rStyle":	// Specifies the character style to be applied to the run.
            context.runningStyle = xml._attrs['w:val'];
            break;
        
        case "w:bCs":	// Specifies that the text in the run should be bold, but only for complex script text.
            context.cs = { fontWeight: 'bold' };
            break;
        case "w:b":	// Specifies that the text in the run should be bold.
            style.fontWeight = 'bold';
            break;
        
        case "w:iCs":	// Specifies that the text in the run should be italic, but only for complex script text.
            context.cs = { fontStyle: 'italic' };
            break;
        case "w:i":	// Specifies that the text in the run should be italic.
            style.fontStyle = 'italic';
            break;
        
        case "w:uCs":	// Specifies that the text in the run should be underlined, but only for complex script text.
            context.cs = { textDecoration: 'underline'};
            break;
        case "w:u":	// Specifies that the text in the run should be underlined.
            style.textDecoration = 'underline';
            break;
        
        case "w:dstrike":	// Specifies that the text in the run should be double struck through.
        case "w:strike":	// Specifies that the text in the run should be struck through.
            style.textDecoration = 'line-through';
            break;
        
        case "w:color":	// Specifies the color of the text in the run.
            if (xml._attrs['w:val'] && xml._attrs['w:val'] !== "auto")
                style.color = `#${xml._attrs['w:val']}`;
            break;
        
        case "w:highlight":	// Specifies the highlight color behind the text in the run.
            style.backgroundColor = xml._attrs['w:val'];
            break;

        case "w:szCs":	// Specifies the font size of the text in the run, but only for complex script text.
            if (xml._attrs['w:val']) {
                // Convert the measurement to points (1 half-point = 0.5 pt)
                let fontSizeInPoints = parseInt(xml._attrs['w:val']) / 2;
                context.cs = { fontSize: `${fontSizeInPoints}pt` };
            }
            break;
        case "w:sz":	// Specifies the font size of the text in the run.
            if (xml._attrs['w:val']) {
                // Convert the measurement to points (1 half-point = 0.5 pt)
                let fontSizeInPoints = parseInt(xml._attrs['w:val']) / 2;
                style.fontSize = `${fontSizeInPoints}pt`;
            }
            break;
        
        case "w:vertAlign":	// Specifies the vertical alignment of the text in the run (superscript, subscript, or baseline).
            style.verticalAlign = xml._attrs['w:val'];
            break;
        
        case "w:rtl":	// Specifies that the text in the run is written in a right-to-left language.
            style.direction = 'rtl';
            break;
        
        case "w:shd":	// Specifies the shading (background color) of the text in the run.
            style.backgroundColor = `#${xml._attrs['w:fill']}`;
            break;
        
        case "w:smallCaps":	// Specifies that the text in the run should be displayed in small caps.
            style.textTransform = 'lowercase';
            break;
        
        case "w:caps":	// Specifies that the text in the run should be displayed in capital letters.
            style.textTransform = 'uppercase';
            break;
        
        case "w:spacing":	// Specifies the spacing between characters in the run.
            style.lineHeight = `${xml._attrs['w:line'] / 20}pt`;
            if (xml._attrs['w:before']) {
                style.marginTop = `${parseInt(xml._attrs['w:before'])/ 20}pt`;
            }
            if (xml._attrs['w:after']) {
                style.marginBottom = `${parseInt(xml._attrs['w:after'])/ 20}pt`;
            }

            if (xml._arrts['w:lineRule'] === "exact") {
                style.lineHeight = `${parseInt(xml._attrs['w:line']) / 20}pt`;
            } else {
                style.lineHeight = `min(${parseInt(xml._attrs['w:line']) / 20}pt, 100%)`;
            }
            style.verticalAlign = 'top';
            break;
        
        case "w:w":	// Specifies the width of the characters in the run.
            style.letterSpacing = `${xml._attrs['w:val'] / 20}pt`;
            break;
        
        case "w:kern":	// Specifies the minimum font size for which kerning should be applied.
            style.fontKerning = 'normal';
            break;
        
        case "w:position":	// Specifies the position of the text relative to the baseline.
            style.verticalAlign = `${xml._attrs['w:val'] / 20}pt`;
            break;
        
        case "w:vanish":	// Specifies that the text in the run should be hidden.
        case "w:webHidden":	// Specifies that the text in the run should be hidden when the document is displayed in a web browser.
        case "w:specVanish":	// Specifies that the run should be hidden for display purposes but should still exist in the document.
            style.visibility = 'hidden';
            break;
        
        case "w:outline":	// Specifies that the text in the run should be outlined.
            style.textShadow = '1px 1px 1px black';
            break;
        
        case "w:shadow":	// Specifies that the text in the run should have a shadow.
            style.textShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';
            break;
        
        case "w:emboss":	// Specifies that the text in the run should appear as if it's raised off the page in relief.
            style.textShadow = '1px 1px 1px rgba(255, 255, 255, 0.5)';
            break;
        
        case "w:imprint":	// Specifies that the text in the run should appear as if it's pressed into the page.
            style.textShadow = '-1px -1px 1px rgba(0, 0, 0, 0.5)';
            break;
        
        case "w:bdr":	// Specifies a border around the text in the run.
            style.border = '1px solid black';
            break;
        
        case "w:fitText":	// Specifies that the text in the run should be compressed or expanded to fit within a specified width.
            style.overflow = 'hidden';
            style.whiteSpace = 'nowrap';
            break;
        
        case "w:rFonts":	// Specifies the fonts to be used to display the text in the run.
            style.fontFamily = `${xml._attrs['w:ascii']}, sans-serif`;
            break;

        case "w:cs":	// Specifies that the run contains complex script content.
            context.cs = { fontFamily: `${xml._attrs['w:val']}, sans-serif`};
            break;

        case "w:hint":	// Specifies the preferred font type to be used to display the text in the run.
            style.fontFamily = `${xml._attrs['w:val']}, sans-serif`;
            break;

        case "w:eastAsianLayout":	// Specifies the layout options to be used when displaying East Asian text in the run.
            if (xml._attrs['w:vert'] === 'true') {
                style.writingMode = 'vertical-rl';
                style.textOrientation = 'upright';
            }
            break;
/**
        
        case "w:lang":	// Specifies the language of the text in the run.
            break;
        
        case "w:noProof":	// Specifies that the text in the run should not be checked for spelling or grammar errors.
            break;
        
        case "w:del":	// Specifies that the run has been deleted in a tracked changes scenario.
            break;
            
        case "w:ins":	// Specifies that the run has been inserted in a tracked changes scenario.
            break;
            
        case "w:moveFrom":	// Specifies that the run has been moved from another location in a tracked changes scenario.
            break;
 
        case "w:moveTo":	// Specifies that the run has been moved to this location in a tracked changes scenario.
            break;
 
        case "w:oMath":	// Specifies that the run contains Office MathML (OMML) content.
            break;

        case "w:effect":	// Specifies a visual effect to be applied to the text in the run.
            break;
*/
        default:
            break;
    }

    return { style, context };
}