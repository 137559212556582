import RoleStatus from '../../Constants/RoleStatus.json';
import { deferred } from './Async';

export function abcToFormData(abc) {
    if (abc) {
        return { ...abc,
            skills: (abc.mcb_ABCSkill || []).map(({mcb_skillid} = {}) => mcb_skillid),
            cliftonstrengths: (abc.mcb_cliftonstrengths?.split(",")?.filter(a => a && !isNaN(a)) || []).map(d => parseInt(d))
        };
    } else return {};
}

export function roleToFormData(role, responsibilities, abcs) {
    const result = { ...(role || {})};
    if (result?.mcb_roleid) {
        result.responsibilities = (responsibilities || [])
            .filter(responsibility => responsibility._mcb_role_value === result?.mcb_roleid)
            .map(responsibility => ({ ...responsibility }));
        result.achievements = (abcs || [])
            .filter(abc => abc._mcb_role_value === result?.mcb_roleid)
            .map(abcToFormData).sort((abc1, abc2) => (abc1?.mcb_index || 0) - (abc2?.mcb_index || 0));
    } else {
        result.responsibilities = [];
        result.achievements = [];
    }
    return result;

}

export function professionToFormData(role, responsibilities, abcs) {
    const result = roleToFormData(role, responsibilities, abcs);
    result.currentJob = role.mcb_rolestatus === RoleStatus.Current;
    return result;
}

export function formDataToABC(formData = {}, index = 0) {
    const result = {...formData};
    const { skills, cliftonstrengths } = result;
    
    if (Array.isArray(skills)) {
        result.mcb_ABCSkill = skills.map(skill => ({
            mcb_skillid: skill
        }));
    } else result.mcb_ABCSkill = [];

    if (Array.isArray(cliftonstrengths)) {
        result.mcb_cliftonstrengths = (formData?.cliftonstrengths || []).join(",");
    }
    
    result.mcb_index = index;

    return result;
}

export function formDataToRole(formData = {}, responsibilityChanges, abcChanges) {
    const { responsibilities, achievements, ...role } = formData;
    let _mcb_role_value;
    
    if (role?.mcb_roleid) {
        _mcb_role_value = role.mcb_roleid; 
    } else {
        const [promise, resolve] = deferred();
        _mcb_role_value = promise;
        role.onCreated = resolve;
    }
    
    responsibilities?.forEach((responsibility, mcb_index) => {
        responsibilityChanges.push({
            ...responsibility, _mcb_role_value, mcb_index });
    });
    
    achievements?.forEach((abc, mcb_index) => {
        abcChanges.push(formDataToABC({...abc, _mcb_role_value}, mcb_index));
    });

    return role;
}

export function formDataToProfession(formData = {}, responsibilityChanges, abcChanges) {
    const result = formDataToRole(formData, responsibilityChanges, abcChanges);
    result.mcb_rolestatus =  result.currentJob? RoleStatus.Current : RoleStatus.Previous;
    return result;
}