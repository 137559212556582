import { useCallback } from "react";

export default function useAIGenerate(apiCall) {
    const emailCVs = useCallback(async(HTML, AdditionalPrompt = "") => {
        try {
            const result = await apiCall({
                method: "POST",
                url: "/_api/cloudflow/v1.0/trigger/8d3296ec-504e-ef11-a316-000d3ae0e86d",
                body: JSON.stringify({ 
                    eventData: JSON.stringify({ HTML, AdditionalPrompt })
                })
            }, 1);
            if (result.json) return result.json;
            else if (result.text) {
                const data = JSON.parse(result.text);
                if (data?.error && data?.error !== "NAN") {
                    throw new Error(data.error);
                } else if (data?.html) {
                    return data.html;
                }
                throw new Error("Error using AI Generation, please try again later.")
            }
            
        } catch (e) {
            console.error(e);
            throw e;
        }
    }, [apiCall])

    return emailCVs;
}