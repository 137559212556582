import React from 'react';
import { useLocation } from 'react-router-dom';
import LinkToJourney from './Generic/LinkToJourney';
import { PlusCircle as AddIcon } from './Generic/Icons';
import SearchForm from './SearchForm';
import ApplicationStatus from '../Constants/ApplicationStatus.json';
import RoleStatus from '../Constants/RoleStatus.json';
import useRole from '../Provider/TableContext/RoleContext';
import ApplyingRoleSmallPanel from './ApplyingRoleSmallPanel';
import { addClassNames } from '../Functions/Helper/Component';
import LoadingSpinner from './Generic/LoadingSpinner';
import { JobApplicationsLink } from './JobApplicationsLink';
import { getSectorNames } from '../Functions/Helper/Sector';

export default function ApplyingRolePreview({ limit, hideExtra: _hideExtra, Pagination: _Pagination }) {
    const location = useLocation();
    
    const hideExtra = React.useMemo(() => _hideExtra || !_Pagination, [_hideExtra, _Pagination]);
    const Pagination = React.useMemo(() => _Pagination || JobApplicationsLink, [_Pagination]);
    
    const [pageNumber, searchParams] = React.useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageNumber = parseInt(searchParams.get("i"));
        if (isNaN(pageNumber)) return [0, searchParams];
        else return [pageNumber, searchParams];
    }, [location.search]);
    
    const [search, setSearch] = React.useState("");
    const [filtered, setFiltered] = React.useState("all");
    const { roles, updateRole, deleteRole, loading } = useRole();
    
    const SearchedApplyingRoles = React.useMemo(() => roles.filter(role => (
        role?.mcb_rolestatus === RoleStatus.Applying
        && (
            !search?.length
            || (search.split(" ")?.every(keyword => {
                const k = keyword.trim().toLowerCase();
                if (!k?.length) return true;
                else if (getSectorNames(role?.mcb_sector.toString())?.toLowerCase().includes(k)) return true;
                else return ["mcb_roletitle", "mcb_rolecompanyname"]
                    .some(attrib => role[attrib]?.toLowerCase().includes(k));

            }))
            || role?.mcb_jobtitle?.toLowerCase().includes(search.toLowerCase())
            || role?.mcb_rolecompanyname?.toLowerCase().includes(search.toLowerCase())
            || role?.mcb_joblocation?.toLowerCase().includes(search.toLowerCase())
        
        )
    )), [roles, search]);

    const ApplicationStatuses = React.useMemo(() => Object.entries(ApplicationStatus).map(([label, value]) => {
        const count = SearchedApplyingRoles.filter(role => role?.mcb_applicationstatus === value).length;
        return {label, value, count};
    }), [SearchedApplyingRoles]);

    const CurrentApplicationStatus = React.useMemo(() => (
        filtered === "all"? {
            label: "All",
            value: "all",
            count: SearchedApplyingRoles.length
        } : ApplicationStatuses.find(({ value }) => value === filtered) 
    ), [ApplicationStatuses, SearchedApplyingRoles, filtered]);

    const [FilteredApplyingRoles, pageCount] = React.useMemo(() => {
        const result = ((CurrentApplicationStatus?.value === "all")? SearchedApplyingRoles : SearchedApplyingRoles.filter(role => {
            return role?.mcb_applicationstatus === CurrentApplicationStatus?.value;
        })).sort((a, b) => {
            const aDate = new Date(a?.modifiedon);
            const bDate = new Date(b?.modifiedon);
            return bDate - aDate;
        });
        if (limit) return [result.slice(pageNumber * limit, pageNumber * limit + limit), Math.ceil(result.length / limit)];
        else return [result, 0];
    }, [CurrentApplicationStatus, SearchedApplyingRoles, pageNumber, limit]);
    
    
    const FilterComponent = React.useMemo(()=> function ({label, value, count}) {
        return (
            <li key={value}>
                <button 
                    className="dropdown-item gap-2 d-flex flex-nowrap align-items-center justify-content-between" 
                    type="button"
                    onClick={() => setFiltered(value)}
                >
                    <div className="flex-grow-1">{label}</div>
                    <div className="rounded-pill ms-2 justify-self-end bg-secondary text-light fs-small p-1 px-2">
                    {count}</div>
                </button>
                
            </li>
        );
    }, []);

    if (loading) return <LoadingSpinner/>;
    return (
        <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row flex-wrap flex-md-nowrap gap-3 align-items-center">
                <h3 className="h4">Keep Track of  Your Applied Jobs</h3>
                <LinkToJourney to="/applying-job-setup" className="text-secondary fs-5"><AddIcon className="icon"/> Add New</LinkToJourney>
                <SearchForm search={search} setSearch={setSearch} className="flex-grow-1" placeholder="Search e.g. my Air New Zealand CV..."/>

                <div className="btn-group dropdown-container">
                    <button 
                        type="button"
                        className="btn btn-outline-secondary py-2 px-3 dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false"
                    > {CurrentApplicationStatus? (
                        <>{CurrentApplicationStatus.label} <span className="rounded-pill ms-2 bg-secondary text-light fs-small p-1 px-2">
                        {CurrentApplicationStatus.count}</span></>
                    ): "Filter by Status"}
                    </button>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <FilterComponent key="all" label="All" value="all" count={SearchedApplyingRoles.length}/>
                        {ApplicationStatuses.map(FilterComponent)}
                    </ul>
                </div>
            </div>
            <div className="row">
                {FilteredApplyingRoles.map((role, index) => (
                    <div
                        key={role?.mcb_roleid}
                        className={addClassNames(
                            "col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 p-2",
                            hideExtra && `d-sm-block${(
                                index > 1 ? ` d-md-none ${(
                                    index > 2 ? `d-lg-none`
                                    : `d-lg-block`
                                )}`
                                : ""
                            )}`
                        )}
                    >
                        <ApplyingRoleSmallPanel
                            role={role}
                            key={role?.mcb_roleid}
                            updateRole={updateRole}
                            deleteRole={deleteRole}
                            ApplicationStatuses={ApplicationStatuses}
                            className="w-100 h-100"
                        />
                    </div>
                ))}
            </div>
            <Pagination pageCount={pageCount} pageNumber={pageNumber} searchParams={searchParams}/>
        </div>
    );
}