import React from 'react';
import useProfileJourneyContext from './ProfileJourneyContext';
import Graph from '../../Components/Generic/Graph';
import RichTextDisplay from '../../Components/RichTextDisplay';
import useSkill from '../../Provider/TableContext/SkillContext';
import { CliftonStrengthOptionsMap } from '../../Constants/CliftonStrengths';
import { TruncateChartData } from '../../Functions/Helper/Functions';
import { getDataColors } from '../../Functions/Helper/Color';
import './SidePanel.scss';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import { addClassNames } from '../../Functions/Helper/Component';

export default function SidePanel(props) {
    const { formData, professionalLoading } = useProfileJourneyContext("professional", [{}]);
    const { skills, loading } = useSkill();

    const [skillData, strengthData, strengthColors] = React.useMemo(() => {
        const skillMap = {};
        const strengthMap = {};
        formData?.forEach(role => {
            role?.achievements?.forEach(achievement => {
                achievement?.skills?.forEach(skill => {
                    if (!isNaN(skillMap[skill])) {
                        skillMap[skill] += 1;
                    } else {
                        skillMap[skill] = 1;
                    }
                });

                achievement?.cliftonstrengths?.forEach(strength => {
                    if (!isNaN(strengthMap[strength])) {
                        strengthMap[strength] += 1;
                    } else {
                        strengthMap[strength] = 1;
                    }
                });
            })
        });
        const skillData = Object.entries(skillMap).map(([id, value]) => ({
            label: skills?.find(skill => skill.mcb_skillid === id)?.mcb_skill,
            value 
        })).filter(a => a.value).sort((a, b) => b.value - a.value);
        const strengthData = Object.entries(strengthMap).map(([id, value]) => ({
            ...CliftonStrengthOptionsMap[id], value
        })).filter(a => a.value).sort((a, b) => b.value - a.value);
        const strengthColors = getDataColors(strengthData);
        return [TruncateChartData(skillData), TruncateChartData(strengthData), strengthColors];
    }, [formData, skills]);

    const achievements = React.useMemo(() => {
        const achievements = [];
        formData?.forEach((role) => 
            role.achievements?.forEach((achievement, index) => achievements.push(
                <li key={achievement.mcb_abcid || achievement.mcb_achievement || index}>
                    <RichTextDisplay
                        value={achievement?.mcb_achievementstatement || ""}
                        readOnly={true}
                        theme="snow"
                    />
                </li>
                
            ))
        );
        return achievements
    }, [formData]);

    return (
        <div className={addClassNames(props.className, "position-relative", (loading || professionalLoading) && "overflow-hidden")}>
            <div className="profile-side-panel d-flex min-h-100 flex-column text-white px-2 py-5">
                    <div className="achievement-preview w-100 flex-grow-1  d-flex flex-column">
                        <h3 className="h3 title text-white text-start">Achievements Across All Roles</h3>
                        <div className="position-relative w-100 min-h-3">
                            {achievements?.length > 0 ? (
                                <ul className="w-100 h-100 overflow-y-auto">
                                    {achievements}
                                </ul>
                            ): "You haven't entered any achievements yet."}
                        </div>
                    </div>
                <div className="d-flex flex-wrap flex-lg-nowrap flex-xl-nowrap flex-xxl-nowrap flex-row gap-5 w-100 justify-content-between align-items-center">
                
                    <div className="skill-preview">
                        <h3 className="h3 title text-white text-start">Your Top 10 Skills</h3>
                        {skillData?.length > 0?  (
                            <Graph data={skillData} chartSize={500}/>
                        ): "You haven't entered any skills yet."}
                    </div>
                    <div className="strength-preview">
                        <h3 className="h3 title text-white text-start">Your Top 10 CliftonStrengths</h3>
                        {strengthData?.length > 0? (
                            <Graph colors={strengthColors} data={strengthData} chartSize={500}/>
                        ) : "You haven't entered any CliftonStrengths yet."}
                    </div>
                </div>
            </div>
            {(loading || professionalLoading) && <LoadingSpinner className="position-absolute w-100 h-100" style={{ background: "none", backdropFilter: "blur"}}/>}            
        </div>
    );
}